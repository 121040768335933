import React from 'react';
import { Button } from 'react-bootstrap';
import { css } from 'twin.macro';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';

const PdfPagination = ({ setCurrentPage, currentPage, fieldMappings }) => {
    return (
        <div
            className="w-100 d-flex justify-content-center items-center"
            css={css`
                background-color: #e0f0ef;
                padding: 16px;
            `}
        >
            <div>
                <Button
                    variant="primary"
                    onClick={() => {
                        setCurrentPage(Math.max(currentPage - 1, 1));
                    }}
                >
                    <FaAngleLeft />
                </Button>
                <span className="mx-2">
                    Page {currentPage} of {fieldMappings?.pdfMetadata?.pageCount}
                </span>
                <Button
                    variant="primary"
                    onClick={() => {
                        setCurrentPage(Math.min(currentPage + 1, fieldMappings?.pdfMetadata?.pageCount));
                    }}
                >
                    <FaAngleRight />
                </Button>
            </div>
        </div>
    );
};

export default PdfPagination;
