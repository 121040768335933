import { css } from 'twin.macro';
import React, { useState, Fragment, useEffect } from 'react';
import { Tabs, Tab } from 'react-bootstrap';

// icons
import { VscLoading } from 'react-icons/vsc';
import { GoProjectTemplate } from 'react-icons/go';

//helpers
import { fetchWrapper, Permission, getUniqueObjects } from '../../../_helpers';
import InfiniteScroll from 'react-infinite-scroller';
import SubmissionModal from '../Teams/SubmissionModal';
import ManagerFormView from '../Teams/ManagerFormView';
import VisitTemplateAccordionModal from '../Teams/TeamSettings/VisitTemplate/VisitTemplateAccordionModal';
import ShareForm from '../PublicForms/ShareForm';

function ChatInputFormModal({ currRoom, team, userId, position, channelRole, handleFormClosed, handleFormOpened }) {
    const [template, setTemplate] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [totalPages, setTotalPages] = useState(1000000);
    const [loadingForms, setLoadingForms] = useState(false);
    const [loadingVisitTemplates, setLoadingVisitTemplates] = useState(false);
    const [visitTemplates, setVisitTemplates] = useState(null);
    const [tabKey, setTabKey] = useState('forms');

    useEffect(() => {
        loadFunc();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadFunc = async (page = 1) => {
        if (currRoom) {
            if (page <= totalPages) {
                try {
                    setLoadingForms(true);
                    const formTemplates = await fetchWrapper.get(
                        `forms/?chatroom=${currRoom.id || currRoom._id}&limit=50&page=${page}`
                    );
                    let tempTemplate = getUniqueObjects([...template], [...formTemplates.results]);
                    setTemplate(tempTemplate);
                    setHasMore(formTemplates.totalResults > tempTemplate.length);
                    setTotalPages(formTemplates.totalPages);
                } catch (err) {
                    console.log(err);
                    setHasMore(false);
                } finally {
                    setLoadingForms(false);
                }
            }
        }
    };

    useEffect(() => {
        const loadVisitTemplates = async () => {
            try {
                setLoadingVisitTemplates(true);
                const response = await fetchWrapper.get(
                    `visit-templates?chatroom=${currRoom.id || currRoom._id}&limit=50`
                );
                setVisitTemplates(
                    response?.results?.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)) || []
                );
            } catch (err) {
                console.log(err);
            } finally {
                setLoadingVisitTemplates(false);
            }
        };
        if (!visitTemplates && tabKey === 'visit-templates') {
            loadVisitTemplates();
        }
    }, [tabKey]);

    return (
        <div className="option-members-content">
            <Tabs id="controlled-tab-example" activeKey={tabKey} onSelect={(k) => setTabKey(k)}>
                <Tab eventKey="forms" title="Forms">
                    <div
                        css={css`
                            height: 400px;
                            overflow: auto;
                            padding: 1rem 0;
                        `}
                    >
                        <InfiniteScroll
                            pageStart={1}
                            loadMore={loadFunc}
                            hasMore={hasMore && !loadingForms}
                            useWindow={false}
                        >
                            {hasMore || (template && template.length > 0) ? (
                                template
                                    .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
                                    .map((tem, idx) => (
                                        <div className="d-flex" key={tem.createdAt + idx}>
                                            <SubmissionModal
                                                form={tem}
                                                roomId={currRoom && (currRoom.id || currRoom._id)}
                                                userId={userId}
                                                currRoom={currRoom}
                                                formType={tem.type}
                                                position={position}
                                                team={team}
                                                handleFormClosed={handleFormClosed}
                                                handleFormOpened={handleFormOpened}
                                            />
                                            <ShareForm
                                                form={tem}
                                                roomId={currRoom && (currRoom.id || currRoom._id)}
                                                userId={userId}
                                                currRoom={currRoom}
                                                formType={tem.type}
                                                position={position}
                                                team={team}
                                                handleFormClosed={handleFormClosed}
                                                handleFormOpened={handleFormOpened}
                                            />
                                            <Permission
                                                role={channelRole}
                                                perform="view:form"
                                                yes={() => (
                                                    <Fragment>
                                                        <ManagerFormView
                                                            roomId={currRoom && (currRoom.id || currRoom._id)}
                                                            form={tem}
                                                            handleFormClosed={handleFormClosed}
                                                            handleFormOpened={handleFormOpened}
                                                        />
                                                    </Fragment>
                                                )}
                                                no={() => null}
                                            />
                                        </div>
                                    ))
                            ) : (
                                <div className="p-4 d-flex justify-content-center align-items-center">No Forms</div>
                            )}
                        </InfiniteScroll>
                        {!loadingForms ? null : (
                            <div
                                className="loader"
                                key={0}
                                css={css`
                                    height: 100%;
                                    min-height: 100px;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                `}
                            >
                                <VscLoading fontSize={34} className="spin" />
                            </div>
                        )}
                    </div>
                </Tab>
                <Tab eventKey="visit-templates" title="Visit Templates">
                    <div
                        css={css`
                            height: 400px;
                            padding: 1rem 0;
                        `}
                    >
                        {loadingVisitTemplates || !visitTemplates ? (
                            <div
                                className="loader"
                                key={1}
                                css={css`
                                    height: 100%;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                `}
                            >
                                <VscLoading fontSize={34} className="spin" />
                            </div>
                        ) : (
                            <Fragment>
                                {visitTemplates?.map((vTemp, idx) => (
                                    <div key={vTemp?._id || idx}>
                                        <VisitTemplateAccordionModal
                                            templateListingMode={true}
                                            mode={'view'}
                                            data={vTemp}
                                            handleFormClosed={handleFormClosed}
                                            handleFormOpened={handleFormOpened}
                                        />
                                    </div>
                                ))}
                                {visitTemplates && visitTemplates?.length === 0 ? (
                                    <div className="p-4 d-flex justify-content-center align-items-center">
                                        No Visit Templates
                                    </div>
                                ) : null}
                            </Fragment>
                        )}
                    </div>
                </Tab>
            </Tabs>
        </div>
    );
}

export default ChatInputFormModal;
