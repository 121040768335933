function getHourlyVisitShift(visitItem) {
    const visitEndTime = new Date(visitItem?.ScheduleEndTime || Date.now());
    const visitStartTime = new Date(visitItem?.ScheduleStartTime || Date.now());
    const totalHours = getTotalHours(visitItem);
    return {
        type: 'hourly',
        start: visitStartTime,
        end: visitEndTime,
        totalHours,
        rate: visitItem?.extraInfo?.staffRate,
        visitId: visitItem?._id,
        metadata: {
            label: 'visit',
            _id: visitItem?._id,
        },
    };
}
function getPtoShift(visitItem) {
    const start = visitItem?.extraInfo?.startDate;
    const end = visitItem?.extraInfo?.endDate;
    const totalHours = getTotalHours(visitItem);
    return {
        type: 'pto',
        start,
        end,
        totalHours,
        rate: visitItem?.extraInfo?.staffRate,
        visitId: visitItem?._id,
        metadata: {
            label: 'pto',
            _id: visitItem?._id,
        },
    };
}

function getReimbursementShift(visitItem) {
    if (visitItem?.extraInfo?.mileageData && visitItem?.provider?.payroll?.metadata?.mileageReimbursementRate) {
        const visitEndTime = new Date(visitItem?.ScheduleEndTime || Date.now());
        const visitStartTime = new Date(visitItem?.ScheduleStartTime || Date.now());
        return {
            type: 'reimbursement',
            start: visitStartTime,
            end: visitEndTime,
            amount:
                ((visitItem?.extraInfo?.mileageData?.distance || 0) *
                    visitItem?.provider?.payroll?.metadata?.mileageReimbursementRate) /
                1609.34,
            visitId: visitItem?._id,
            metadata: {
                label: 'reimbursement',
                _id: visitItem?._id,
            },
        };
    }
}

export function getFormattedShiftData(visitItem) {
    const shiftFunctions = {
        visit: getHourlyVisitShift,
        pto: getPtoShift,
        reimbursement: getReimbursementShift,
    };

    const shiftFunction = shiftFunctions[visitItem?.label];
    return shiftFunction ? shiftFunction(visitItem) : undefined;
}

export function getTotalHours(item) {
    if (item?.label === 'pto') {
        return item?.extraInfo?.requestedBalance;
    } else return item?.extraInfo?.staffRate ? (item?.extraInfo?.staffAmount || 0) / item?.extraInfo?.staffRate : 0;
}

export function isEmployeeOnboarded(employee) {
    if (
        employee?.payroll?.type === 'zeal' &&
        employee?.payroll?.metadata?.employeeID &&
        employee?.payroll?.metadata?.onboarded
    ) {
        return true;
    }
    return false;
}

export function groupFunction(data) {
    const aggregatedData = {};
    (data || [])?.forEach((visitItem) => {
        const employeeId = visitItem?.employee?._id;
        const totalHours = getTotalHours(visitItem);
        if (totalHours <= 0) {
            return;
        }
        const currentShiftData = getFormattedShiftData(visitItem);

        const shifts = [currentShiftData].filter((shift) => {
            if (shift?.type === 'reimbursement' && shift?.amount < 0.005) {
                return false;
            }
            return !!shift;
        });

        if (!aggregatedData[employeeId]) {
            aggregatedData[employeeId] = {
                totalAmount: visitItem?.extraInfo?.staffAmount || 0,
                totalRate: visitItem?.extraInfo?.staffRate,
                totalHours,
                shifts,
                count: 1,
                ...visitItem,
            };
        } else {
            aggregatedData[employeeId] = {
                totalAmount: aggregatedData[employeeId]?.totalAmount + visitItem?.extraInfo?.staffAmount,
                totalRate: aggregatedData[employeeId]?.totalRate + visitItem?.extraInfo?.staffRate,
                count: aggregatedData[employeeId]?.count + 1,
                totalHours: totalHours + aggregatedData[employeeId]?.totalHours,
                shifts: [...aggregatedData[employeeId].shifts, ...shifts],
                ...visitItem,
            };
        }
    });

    return Object.keys(aggregatedData).map((employeeId) => ({
        ...aggregatedData[employeeId],
    }));
}

export function getTotalHoursFromEmployeeCheckShifts(shifts = []) {
    const totalHours = shifts.reduce((acc, shift) => {
        return acc + parseFloat(shift?.hourly?.hours || shift?.hours || 0);
    }, 0);
    return (totalHours || 0).toFixed(2);
}
