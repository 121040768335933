import React, { useState, useRef, useEffect, Fragment } from 'react';
import { PDFDocument } from 'pdf-lib';
import { Document, Page } from 'react-pdf';
import { Button } from 'react-bootstrap';
import ID from '../../../../_helpers/uuid';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { uploadFile } from '../../../../_redux';
import FileUploader from '../../../Features/Teams/TeamSettings/Channels/FileUploader';
import MappedFields from './MappedFields';
import PdfPagination from './PdfPagination';
import PdfCanvas from './PdfCanvas';
import { convertUrlToFilename, PdfLoader } from './components';

const PDFFieldMapper = ({ formElements, onUploadPdfTemplate, url, handleClose }) => {
    const templateFormElements = (formElements || [])?.filter((x) => x?.config?.templateConfig);
    const [isBusy, setIsBusy] = useState(false);
    const dispatch = useDispatch();
    const [rects, setRects] = useState(
        (templateFormElements || []).map((rect) => ({
            ...rect,
            ...(rect?.config?.rectConfig || {}),
            id: rect?._id || rect?.id,
        }))
    );
    const [rectsIdsToRemove, setRectsIdsToRemove] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);
    const [metadata, setMetadata] = useState(null);
    const [pdfUrl, setPdfUrl] = useState(null);
    const [isDrawing, setIsDrawing] = useState(false);
    const [startPoint, setStartPoint] = useState({ x: 0, y: 0 });
    const [pdfDimensions, setPdfDimensions] = useState({ width: 0, height: 0 });
    const [currentPage, setCurrentPage] = useState(1);
    const canvasRef = useRef(null);
    const pdfContainerRef = useRef(null);

    const [fieldMappings, setFieldMappings] = useState({
        documentId: null,
        fields: (templateFormElements || []).map((rect) => ({
            ...rect,
            ...(rect?.config?.templateConfig || {}),
            ...(rect?.config?.templateConfig?.coordinates || {}),
            id: rect?._id || rect?.id,
        })),
        pdfMetadata: {
            pageCount: 1,
            dimensions: { width: 0, height: 0 },
        },
    });

    const processPdf = async (arrayBuffer) => {
        try {
            const fileBlob = new Blob([arrayBuffer], { type: 'application/pdf' });
            const fileUrl = URL.createObjectURL(fileBlob);
            setPdfUrl(fileUrl);

            const pdfDoc = await PDFDocument.load(arrayBuffer);
            const page = pdfDoc.getPages()[0];
            const { width, height } = page.getSize();
            const pageCount = pdfDoc.getPageCount();

            setPdfDimensions({ width, height });
            setMetadata({
                pageCount,
                dimensions: { width, height },
            });

            setFieldMappings((prev) => ({
                ...prev,
                documentId: ID.uuid(),
                pdfMetadata: {
                    pageCount,
                    dimensions: { width, height },
                },
            }));
        } catch (error) {
            console.error('Failed to process PDF:', error);
            toast.error('Error processing the PDF.');
        }
    };

    const handleFileUploadFromUrl = async () => {
        try {
            setIsBusy(true);
            const response = await fetch(url);
            const arrayBuffer = await response.arrayBuffer();
            await processPdf(arrayBuffer);
        } catch (error) {
            console.error('Failed to load PDF from URL:', error);
            toast.error('Error loading PDF from the provided URL.');
        } finally {
            setIsBusy(false);
        }
    };

    const handleFileUpload = async (file) => {
        try {
            if (file) {
                setSelectedFile(file);
                const arrayBuffer = await file.arrayBuffer();
                await processPdf(arrayBuffer);
            } else {
                toast.error('Invalid file type. Please upload a PDF.');
            }
        } catch (e) {
            console.log(e);
        } finally {
            setRects([]);
        }
    };

    useEffect(() => {
        if (url) {
            handleFileUploadFromUrl();
        }
    }, [url]);

    const constrainCoordinates = (x, y) => {
        if (!pdfContainerRef.current) return { x, y };

        const container = pdfContainerRef.current.getBoundingClientRect();
        return {
            x: Math.max(0, Math.min(x, container.width)),
            y: Math.max(0, Math.min(y, container.height)),
        };
    };

    const startDrawing = (e) => {
        if (!canvasRef.current) return;

        const rect = canvasRef.current.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;

        if (x >= 0 && x <= rect.width && y >= 0 && y <= rect.height) {
            const constrained = constrainCoordinates(x, y);
            setIsDrawing(true);
            setStartPoint(constrained);
            setRects((prev) => [
                ...prev,
                {
                    ...constrained,
                    width: 0,
                    height: 0,
                    fieldName: `Field ${prev.length + 1}`,
                    key: 'InputField',
                    page: currentPage,
                },
            ]);
        }
    };

    const updateDrawing = (e) => {
        if (!isDrawing || !canvasRef.current) return;

        const rect = canvasRef.current.getBoundingClientRect();
        const currentPoint = {
            x: e.clientX - rect.left,
            y: e.clientY - rect.top,
        };

        const constrained = constrainCoordinates(currentPoint.x, currentPoint.y);

        setRects((prev) => {
            const updated = [...prev];
            const current = updated[updated.length - 1];
            updated[updated.length - 1] = {
                ...current,
                width: constrained.x - startPoint.x,
                height: constrained.y - startPoint.y,
            };
            return updated;
        });
    };

    const finishDrawing = () => {
        if (!isDrawing) return;

        const lastRect = rects[rects.length - 1];
        if (Math.abs(lastRect?.width) > 5 && Math.abs(lastRect?.height) > 5) {
            const relativeCoords = convertToRelativeCoordinates(lastRect);
            setFieldMappings((prev) => ({
                ...prev,
                fields: [
                    ...prev.fields,
                    {
                        id: ID.uuid(),
                        fieldName: lastRect.fieldName,
                        key: lastRect.key,
                        coordinates: relativeCoords,
                        page: currentPage,
                    },
                ],
            }));
        } else {
            setRects((prev) => prev.slice(0, -1));
        }
        setIsDrawing(false);
    };

    const convertToRelativeCoordinates = (rect) => {
        if (!canvasRef.current || !pdfDimensions.width || !pdfDimensions.height) return null;

        const canvas = canvasRef.current;
        const { clientWidth: canvasWidth, clientHeight: canvasHeight } = canvas;

        let x = rect.x;
        let y = rect.y;
        let width = rect.width;
        let height = rect.height;

        if (width < 0) {
            x += width;
            width = Math.abs(width);
        }
        if (height < 0) {
            y += height;
            height = Math.abs(height);
        }

        return {
            x: x / canvasWidth,
            y: y / canvasHeight,
            width: width / canvasWidth,
            height: height / canvasHeight,
            page: currentPage,
        };
    };

    const saveFieldMappings = async () => {
        try {
            setIsBusy(true);
            if (rects.length === 0) {
                toast.error('Please create at least one field before saving.');
                return;
            }

            const mappingsToSave = {
                ...fieldMappings,
                fields: fieldMappings.fields.map((field, mapIndex) => {
                    const coords = field.coordinates;
                    const rectConfig = rects[mapIndex];
                    return {
                        ...field,
                        key: field.key,
                        coordinates: {
                            ...coords,
                            pdfX: coords.x * pdfDimensions.width,
                            pdfY:
                                pdfDimensions.height -
                                coords.y * pdfDimensions.height -
                                coords.height * pdfDimensions.height,
                            pdfWidth: coords.width * pdfDimensions.width,
                            pdfHeight: coords.height * pdfDimensions.height,
                        },
                        rectConfig,
                    };
                }),
            };

            rectsIdsToRemove.forEach((matchedRectId) => {
                removeFormDataElement(matchedRectId);
            });
            confirmElements(mappingsToSave.fields || []);

            if (selectedFile) {
                const formData = new FormData();
                formData.append('file', selectedFile);
                const response = await uploadFile(formData);
                if (response && response.length) {
                    onUploadPdfTemplate({
                        url: response[0].url,
                        metadata,
                    });
                }
            }
            handleClose();
        } catch (error) {
            toast.error('Error uploading file');
        } finally {
            setIsBusy(false);
        }
    };

    const removeField = (index) => {
        setRects((prev) => prev.filter((_, i) => i !== index));
        setFieldMappings((prev) => ({
            ...prev,
            fields: prev.fields.filter((_, i) => i !== index),
        }));
        const matchedRectId = fieldMappings.fields[index].id;
        setRectsIdsToRemove((prev) => [...prev, matchedRectId]);
    };

    const updateFieldName = (index, fieldName) => {
        setRects((prev) => {
            const updated = [...prev];
            updated[index] = { ...updated[index], fieldName };
            return updated;
        });

        setFieldMappings((prev) => ({
            ...prev,
            fields: prev.fields.map((field, i) => (i === index ? { ...field, fieldName } : field)),
        }));
    };

    const updateFieldKey = (index, key) => {
        setRects((prev) => {
            const updated = [...prev];
            updated[index] = { ...updated[index], key };
            return updated;
        });

        setFieldMappings((prev) => ({
            ...prev,
            fields: prev.fields.map((field, i) => (i === index ? { ...field, key: key } : field)),
        }));
    };

    const confirmElements = (rects) => {
        if (rects.length === 0) {
            return toast.error('No elements selected. Please select at least one element.');
        }
        const getFieldType = (key) => {
            switch (key) {
                case 'InputField':
                    return 'textbox';
                case 'Signature':
                    return 'signature';
                default:
                    return 'textbox';
            }
        };

        const formElements = rects.map((rect) => ({
            fieldName: rect.fieldName,
            id: rect?.id || rect?._id || ID.uuid(),
            type: getFieldType(rect.key),
            key: rect.key,
            config: {
                templateConfig: {
                    coordinates: rect?.coordinates,
                    page: rect?.page,
                    id: rect?.id || rect?._id,
                },
                rectConfig: {
                    x: rect?.rectConfig?.x,
                    y: rect?.rectConfig?.y,
                    width: rect?.rectConfig?.width,
                    height: rect?.rectConfig?.height,
                    page: rect?.rectConfig?.page,
                },
            },
        }));

        formElements.forEach((element) => {
            addFormDataElement(element);
        });
    };

    const addFormDataElement = (form) => {
        dispatch({ type: 'ADD_FORM_DATA', payload: form });
    };
    const removeFormDataElement = (formId) => {
        dispatch({ type: 'DELETE_FORM_ELEMENT', payload: formId });
    };
    return (
        <Fragment>
            {isBusy && <PdfLoader />}

            {pdfUrl && !isBusy && (
                <Fragment>
                    <div
                        ref={pdfContainerRef}
                        className=""
                        style={{
                            minWidth: '800px',
                            border: '1px solid black',
                            position: 'relative',
                        }}
                    >
                        <Document
                            file={pdfUrl}
                            onLoadSuccess={({ numPages }) => {
                                setFieldMappings((prev) => ({
                                    ...prev,
                                    pdfMetadata: {
                                        ...prev.pdfMetadata,
                                        pageCount: numPages,
                                    },
                                }));
                            }}
                        >
                            <Page
                                pageNumber={currentPage}
                                width={pdfContainerRef.current ? pdfContainerRef.current.offsetWidth : 800}
                                renderTextLayer={false}
                                renderAnnotationLayer={false}
                            />
                        </Document>

                        <PdfCanvas
                            canvasRef={canvasRef}
                            startDrawing={startDrawing}
                            updateDrawing={updateDrawing}
                            finishDrawing={finishDrawing}
                            currentPage={currentPage}
                            rects={rects}
                            removeField={removeField}
                        />
                    </div>

                    <div className="mt-4 bg-gray-100  rounded sticky top-24">
                        <PdfPagination
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            fieldMappings={fieldMappings}
                        />
                        <MappedFields
                            rects={rects}
                            updateFieldName={updateFieldName}
                            updateFieldKey={updateFieldKey}
                            removeField={removeField}
                        />
                    </div>
                </Fragment>
            )}
            <div className="sticky top-0 z-50 bg-white pb-4">
                <div className="flex gap-4 mb-4 pb-4">
                    <FileUploader
                        accept=".pdf"
                        onFileUpload={handleFileUpload}
                        buttonText="Upload PDF File"
                        loadingText="Uploading..."
                        successMessage={selectedFile?.name || '-'}
                        errorMessage="File upload failed. Please try again."
                        fileLimit={19}
                        showTips={true}
                        tips={[]}
                        getOriginalFile={true}
                        uploadedFileName={convertUrlToFilename(url)}
                    />
                </div>
            </div>
            <div
                className="d-flex justify-content-end"
                style={{
                    position: 'absolute',
                    bottom: '0px',
                    right: '5px',
                    zIndex: 9999,
                }}
            >
                <div>
                    {pdfUrl && (
                        <Button variant="primary" onClick={saveFieldMappings} disabled={isBusy}>
                            Save Changes
                        </Button>
                    )}
                    <Button variant="secondary" onClick={handleClose} className="ml-2">
                        Close
                    </Button>
                </div>
            </div>
        </Fragment>
    );
};

export default PDFFieldMapper;
