import React, { Fragment, useEffect, useState, useRef } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { IoDocuments } from 'react-icons/io5';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { fetchWrapper, getChannelIdFromSettingsURL, getTeamIdFromURL } from '../../../../../_helpers';
import { VscLoading } from 'react-icons/vsc';
import Table from './Table';
import styled from '@emotion/styled';
import { jsPDF } from 'jspdf';
import { css } from 'twin.macro';

const StyledModalDialog = styled.div`
    &.modal-90w {
        width: 90%;
        max-width: none !important;
        background: #fff;
        margin: auto;
        // position: relative; /* Ensure proper centering */
        // top: 50%; /* Position the modal in the middle of the viewport */
        // transform: translateY(-50%);
    }
`;

const groupByDate = (data) => {
    const res = [''];
    const dates = {};

    (data || []).forEach((item) => {
        if (item?.createdAt) {
            const value = dayjs(item.createdAt).format('MMMM D, YYYY') + ':';
            if (!dates[value]) {
                dates[value] = value;
                res.push({ value, day: true });
            } else {
                res.push({ value: '' });
            }
        }
    });

    return { values: res };
};

function AccumulatedView({ form, sortBy, channel, startDate, endDate, user }) {
    const channelIdFromUrl = getChannelIdFromSettingsURL();
    const teamId = getTeamIdFromURL();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isBusy, setIsBusy] = useState(false);
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const fetchData = async () => {
        try {
            setLoading(true);
            let url = `userFormData?sortBy=createdAt:asc&limit=200&page=1`;
            if (form?.value) {
                url += `&form=${form.value}`;
            }

            if (user?.value) {
                url += `&user=${user.value}`;
            }
            if (channel?.value || channelIdFromUrl) {
                url += `&chatroom=${channelIdFromUrl || channel?.value}`;
            } else if (teamId) {
                url += `&team=${teamId}`;
            }
            if (startDate) {
                url += `&startDate=${startDate}`;
            }
            if (endDate) {
                url += `&endDate=${endDate}`;
            }
            const response = await fetchWrapper.get(url);
            if (response?.results?.length) {
                const { results } = response;

                const formatRowValues = (label, getValue) => ({
                    values: [{ value: label, style: { fontWeight: 'bold' } }, ...results.map(getValue)],
                });

                const _rowData = (results[0]?.formData?.data || [])
                    // .filter(({ key: fieldType }) => fieldType !== 'Header')
                    .map((formElement) => {
                        const { key: fieldType, fieldName, _id, data } = formElement;

                        const values = [
                            { value: fieldName, style: { fontWeight: 'bold' } },
                            ...results.map(({ data: userData = [] }) => {
                                const matchedField = userData.find((f) => _id === f.fieldName.split('-')[1]);
                                const userDataValue = matchedField?.value;
                                const actualValue = data?.find((f) => f?.value === userDataValue)?.text;
                                let image;
                                const _value = actualValue || matchedField?.text || userDataValue || '';
                                let value = _value;
                                if (fieldType === 'Signature') {
                                    image = _value;
                                } else if (fieldType === 'FullName') {
                                    const data = JSON.parse(_value);
                                    value = `${data?.firstName || ''} ${data?.lastName || ''}` || '';
                                } else if ((fieldType || '').toLowerCase().includes('date')) {
                                    value = dayjs(_value).format('MMMM D, YYYY h:mm A');
                                } else if ((fieldType || '').toLowerCase().includes('time')) {
                                    value = dayjs(_value).format('h:mm A');
                                } else if (fieldType === 'Checkbox') {
                                    const parsedValue = JSON.parse(_value);
                                    value = parsedValue
                                        .map((v) => {
                                            return formElement?.data?.find((f) => f?.value === v)?.text;
                                        })
                                        .join(', ');
                                } else if (fieldType === 'Image') {
                                    image = _value;
                                }
                                return {
                                    value,
                                    image,
                                };
                            }),
                        ];

                        return { ...formElement, values };
                    });

                const _submittedByRow = formatRowValues('Submitted By', ({ userData }) => ({
                    value: userData?.name || '',
                    // style: { fontWeight: 'bold' },
                }));

                const _submittedAtRow = formatRowValues('Submitted At', ({ createdAt }) => ({
                    value: dayjs(createdAt).format('h:mm A'),
                    // style: { fontWeight: 'bold' },
                    createdAt,
                }));

                const _days = groupByDate(_submittedAtRow?.values);
                const allRows = [_days, _submittedAtRow, _submittedByRow, ..._rowData];
                setData(allRows.map((row) => row.values));
            }
        } catch (error) {
            if (error.name === 'AbortError') {
                return;
            }
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (show && form?.value && startDate && endDate) {
            fetchData();
        }
    }, [show, form?.value, user?.value, channel?.value, startDate, endDate]);

    const tableRef = useRef();

    const exportToPDF = async () => {
        setIsBusy(true);
        try {
            const element = tableRef.current;

            const pdf = new jsPDF({
                unit: 'mm',
                format: 'a4',
                orientation: 'landscape',
            });

            const contentWidth = element.scrollWidth;
            const contentHeight = element.scrollHeight;
            const pageWidth = pdf.internal.pageSize.width;
            const pageHeight = pdf.internal.pageSize.height;
            const scaleFactorWidth = pageWidth / contentWidth;
            const scaleFactorHeight = pageHeight / contentHeight;
            const scaleFactor = Math.min(scaleFactorWidth, scaleFactorHeight);
            const scaledWidth = contentWidth * scaleFactor;
            const scaledHeight = contentHeight * scaleFactor;
            await pdf.html(element, {
                callback: (doc) => {
                    doc.save(`${form?.label || ''}-accumulated-${Date.now()}.pdf`);
                },
                x: 0,
                y: 0,
                width: scaledWidth,
                height: scaledHeight,
                windowWidth: contentWidth,
                autoPaging: true,
                html2canvas: {
                    useCORS: true,
                    imageTimeout: 15000,
                },
            });
        } catch (error) {
            console.log(error);
        } finally {
            setIsBusy(false);
        }
    };

    return (
        <Fragment>
            <Button onClick={handleShow}>
                <IoDocuments />
                <span className="ml-2">Accumulated View</span>
            </Button>
            <Modal show={show} onHide={handleClose} dialogClassName="modal-90w" dialogAs={StyledModalDialog}>
                <Modal.Header closeButton>
                    <Button
                        onClick={exportToPDF}
                        css={css`
                            margin-bottom: 20px;
                        `}
                        disabled={loading || isBusy}
                    >
                        {isBusy ? 'Exporting...' : 'Export to PDF'}
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    {!form?.value || !startDate || !endDate ? (
                        <Fragment>
                            <div>
                                {!form?.value && <p>- Please select a form !</p>}
                                {!startDate && <p>- Please select a start date !</p>}
                                {!endDate && <p>- Please select a end date !</p>}
                            </div>
                        </Fragment>
                    ) : (
                        <Fragment>
                            {loading ? (
                                <div className="text-center">
                                    {/* <div className="spinner-border text-primary" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div> */}
                                    <VscLoading className="spin" size={50} />{' '}
                                </div>
                            ) : (
                                <Fragment>
                                    <div
                                        ref={tableRef}
                                        css={css`
                                            width: 100%;
                                            overflow-x: auto;
                                            padding-bottom: 50px;
                                        `}
                                    >
                                        <Table
                                            data={data}
                                            columns={[]}
                                            tableRef={tableRef}
                                            title={form?.label}
                                            formId={form?.value}
                                        />
                                    </div>
                                </Fragment>
                            )}
                        </Fragment>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
}

export default AccumulatedView;
