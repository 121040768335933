import React from 'react';
import { useCallback } from 'react';
import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import ViewForm from '../../Admin/Form/ViewForm';

function UserFormModal({ roomId, chatFormId, setChatFormId, canDownload }) {
    const [formTitle, setFormTitle] = useState('');
    const history = useHistory();

    const handleClose = useCallback(() => {
        setChatFormId(null);
        history.push(`/teams/${roomId}`);
    }, [history, roomId, setChatFormId]);
    return (
        <Modal show={Boolean(chatFormId)} onHide={handleClose} size="xl" centered>
            <Modal.Header closeButton>
                <Modal.Title>{formTitle} Form</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ViewForm
                    formId={chatFormId}
                    hide={handleClose}
                    showSignature={true}
                    setFormTitle={setFormTitle}
                    canDownload={canDownload}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default UserFormModal;
