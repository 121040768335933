import React, { useState } from 'react';
import { css } from 'twin.macro';
import { connect } from 'react-redux';
import { Button, Modal, FormLabel } from 'react-bootstrap';
import { toast } from 'react-toastify';
import ChannelSelectFilter from '../../ChannelSelectFilter';
import { fetchWrapper, getTeamIdFromURL } from '../../../../_helpers';

const getOptionFromData = (data = {}, noun) => ({
    label: data.name || data.title || data.label || 'Select a ' + noun,
    value: data.id || data._id || data.value || '',
});

const AccessForm = ({
    accessData = {},
    afterSubmit = async () => {},
    ButtonContent = '',
    buttonVariant = 'primary',
}) => {
    const teamId = getTeamIdFromURL();
    const [data, setData] = useState({
        room: getOptionFromData(accessData.room, 'Room'),
    });

    const [isSubmitting, setIsSubmitting] = useState(false);

    const [showModal, setShowModal] = useState(false);

    const handleModalShow = () => {
        setShowModal(true);
    };

    const handleHideModal = () => {
        setShowModal(false);
    };

    const onSubmit = async () => {
        try {
            const roomId = data?.room?.value || data?.room?._id || '0'.repeat(24);
            if (!roomId)
                if (!teamId || !accessData?._id) {
                    return toast.warning('Team or access id is missing');
                }
            setIsSubmitting(true);

            if (!'isDefaultAccess' && !data.team?.value && !data.user?.value && !data.room?.value) {
                return toast.error('One of team, room or user is required for specific access rule.');
            }

            const postData = {
                room: roomId,
            };

            const response = await fetchWrapper.patch(
                `/sms/access/${accessData?._id || accessData?.id}?team=${teamId}`,
                postData
            );
            await afterSubmit(response);
            setShowModal(false);
        } catch (error) {
            toast.error(error.message || 'Something went wrong');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <>
            <Button variant={buttonVariant} onClick={handleModalShow}>
                {ButtonContent}
            </Button>
            {showModal && (
                <Modal show={showModal} onHide={handleHideModal} centered backdrop="static">
                    <Modal.Header closeButton>
                        <div className="header">
                            <h4
                                className="d-flex align-items-center "
                                css={css`
                                    margin: 0;
                                `}
                            >
                                Edit Channel
                            </h4>
                        </div>
                    </Modal.Header>
                    <Modal.Body style={{ height: '50vh' }}>
                        <FormLabel>Room</FormLabel>
                        <ChannelSelectFilter
                            teamIds={[data.team?.value]}
                            selectedOption={data.room}
                            onRoomSelect={(option) => setData((prev) => ({ ...prev, room: option }))}
                            disabled={isSubmitting}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <div
                            css={css`
                                display: flex;
                                padding: 12px 0 0;
                                justify-content: flex-end;
                            `}
                        >
                            <Button onClick={onSubmit} disabled={isSubmitting}>
                                Submit
                            </Button>
                        </div>
                    </Modal.Footer>
                </Modal>
            )}
        </>
    );
};
const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(AccessForm);
