import { css } from 'twin.macro';
import React, { useState, Fragment, useEffect, useCallback, useRef, forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { Modal, Form, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import DateTimePicker from 'react-datetime-picker';
import { yupResolver } from '@hookform/resolvers';
import Select from 'react-select';
import dayjs from 'dayjs';
import { ConsentToolTip, ToolTip } from '../../../_elements/ToolTip';
import { BsArrowBarRight } from 'react-icons/bs';
import { connect } from 'react-redux';
import TimezoneSelect from 'react-timezone-select';

//components
import ChannelSelectFilter from '../ChannelSelectFilter';
import ReactSelectComponent from './ReactSelectComponent';
import {
    fetchWrapper,
    sortingOptionMethod,
    createEventValidation,
    formatAMPM,
    formatDate,
    addHours,
    getTeamIdFromURL,
    getChannelIdFromSettingsURL,
} from '../../../_helpers';
import UserInRoom from './UserInRoom';
import TeamSelectFilter from '../TeamSelectFilter';

//styles
import { StyledLinkBtn } from './EventModalStyles';

// icons
import { VscLoading } from 'react-icons/vsc';
import { HiPlusCircle, HiMinusCircle } from 'react-icons/hi';
import { CustFormLabel } from '../../../_elements/formLabel';
import { customCheckbox } from '../../../_elements/CustomCheckbox';
import { ImCheckboxChecked, ImCheckboxUnchecked } from 'react-icons/im';
import { MdCall } from 'react-icons/md';
import { AiOutlineClose } from 'react-icons/ai';
import CustomRecurrence, {
    getRecurrenceData,
    getRecurrenceString,
    nthDay,
    days,
} from '../../Features/Events/CustomRecurrence';
import loaderStyles from '../../../_elements/loader';
import { FaBellSlash } from 'react-icons/fa';
import OurAccordion from '../../../_elements/OurAccordion';

// const recurringOptions = [
// 	// { label: 'Hourly', value: 'Hourly' },
// 	{ label: 'Daily', value: 'Daily' },
// 	{ label: 'Weekly', value: 'Weekly' },
// 	{ label: 'Monthly', value: 'Monthly' },
// 	{ label: 'Quarterly', value: 'Quarterly' },
// 	{ label: 'Halfyearly', value: 'Halfyearly' },
// 	{ label: 'Yearly', value: 'Yearly' },
// ];

const reminderSet = {
    // Hourly: [
    // 	{ label: '15 Minutes', value: '15mins' },
    // 	{ label: '30 Minutes', value: '30mins' },
    // ],
    days: [
        { label: '15 Minutes', value: '15mins' },
        { label: '30 Minutes', value: '30mins' },
        { label: '1 hour', value: '1hour' },
    ],
    weeks: [
        { label: '15 Minutes', value: '15mins' },
        { label: '30 Minutes', value: '30mins' },
        { label: '1 hour', value: '1hour' },
        { label: '1 day', value: '1day' },
    ],
    months: [
        { label: '15 Minutes', value: '15mins' },
        { label: '30 Minutes', value: '30mins' },
        { label: '1 hour', value: '1hour' },
        { label: '1 day', value: '1day' },
        { label: '1 week', value: '7days' },
    ],
    years: [
        { label: '15 Minutes', value: '15mins' },
        { label: '30 Minutes', value: '30mins' },
        { label: '1 hour', value: '1hour' },
        { label: '1 day', value: '1day' },
        { label: '1 week', value: '7days' },
        { label: '1 month', value: '30days' },
    ],
    all: [
        { label: '15 Minutes', value: '15mins' },
        { label: '30 Minutes', value: '30mins' },
        { label: '1 hour', value: '1hour' },
        { label: '1 day', value: '1day' },
        { label: '1 week', value: '7days' },
        { label: '1 month', value: '30days' },
    ],
};

const EventModalFormWithoutReduxState = ({
    data,
    channel,
    handleSubmitTask,
    handleClose,
    teamIds,
    isPackageModal,
    mode = 'edit',
    currUser,
}) => {
    const self = useSelector((state) => state.user.user);
    const [forms, setForms] = useState();
    const [usersInRoom, setUsersInRoom] = useState([]);
    const [allUsersInRoom, setAllUsersInRoom] = useState([]);
    const [userError, setUserError] = useState(false);
    const [recurrenceData, setRecurrenceData] = useState(
        data?.recurring ? getRecurrenceData(data.recurring, new Date(data.latestDatePointer || data.date)) : {}
    );
    const [selectedForm, setSelectedForm] = useState();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [loadingUsers, setLoadingUsers] = useState(false);
    const [channelError, setChannelError] = useState(false);
    const [endDateError, setEndDateError] = useState(false);
    const [recurringUntilError, setRecurringUntilError] = useState(false);
    const [recurringMessage, setRecurringMessage] = useState();
    const [eventTypeError, setEventTypeError] = useState(false);
    const [reminderMode, setReminderMode] = useState(data?.startReminders || ['15mins']);
    const [endTimeReminderMode, setEndTimeReminderMode] = useState(data?.endReminders || ['30mins']);
    const [selectedEventTypeOption, setSelectedEventTypeOption] = useState(data?.eventType);
    const [showRecurring, setShowRecurring] = useState(Boolean(data?.recurring) || false);
    const [selectedUserOption, setSelectedUserOption] = useState(
        data?.assignedUser?.filter((userId) => userId !== (self._id || self.id)) || []
    );
    const [notificationDisabledFor, setNotificationDisabledFor] = useState(data?.notificationDisabledFor || []);
    const [showReminder, setShowReminder] = useState(Boolean(data?.startReminders?.length) || false);
    const [showEndTimeReminder, setShowEndTimeReminder] = useState(Boolean(data?.endReminders?.length) || false);
    const [selectedRoomOption, setSelectedRoomOption] = useState(null);
    const [startDate, setStartDate] = useState(data?.latestDatePointer || data?.date || new Date());
    const [hasConference, setHasConference] = useState(null);
    const [eventTypeMeeting, setEventTypeMeeting] = useState(false);
    const [userStartReminder, setUserStartReminder] = useState(data?.userStartReminders || []);
    const [showUserStartReminder, setShowUserStartReminder] = useState(
        Boolean(data?.userStartReminders?.length) || false
        // true
    );
    const [userEndReminder, setUserEndReminder] = useState(data?.userEndReminders || []);
    const [showUserEndReminder, setShowUserEndReminder] = useState(Boolean(data?.userEndReminders?.length) || false);
    const [editUserStartTimeReminder, seteditUserStartTimeReminder] = useState(false);
    const [editUserEndTimeReminder, seteditUserEndTimeReminder] = useState(false);
    const [eventMode, setEventMode] = useState(data?.eventMode || 'live');
    const [selectedTimezone, setSelectedTimezone] = useState(
        Intl.DateTimeFormat().resolvedOptions().timeZone || 'America/Detroit'
    );
    const [selectedTeamOptions, setSelectedTeamOptions] = useState(
        data?.assignedChannelInfo?.length
            ? {
                  label: data?.assignedChannelInfo[0]?.teamData?.name,
                  value: data?.assignedChannelInfo[0]?.teamData?._id,
              }
            : []
    );
    const [selectedCourses, setSelectedCourses] = useState(
        data?.trainings?.map((course) => {
            return {
                label: course.title,
                value: course._id,
                index: course.index,
            };
        }) || []
    );

    const courseOptions = [
        { value: 25, label: '001 Intro to DSP123', index: 1 },
        { value: 26, label: '002 Home and Community Care', index: 2 },
        { value: 28, label: '003 Principles of Intellectual Disabilities', index: 3 },
        { value: 29, label: '004 Supporting Individuals With ASD', index: 4 },
        { value: 31, label: '005 Behavioral Analysis & Support', index: 5 },
        { value: 32, label: '006 HIPAA Compliance', index: 6 },
        { value: 33, label: '007 Incident Management', index: 7 },
        { value: 34, label: '008 Basic CPR & First Aid', index: 8 },
        { value: 35, label: '009 Emergency Preparation & Reporting', index: 9 },
        { value: 36, label: '010 First Responders', index: 10 },
        { value: 37, label: '011 Ethical Boundaries', index: 11 },
        { value: 38, label: '012 General Fire Safety', index: 12 },
        { value: 30, label: '013 Identifying Abuse, Neglect & Exploitation', index: 13 },
        { value: 19, label: '017 Introduction to Medication Administration & Management Part 1', index: 17 },
        { value: 40, label: '020 Smart Goals', index: 20 },
        { value: 41, label: '021 Intro to ISP', index: 21 },
        { value: 42, label: '023 Coping Strategieis', index: 23 },
        { value: 27, label: '024 Empathy', index: 24 },
        {
            value: 20,
            label: '026 Medication Administration and Management Part 2 - How to safely administer medication',
            index: 26,
        },
        { value: 21, label: '027 Medication Administration and Management Part 3', index: 27 },
        { value: 22, label: '028 Medication Administration and Management Part 4', index: 28 },
        { value: 23, label: '028 Medication Administration and Management Part 5 Documentation', index: 28 },
        {
            value: 24,
            label: '030 Medication Admin. & Mgmt. Part 6 Controlled Substance & Medication Errors',
            index: 30,
        },
    ];

    const handleEndDateDefaultValue = useCallback(() => {
        if (data && (data.latestDueDatePointer || data.dueDate)) {
            return data.latestDueDatePointer || data.dueDate;
        } else {
            let tempStartDate = new Date(startDate);
            let tempEndDate = addHours(1, tempStartDate);
            return tempEndDate;
        }
    }, [data?.dueDate, startDate]);

    const [isDisabled, setIsDisabled] = useState(true);
    const [channelTeam, setChannelTeam] = useState(null);
    const channelIdFromUrl = getChannelIdFromSettingsURL();

    useEffect(() => {
        const checkTeam = channelTeam || getTeamIdFromURL();

        const check =
            self?.role === 'admin' ||
            self?.roomList?.find((room) => (room?.room?._id || room?.room?.id) === selectedRoomOption?.value)?.role ===
                'supervisor' ||
            self?.teamList?.find((team) => (team?.team?._id || team?.team?.id) === checkTeam)?.role === 'owner' ||
            self?.teamList?.find((team) => (team?.team?._id || team?.team?.id) === checkTeam)?.role === 'teamManager';

        setIsDisabled(!check);
    }, [self, selectedRoomOption, channelTeam]);

    const [endDate, setEndDate] = useState(handleEndDateDefaultValue);

    const [recurringUntil, setRecurringUntil] = useState(data?.recurringUntil || null);

    useEffect(() => {
        setEndDate(handleEndDateDefaultValue());
    }, [handleEndDateDefaultValue, startDate]);

    const onStartDateChange = (date) => {
        setStartDate(date);
    };
    const onEndDateChange = (date) => {
        setEndDate(date);
    };

    const onRecurringUntilChange = (date) => {
        setRecurringUntil(date);
    };

    const { register, handleSubmit, errors, setValue } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(createEventValidation),
    });

    useEffect(() => {
        // console.log("CHANGED => ", recurrenceData)
        if (recurrenceData?.num && recurrenceData?.unit) {
            const eventStartDateTime = startDate ? new Date(startDate) : new Date();
            const unitString =
                recurrenceData.num > 1
                    ? recurrenceData.num + ' ' + recurrenceData.unit
                    : recurrenceData.unit?.replace('s', '');
            let message = `The event will reoccur every ${unitString}`;
            if (recurrenceData.unit === 'minutes') {
                message += ` from ${formatDate(eventStartDateTime)}`;
            } else if (recurrenceData.unit === 'weeks' || (recurrenceData.unit === 'months' && recurrenceData.week)) {
                message += ` on ${recurrenceData.unit === 'months' ? nthDay[recurrenceData.week] + ' ' : ''}${recurrenceData.days.map((x) => days[x]).join(', ')} at ${formatAMPM(eventStartDateTime)}`;
            } else {
                message += ` at ${recurrenceData.unit === 'days' ? formatAMPM(eventStartDateTime) : formatDate(eventStartDateTime, recurrenceData.unit !== 'months')}`;
            }
            setRecurringMessage(
                `${message}${recurringUntil ? ` until ${formatDate(new Date(recurringUntil))}` : ' forever'}`
            );
        }
    }, [recurrenceData, recurringUntil]);

    useEffect(() => {
        const eventStartDateObject = new Date(startDate);
        const [_, startDay, startWeek] = [
            eventStartDateObject.getDate(),
            eventStartDateObject.getDay(),
            Math.min(3, Math.floor(eventStartDateObject.getDate() / 7)),
        ];
        setRecurrenceData((prev) => ({
            ...prev,
            num: prev.num || 1,
            unit: prev.unit || 'days',
            days: prev.days?.includes(startDay) ? prev.days : [...(prev.days || []), startDay].sort(),
            week: startWeek,
        }));
    }, [startDate]);

    useEffect(() => {
        if (channel) {
            setSelectedRoomOption({
                label: channel.title,
                value: channel._id,
                type: channel.type,
            });
        }
        if (data?.assignedChannelInfo) {
            const { _id, title, type } = Array.isArray(data.assignedChannelInfo)
                ? data.assignedChannelInfo[0]
                : data.assignedChannelInfo || {};
            setSelectedRoomOption({
                value: _id,
                label: title,
                type,
            });
        }
    }, [channel, data]);

    useEffect(() => {
        if (Boolean(data?.recurring)) {
            setShowRecurring(true);
        } else {
            setShowRecurring(false);
        }
    }, [data?.recurring]);

    useEffect(() => {
        if (Boolean(data?.startReminders?.length)) {
            setShowReminder(true);
        } else {
            setShowReminder(false);
        }
    }, [data?.startReminders]);

    const roomNotFoundChecker = useRef({});

    useEffect(() => {
        roomNotFoundChecker.current.notFound = true;
    }, []);

    useEffect(() => {
        const fetchUsersData = async () => {
            try {
                setLoadingUsers(true);
                const chatroomId =
                    channelIdFromUrl ||
                    (Array.isArray(selectedRoomOption)
                        ? selectedRoomOption[0]
                        : selectedRoomOption?.value || selectedRoomOption) ||
                    (Array.isArray(data.assignedChannel)
                        ? data.assignedChannel[0]
                        : data.assignedChannel?.value || data.assignedChannel);

                if (!chatroomId) {
                    return;
                }

                const chatroomData = await fetchWrapper.get(`/chatrooms/${chatroomId}`);

                if (chatroomData.code === 404 && roomNotFoundChecker.current.notFound) {
                    roomNotFoundChecker.current.notFound = false;
                    return toast.error(chatroomData?.message);
                }

                setChannelTeam(chatroomData?.team);

                setSelectedTeamOptions({
                    label: chatroomData?.teamData?.name,
                    value: chatroomData?.team,
                });

                const memberUrl =
                    chatroomData.type === 'public'
                        ? `organizations/5f3569c2647fb5000c019e42/teams/${chatroomData.team}/members?limit=200`
                        : `/chatrooms/${chatroomId}/members?limit=200`;

                const membersRes = await fetchWrapper.get(memberUrl);

                const usersInRoomList = [...membersRes.results].filter(
                    (user) => (user._id || user.id) !== (self._id || self.id)
                );

                setUsersInRoom(usersInRoomList);

                const formsRes = await fetchWrapper.get(`forms?limit=200&chatroom=${chatroomId}`);

                const formOptions = formsRes?.results?.map((form) => ({
                    label: form.name,
                    value: form._id,
                    ...form,
                }));

                setForms((formOptions || []).sort(sortingOptionMethod));
                setLoadingUsers(false);
            } catch (err) {
                if (err.name === 'AbortError') {
                    return;
                }
            }
        };
        const taskId = data?._id || data?.id;
        if (mode === 'create' || (!loadingUsers && taskId) || (!loadingUsers && selectedRoomOption?.value)) {
            fetchUsersData();
            if (data?.assignedUser) {
                setSelectedUserOption(data?.assignedUser?.filter((userId) => userId !== (self._id || self.id)));
            }
        }
    }, [data, selectedRoomOption, self]);

    useEffect(() => {
        if (mode === 'create' || (data?.assignedUserData && usersInRoom && usersInRoom?.length)) {
            const tempAllUsers = [
                ...new Map(
                    [
                        ...(usersInRoom || []),
                        ...(allUsersInRoom || []),
                        currUser,
                        ...(data?.assignedUserData || []),
                    ].map((user) => [user._id || user.id, user])
                ).values(),
            ];
            // const userToshowInAssignee = (tempAllUsers || []).filter((_user) => _user?._id !== (self._id || self.id));
            setAllUsersInRoom(tempAllUsers);
            // setUsersInRoom(userToshowInAssignee);
        }
    }, [data?.assignedUserData, usersInRoom]);

    useEffect(() => {
        if (data?.notificationDisabledFor) {
            setSelectedUserOption(data?.assignedUser?.filter((userId) => userId !== (self._id || self.id)) || []);
            setNotificationDisabledFor(data?.notificationDisabledFor || []);
        }
    }, [data]);

    useEffect(() => {
        if (forms && data?.forms) {
            let newSelectedForms = forms?.filter((form) => data?.forms.includes(form.value));
            setSelectedForm(newSelectedForms);
        }
    }, [data?.forms, forms]);

    useEffect(() => {
        if (selectedRoomOption) {
            setChannelError();
        }
        if (data?.title && data?.description) {
            return;
        }
        if (selectedEventTypeOption) {
            setEventTypeMeeting(selectedEventTypeOption.title === 'Meeting');
            setEventTypeError();
            let channelTitle = selectedRoomOption?.label;
            if (channelTitle) {
                setValue('title', `${channelTitle}-${selectedEventTypeOption.title}`);
                setValue('description', `${channelTitle}-${selectedEventTypeOption.title}`);
            } else {
                setValue('title', selectedEventTypeOption.title);
                setValue('description', selectedEventTypeOption.title);
            }
        }
        return null;
    }, [data, selectedEventTypeOption, selectedRoomOption]);

    useEffect(() => {
        if (selectedUserOption.length > 0) {
            setUserError();
        }
    }, [selectedUserOption.length]);

    const handleReminderChange = (event) => {
        let reminder = event.target.value;
        let newReminder = [...reminderMode];

        let reminderIndex = newReminder.indexOf(reminder);
        let permissionGiven = Boolean(reminderIndex >= 0);
        if (permissionGiven) {
            // remove reminder permission
            newReminder.splice(reminderIndex, 1);
        } else {
            // add reminder in permission
            newReminder.push(reminder);
        }
        setReminderMode(newReminder);
    };
    const handleUserStartReminderChange = (event) => {
        let reminder = event.target.value;
        let newUserStartReminder = [...userStartReminder];
        let reminderIndex = newUserStartReminder.indexOf(reminder);
        let permissionGiven = Boolean(reminderIndex >= 0);
        if (permissionGiven) {
            // remove reminder permission
            newUserStartReminder.splice(reminderIndex, 1);
        } else {
            // add reminder in permission
            newUserStartReminder.push(reminder);
        }
        setUserStartReminder(newUserStartReminder);
    };
    const handleUserEndReminderChange = (event) => {
        let reminder = event.target.value;
        let newUserEndReminder = [...userEndReminder];
        let reminderIndex = newUserEndReminder.indexOf(reminder);
        let permissionGiven = Boolean(reminderIndex >= 0);
        if (permissionGiven) {
            // remove reminder permission
            newUserEndReminder.splice(reminderIndex, 1);
        } else {
            // add reminder in permission
            newUserEndReminder.push(reminder);
        }
        setUserEndReminder(newUserEndReminder);
    };

    const handleEndTimeReminderChange = (event) => {
        let reminder = event.target.value;
        let newEndTimeReminder = [...endTimeReminderMode];
        let reminderIndex = newEndTimeReminder.indexOf(reminder);
        let permissionGiven = Boolean(reminderIndex >= 0);
        if (permissionGiven) {
            // remove reminder permission
            newEndTimeReminder.splice(reminderIndex, 1);
        } else {
            // add reminder in permission
            newEndTimeReminder.push(reminder);
        }
        setEndTimeReminderMode(newEndTimeReminder);
    };

    const handleEventModeChange = (e) => {
        setEventMode(e.target.value);
    };
    const changeDateTimezone = (date, offset = 0) => {
        const getTwoDigits = (x) => `${x >= 10 ? x : `0${x}`}`;
        const dateObject = date ? new Date(date) : new Date();
        const [year, month, day] = [dateObject.getFullYear(), dateObject.getMonth() + 1, dateObject.getDate()];
        const timeString = dateObject.toTimeString().split(' ')[0];
        const sign = offset < 0 ? '-' : '+';
        const offsetHours = Math.floor(Math.abs(offset));
        const offsetMinuts = Math.round(Math.abs(Math.abs(offset) - offsetHours) * 60);
        return new Date(
            `${year}-${getTwoDigits(month)}-${getTwoDigits(day)}T${timeString}${sign}${getTwoDigits(offsetHours)}:${getTwoDigits(offsetMinuts)}`
        );
    };

    const onSubmit = async (formData) => {
        try {
            setIsSubmitting(true);

            let validated = true;
            if (!formData.title || !formData.description) {
                return null;
            }

            if (!selectedRoomOption && !data?.assignedChannel && teamIds?.some((value) => value)) {
                setChannelError('Channel must not be empty!');
                validated = false;
            }

            //optional for adding assignee
            // if (selectedUserOption.length === 0) {
            // 	setUserError('Atleast one user must be selected!');
            // 	validated = false;
            // }

            if (!selectedEventTypeOption && !data?.eventType) {
                setEventTypeError('Event type must be selected!');
                validated = false;
            }

            if (dayjs(startDate).isAfter(dayjs(endDate))) {
                setEndDateError('End date must not be earlier than start date');
                validated = false;
            }

            if (
                recurrenceData?.num &&
                recurrenceData?.unit &&
                recurringUntil &&
                dayjs(endDate).isAfter(dayjs(recurringUntil))
            ) {
                setRecurringUntilError('Event can not recur until end date');
                validated = false;
            }

            if (!validated) {
                return null;
            }

            const offset =
                selectedTimezone?.offset && selectedTimezone?.offset !== 0
                    ? selectedTimezone?.offset
                    : (-1 * new Date().getTimezoneOffset()) / 60;

            const newTask = {
                title: formData.title,
                description: formData.description,
                date: changeDateTimezone(startDate, offset),
                dueDate: changeDateTimezone(endDate, offset),
                status: [],
                eventType: selectedEventTypeOption.id || data?.eventType?.id || data?.eventType._id || data?.eventType,
                assignedUser: [self._id, ...selectedUserOption],
                assignedChannel: channelIdFromUrl
                    ? [channelIdFromUrl]
                    : selectedRoomOption
                      ? [selectedRoomOption.value]
                      : data?.assignedChannel,
                startReminders: [],
                endReminders: [],
                assignedChannelInfo: selectedRoomOption
                    ? [
                          {
                              _id: selectedRoomOption.value,
                              type: selectedRoomOption.type,
                              title: selectedRoomOption.label,
                          },
                      ]
                    : data?.assignedChannelInfo,
                // userStartReminders: [],
                // userEndReminders: [],
                hasConference: Boolean(hasConference),
                eventMode: eventMode,
            };

            if (mode === 'edit' && data?.eventPackage) {
                newTask.eventPackage = data.eventPackage;
            }

            if (showReminder) {
                if (reminderMode.length > 0) {
                    newTask.startReminders = reminderMode;
                }
            }
            // if (showUserStartReminder) {
            if (userStartReminder.length > 0) {
                newTask.startReminders = userStartReminder;
            }
            // }
            // if (showUserEndReminder) {
            if (userEndReminder.length > 0) {
                newTask.endReminders = userEndReminder;
            }
            // }
            if (selectedForm?.length) {
                let formIdsArr = selectedForm.map((selected) => selected.value);
                newTask.forms = formIdsArr;
            }
            if (selectedCourses?.length && selectedEventTypeOption?.title === 'Online Training') {
                let coursesObj = selectedCourses.map((selected) => {
                    return {
                        _id: selected.value,
                        title: selected.label,
                        index: selected.index,
                    };
                });
                newTask.trainings = coursesObj;
            }
            if (showRecurring && recurrenceData?.num && recurrenceData?.unit) {
                newTask.recurring = getRecurrenceString(
                    recurrenceData,
                    newTask.date.getUTCDay() - new Date(startDate).getDay()
                );
                newTask.recurringUntil = recurringUntil && changeDateTimezone(recurringUntil, offset);
            }
            if (showEndTimeReminder) {
                if (endTimeReminderMode.length > 0) {
                    newTask.endReminders = endTimeReminderMode;
                }
            }
            newTask.notificationDisabledFor = notificationDisabledFor || data?.notificationDisabledFor || [];
            const postedTask = await handleSubmitTask(newTask);
            if (postedTask) {
                handleClose();
            }
        } catch (error) {
            toast.error('Something went wrong!' + error);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleUserSelect = (userId) => {
        let tempSelectedUserOption;
        if (selectedUserOption.length !== 0) {
            tempSelectedUserOption = [...selectedUserOption];
        } else {
            tempSelectedUserOption = [];
        }
        let indexOfUserId = tempSelectedUserOption.indexOf(userId);
        let isUserPresent = Boolean(indexOfUserId !== -1);
        if (isUserPresent) {
            tempSelectedUserOption.splice(indexOfUserId, 1);
        } else {
            tempSelectedUserOption.push(userId);
        }

        setSelectedUserOption(tempSelectedUserOption);
    };

    const handleNotifyUserSelect = (userId) => {
        let tempSelectedUserOption;
        if (notificationDisabledFor.length !== 0) {
            tempSelectedUserOption = [...notificationDisabledFor];
        } else {
            tempSelectedUserOption = [];
        }
        let indexOfUserId = tempSelectedUserOption.indexOf(userId);
        let isUserPresent = Boolean(indexOfUserId !== -1);
        if (isUserPresent) {
            tempSelectedUserOption.splice(indexOfUserId, 1);
        } else {
            tempSelectedUserOption.push(userId);
        }
        setNotificationDisabledFor(tempSelectedUserOption);
    };

    const handleSelectAllUsers = () => {
        setSelectedUserOption(usersInRoom.map((user) => user._id));
    };

    const handleNotifySelectAllUsers = () => {
        setNotificationDisabledFor(allUsersInRoom.map((user) => user._id));
    };

    const handleDeselectAllUsers = () => {
        setSelectedUserOption([]);
    };
    const handleNotifyDeselectAllUsers = () => {
        setNotificationDisabledFor([]);
    };

    const handleHasConference = () => {
        setHasConference(true);
    };

    const displayText = { create: 'Create an Event', edit: 'Edit Event', copy: 'Copy Event' }[mode];
    return (
        <Form className="px-5" onSubmit={handleSubmit(onSubmit)}>
            <Modal.Header
                className="font-weight-bold d-flex justify-content-center"
                css={css`
                    font-size: 22px;
                `}
            >
                {displayText}
                {data?.eventPackage && (
                    <span
                        className="badge badge-sm badge-warning"
                        style={{
                            position: 'absolute',
                            right: '6px',
                            top: '-16px',
                            padding: '6px 8px',
                            fontSize: '14px',
                        }}
                    >
                        Plan Event
                    </span>
                )}
            </Modal.Header>
            <Modal.Body
                style={{
                    maxHeight: 'calc(100vh - 160px)',
                    padding: '0 48px 0',
                    overflowY: 'scroll',
                    overflowX: 'hidden',
                }}
            >
                <Fragment>
                    <Fragment>
                        <CustFormLabel>Event Type</CustFormLabel>
                        <ReactSelectComponent
                            selectedOption={selectedEventTypeOption}
                            setSelectedOption={setSelectedEventTypeOption}
                            eventType={data && data.eventType}
                            placeholder="Search EventType"
                            mode={data ? `edit` : `create`}
                        />
                        <Form.Text className="text-danger">{eventTypeError}</Form.Text>
                    </Fragment>

                    {currUser?.role === 'admin' ? (
                        <Form.Row>
                            <div className="w-100">
                                <Form.Label>Team</Form.Label>
                                <TeamSelectFilter
                                    selectedTeamOption={selectedTeamOptions}
                                    setSelectedTeamOption={setSelectedTeamOptions}
                                />
                            </div>
                        </Form.Row>
                    ) : null}

                    <CustFormLabel>Channel</CustFormLabel>
                    <div className="d-flex" style={{ width: '100%', gap: '5px' }}>
                        <div style={{ width: '100%' }}>
                            <ChannelSelectFilter
                                selectedOption={selectedRoomOption}
                                onRoomSelect={setSelectedRoomOption}
                                assignedChannel={
                                    channelIdFromUrl
                                        ? channelIdFromUrl
                                        : selectedRoomOption
                                          ? selectedRoomOption?.value
                                              ? selectedRoomOption?.value
                                              : selectedRoomOption[0]
                                          : data && data?.assignedChannel
                                }
                                placeholder="Search channels"
                                disabled={isDisabled || !!channelIdFromUrl}
                                teamIds={selectedTeamOptions?.value ? [selectedTeamOptions?.value] : teamIds}
                            />
                        </div>
                        {data && data.assignedChannel && (
                            <ToolTip text="Go to channel" cssStyles={'top:-70% !important; min-width: 97px !important'}>
                                <a
                                    href={`/teams/${
                                        (selectedRoomOption && selectedRoomOption?.value) ||
                                        (data && data.assignedChannel)
                                    }`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <BsArrowBarRight size={40} className="text-primary" />
                                </a>
                            </ToolTip>
                        )}
                    </div>
                    <Form.Text className="text-danger">{channelError}</Form.Text>
                    <Fragment>
                        <CustFormLabel>Forms</CustFormLabel>
                        <Select
                            className="basic-single my-2"
                            classNamePrefix="select"
                            name="Forms"
                            options={forms}
                            value={selectedForm}
                            onChange={(selected) => {
                                setSelectedForm(selected);
                            }}
                            closeMenuOnSelect={false}
                            isMulti
                            isLoading={loadingUsers}
                            isDisabled={loadingUsers}
                        />
                        <Form.Text className="text-danger"></Form.Text>
                    </Fragment>

                    {selectedEventTypeOption && (
                        <div className="mt-4">
                            {hasConference ? (
                                <div className="d-flex justify-content-between">
                                    <div className="alert alert-info py-2 px-4 mr-2">
                                        <MdCall className="mr-2" /> Conference added to the event.
                                        <small className="d-block">
                                            {' '}
                                            Assigneed participant can join the conference at the event time.
                                        </small>
                                    </div>
                                    <div>
                                        <Button
                                            variant="outline-danger"
                                            className="d-flex align-items-center"
                                            onClick={() => setHasConference(false)}
                                        >
                                            <AiOutlineClose className="cursor-pointer mr-2" />
                                            Remove Conference
                                        </Button>
                                    </div>
                                </div>
                            ) : (
                                <Button className="d-flex align-items-center " onClick={handleHasConference}>
                                    <MdCall className="mr-2" /> Add conference to the event
                                </Button>
                            )}
                        </div>
                    )}

                    <CustFormLabel>Name of Event</CustFormLabel>
                    <Form.Control
                        ref={register}
                        type="text"
                        name="title"
                        css={css`
                            font-size: 16px !important;
                        `}
                        placeholder={`Event name`}
                        defaultValue={data && data.title}
                    />
                    <Form.Text className="text-danger">{errors.title?.message}</Form.Text>
                    <CustFormLabel>Description</CustFormLabel>

                    <Form.Control
                        ref={register}
                        placeholder="Add Short description"
                        css={css`
                            font-size: 16px !important;
                        `}
                        as="textarea"
                        name="description"
                        defaultValue={data && data.description}
                    />
                    <Form.Text className="text-danger">{errors.description?.message}</Form.Text>
                    <CustFormLabel>Event Mode</CustFormLabel>

                    <Form.Control
                        ref={register}
                        disabled={isDisabled}
                        onChange={handleEventModeChange}
                        as="select"
                        name="eventMode"
                        defaultValue={eventMode}
                        style={{ fontSize: '18px' }}
                    >
                        <option value="onDemand">On Demand</option>
                        <option value="live">Live</option>
                    </Form.Control>

                    <CustFormLabel className="d-flex justify-content-between">
                        Assignee
                        <div
                            className="w-25 d-flex justify-content-end"
                            css={css`
                                [disabled] {
                                    pointer-events: none;
                                    cursor: not-allowed;
                                }
                            `}
                        >
                            {usersInRoom && usersInRoom?.length !== selectedUserOption?.length ? (
                                <StyledLinkBtn
                                    onClick={handleSelectAllUsers}
                                    disabled={
                                        !(data?.assignedChannel || selectedRoomOption || channelIdFromUrl) ||
                                        data?.eventPackage?._id ||
                                        data?.eventPackage
                                    }
                                >
                                    <ImCheckboxUnchecked />
                                    Select all
                                </StyledLinkBtn>
                            ) : (
                                <StyledLinkBtn
                                    onClick={handleDeselectAllUsers}
                                    disabled={
                                        !(data?.assignedChannel || selectedRoomOption || channelIdFromUrl) ||
                                        data?.eventPackage?._id ||
                                        data?.eventPackage
                                    }
                                >
                                    <ImCheckboxChecked />
                                    Remove all
                                </StyledLinkBtn>
                            )}
                        </div>
                    </CustFormLabel>

                    <div
                        className="m-0 p-0"
                        sm="12"
                        css={css`
                            display: flex;
                            border: 1px solid #cacaca;
                            border-radius: 5px;
                            padding: 0.2rem;
                            flex-wrap: wrap;
                            max-height: 300px;
                            overflow-y: scroll;
                            opacity: ${data ? 0.8 : 1};
                        `}
                    >
                        {selectedRoomOption || channelIdFromUrl ? (
                            loadingUsers ? (
                                <div
                                    css={css`
                                        margin: 0;
                                        padding-left: 0.8rem;
                                        display: flex;
                                        align-items: center;
                                        justify-content: center;
                                        width: 100%;
                                        overflow: hidden;
                                    `}
                                >
                                    <VscLoading className="spin" />
                                </div>
                            ) : usersInRoom ? (
                                usersInRoom.map((user) => (
                                    <UserInRoom
                                        key={user._id}
                                        user={user}
                                        onSelect={handleUserSelect}
                                        isSelected={selectedUserOption?.includes(user._id)}
                                        disabled={
                                            !(data?.assignedChannel || selectedRoomOption || channelIdFromUrl) ||
                                            data?.eventPackage?._id ||
                                            data?.eventPackage
                                        }
                                    />
                                ))
                            ) : (
                                <div>No one found</div>
                            )
                        ) : (
                            <p
                                css={css`
                                    margin: 0.6rem 0px;
                                    padding-left: 0.8rem;
                                    display: flex;
                                    align-items: center;
                                    color: gray;
                                `}
                            >
                                No channel selected yet.
                            </p>
                        )}
                    </div>
                    <Form.Text className="text-danger">{userError}</Form.Text>
                    <Fragment>
                        <br />
                        <OurAccordion
                            title={
                                <CustFormLabel>
                                    <div className="d-flex align-items-center">
                                        <FaBellSlash />
                                        <span className="ml-2">Turn off Notifications For</span>
                                        <ConsentToolTip text="The following unchecked users may receive email, push and SMS notifications for this event" />
                                    </div>
                                </CustFormLabel>
                            }
                            titleTag="span"
                            defaultShow={false}
                            style={{ border: '1px solid #cacaca', borderRadius: '5px' }}
                        >
                            <div className="d-flex justify-content-between">
                                <div></div>
                                <div
                                    className="w-25 d-flex justify-content-end"
                                    css={css`
                                        [disabled] {
                                            pointer-events: none;
                                            cursor: not-allowed;
                                        }
                                    `}
                                >
                                    {allUsersInRoom && allUsersInRoom?.length !== notificationDisabledFor?.length ? (
                                        <StyledLinkBtn
                                            onClick={handleNotifySelectAllUsers}
                                            disabled={
                                                !(data?.assignedChannel || selectedRoomOption || channelIdFromUrl)
                                            }
                                        >
                                            <ImCheckboxUnchecked />
                                            Select all
                                        </StyledLinkBtn>
                                    ) : (
                                        <StyledLinkBtn
                                            onClick={handleNotifyDeselectAllUsers}
                                            disabled={
                                                !(data?.assignedChannel || selectedRoomOption || channelIdFromUrl)
                                            }
                                        >
                                            <ImCheckboxChecked />
                                            Remove all
                                        </StyledLinkBtn>
                                    )}
                                </div>
                            </div>
                            <div
                                className="m-0 p-0"
                                sm="12"
                                css={css`
                                    display: flex;
                                    border-radius: 5px;
                                    padding: 0.2rem;
                                    flex-wrap: wrap;
                                    max-height: 300px;
                                    overflow-y: scroll;
                                    opacity: ${data ? 0.8 : 1};
                                `}
                            >
                                {selectedRoomOption || channelIdFromUrl ? (
                                    loadingUsers || !allUsersInRoom?.length ? (
                                        <div
                                            css={css`
                                                margin: 0;
                                                padding-left: 0.8rem;
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                                width: 100%;
                                                overflow: hidden;
                                            `}
                                        >
                                            <VscLoading className="spin" />
                                        </div>
                                    ) : allUsersInRoom ? (
                                        allUsersInRoom.map((user) => (
                                            <UserInRoom
                                                key={user._id}
                                                user={user}
                                                onSelect={handleNotifyUserSelect}
                                                isSelected={notificationDisabledFor?.includes(user._id)}
                                                disabled={
                                                    !(data?.assignedChannel || selectedRoomOption || channelIdFromUrl)
                                                }
                                            />
                                        ))
                                    ) : (
                                        <div>No one found</div>
                                    )
                                ) : (
                                    <p
                                        css={css`
                                            margin: 0.6rem 0px;
                                            padding-left: 0.8rem;
                                            display: flex;
                                            align-items: center;
                                            color: gray;
                                        `}
                                    >
                                        No channel selected yet.
                                    </p>
                                )}
                            </div>
                        </OurAccordion>
                    </Fragment>

                    <CustFormLabel>Select Timezone</CustFormLabel>
                    <TimezoneSelect value={selectedTimezone} onChange={setSelectedTimezone} />
                    <CustFormLabel>Event Start</CustFormLabel>
                    <DateTimePicker
                        onChange={onStartDateChange}
                        value={typeof startDate === 'string' ? new Date(startDate) : startDate}
                        ref={register}
                        format="MM-dd-y hh:mm a"
                        disableClock={true}
                        calendarType={'US'}
                        // disabled={isDisabled}
                    />
                    <CustFormLabel>Event End</CustFormLabel>
                    <DateTimePicker
                        onChange={onEndDateChange}
                        value={typeof endDate === 'string' ? new Date(endDate) : endDate}
                        format="MM-dd-y hh:mm a"
                        disableClock={true}
                        calendarType={'US'}
                        // disabled={isDisabled}
                    />
                    <Form.Text className="text-danger">{endDateError}</Form.Text>
                    {eventMode === 'live' ? (
                        showRecurring ? (
                            <CustomRecurrence
                                eventStartDate={startDate}
                                recurrenceData={recurrenceData}
                                setRecurrenceData={setRecurrenceData}
                                onRemoveRecurrence={() => {
                                    setShowRecurring(false);
                                    setRecurrenceData({});
                                    setRecurringMessage('');
                                    setRecurringUntilError('');
                                }}
                                eventRecurringUntil={recurringUntil}
                                onRecurringUntilChange={onRecurringUntilChange}
                                recurringMessage={recurringMessage}
                                recurringUntilError={recurringUntilError}
                            />
                        ) : (
                            <Fragment>
                                <CustFormLabel></CustFormLabel>
                                <StyledLinkBtn
                                    onClick={() => {
                                        setShowRecurring(true);
                                        setRecurrenceData(getRecurrenceData('Daily', startDate));
                                    }}
                                >
                                    <HiPlusCircle />
                                    Event Recurrence
                                </StyledLinkBtn>
                            </Fragment>
                        )
                    ) : null}

                    {(isPackageModal || mode !== 'edit') && (
                        <>
                            {data ? (
                                showUserStartReminder ? (
                                    <Fragment>
                                        <CustFormLabel>Start Reminders</CustFormLabel>
                                        <div className="my-2 d-flex">
                                            {reminderSet.all.map((rem) => {
                                                return (
                                                    <div key={rem.value} className="d-flex align-items-center">
                                                        <input
                                                            id={`userstart-${rem.value}`}
                                                            type="checkbox"
                                                            css={customCheckbox}
                                                            onChange={handleUserStartReminderChange}
                                                            value={rem.value}
                                                            checked={userStartReminder.includes(rem.value)}
                                                        />{' '}
                                                        <label htmlFor={`userstart-${rem.value}`} className="mr-4">
                                                            {rem.label}
                                                        </label>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        {userStartReminder.length > 0 ? (
                                            <Form.Text className="small text-muted font-italic">
                                                We will remind you{' '}
                                                {userStartReminder.map((reminder, idx) => {
                                                    let reminderTxt = '';
                                                    if (idx === 0) {
                                                        reminderTxt = reminder;
                                                    } else {
                                                        reminderTxt += `, ${reminder}`;
                                                    }
                                                    return reminderTxt;
                                                })}{' '}
                                                before the event start.
                                            </Form.Text>
                                        ) : null}
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        <CustFormLabel></CustFormLabel>
                                        <StyledLinkBtn
                                            onClick={() => {
                                                seteditUserStartTimeReminder(true);
                                            }}
                                        >
                                            <HiPlusCircle />
                                            Start reminders
                                        </StyledLinkBtn>

                                        {editUserStartTimeReminder && (
                                            <Fragment>
                                                <CustFormLabel>Start Reminders</CustFormLabel>
                                                <div className="my-2 d-flex">
                                                    {reminderSet.all.map((rem) => {
                                                        return (
                                                            <div key={rem.value} className="d-flex align-items-center">
                                                                <input
                                                                    id={`userstart-${rem.value}`}
                                                                    type="checkbox"
                                                                    css={customCheckbox}
                                                                    onChange={handleUserStartReminderChange}
                                                                    value={rem.value}
                                                                    checked={userStartReminder.includes(rem.value)}
                                                                />{' '}
                                                                <label
                                                                    htmlFor={`userstart-${rem.value}`}
                                                                    className="mr-4"
                                                                >
                                                                    {rem.label}
                                                                </label>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                                {userStartReminder.length > 0 ? (
                                                    <Form.Text className="small text-muted font-italic">
                                                        We will remind you{' '}
                                                        {userStartReminder.map((reminder, idx) => {
                                                            let reminderTxt = '';
                                                            if (idx === 0) {
                                                                reminderTxt = reminder;
                                                            } else {
                                                                reminderTxt += `, ${reminder}`;
                                                            }
                                                            return reminderTxt;
                                                        })}{' '}
                                                        before the event start.
                                                    </Form.Text>
                                                ) : null}
                                                <StyledLinkBtn
                                                    className="danger"
                                                    onClick={() => {
                                                        seteditUserStartTimeReminder(false);
                                                    }}
                                                >
                                                    <HiMinusCircle />
                                                    Remove Start Reminders
                                                </StyledLinkBtn>
                                            </Fragment>
                                        )}
                                    </Fragment>
                                )
                            ) : showReminder ? (
                                <Fragment>
                                    <CustFormLabel>Start Reminders</CustFormLabel>
                                    <div className="my-2 d-flex">
                                        {reminderSet.all.map((reminder) => {
                                            return (
                                                <div key={reminder.value} className="d-flex align-items-center">
                                                    <input
                                                        id={reminder.value}
                                                        type="checkbox"
                                                        css={customCheckbox}
                                                        onChange={handleReminderChange}
                                                        value={reminder.value}
                                                        checked={reminderMode.includes(reminder.value)}
                                                    />
                                                    <label htmlFor={reminder.value} className="mr-4">
                                                        {reminder.label}
                                                    </label>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    {reminderMode.length > 0 ? (
                                        <Form.Text className="small text-muted font-italic">
                                            We will remind you{' '}
                                            {reminderMode.map((reminder, idx) => {
                                                let reminderTxt = '';
                                                if (idx === 0) {
                                                    reminderTxt = reminder;
                                                } else {
                                                    reminderTxt += `, ${reminder}`;
                                                }
                                                return reminderTxt;
                                            })}{' '}
                                            before the event.
                                        </Form.Text>
                                    ) : null}

                                    <StyledLinkBtn
                                        className="danger"
                                        onClick={() => {
                                            setShowReminder(false);
                                            setReminderMode([]);
                                        }}
                                    >
                                        <HiMinusCircle />
                                        Remove Start Reminders
                                    </StyledLinkBtn>
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <CustFormLabel></CustFormLabel>
                                    <StyledLinkBtn
                                        onClick={() => {
                                            setShowReminder(true);
                                        }}
                                    >
                                        <HiPlusCircle />
                                        Start Reminders
                                    </StyledLinkBtn>
                                </Fragment>
                            )}

                            {data ? (
                                showUserEndReminder ? (
                                    <Fragment>
                                        <CustFormLabel> End Reminders</CustFormLabel>
                                        <div className="my-2 d-flex">
                                            {reminderSet.all.map((rem) => {
                                                return (
                                                    <div key={rem.value} className="d-flex align-items-center">
                                                        <input
                                                            id={`userend-${rem.value}`}
                                                            type="checkbox"
                                                            css={customCheckbox}
                                                            onChange={handleUserEndReminderChange}
                                                            value={rem.value}
                                                            checked={userEndReminder.includes(rem.value)}
                                                        />
                                                        <label htmlFor={`userend-${rem.value}`} className="mr-4">
                                                            {rem.label}
                                                        </label>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        {userEndReminder.length > 0 ? (
                                            <Form.Text className="small text-muted font-italic">
                                                We will remind you{' '}
                                                {userEndReminder.map((reminder, idx) => {
                                                    let reminderTxt = '';
                                                    if (idx === 0) {
                                                        reminderTxt = reminder;
                                                    } else {
                                                        reminderTxt += `, ${reminder}`;
                                                    }
                                                    return reminderTxt;
                                                })}{' '}
                                                before the event end.
                                            </Form.Text>
                                        ) : null}
                                    </Fragment>
                                ) : (
                                    <Fragment>
                                        <CustFormLabel></CustFormLabel>
                                        <StyledLinkBtn
                                            onClick={() => {
                                                seteditUserEndTimeReminder(true);
                                            }}
                                        >
                                            <HiPlusCircle />
                                            End Reminders
                                        </StyledLinkBtn>

                                        {editUserEndTimeReminder && (
                                            <Fragment>
                                                <CustFormLabel>End Reminders</CustFormLabel>
                                                <div className="my-2 d-flex">
                                                    {reminderSet.all.map((rem) => {
                                                        return (
                                                            <div key={rem.value} className="d-flex align-items-center">
                                                                <input
                                                                    id={`userend-${rem.value}`}
                                                                    type="checkbox"
                                                                    css={customCheckbox}
                                                                    onChange={handleUserEndReminderChange}
                                                                    value={rem.value}
                                                                    checked={userEndReminder.includes(rem.value)}
                                                                />{' '}
                                                                <label
                                                                    htmlFor={`userend-${rem.value}`}
                                                                    className="mr-4"
                                                                >
                                                                    {rem.label}
                                                                </label>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                                {userEndReminder.length > 0 ? (
                                                    <Form.Text className="small text-muted font-italic">
                                                        We will remind you{' '}
                                                        {userEndReminder.map((reminder, idx) => {
                                                            let reminderTxt = '';
                                                            if (idx === 0) {
                                                                reminderTxt = reminder;
                                                            } else {
                                                                reminderTxt += `, ${reminder}`;
                                                            }
                                                            return reminderTxt;
                                                        })}{' '}
                                                        before the event end.
                                                    </Form.Text>
                                                ) : null}
                                                <StyledLinkBtn
                                                    className="danger"
                                                    onClick={() => {
                                                        seteditUserEndTimeReminder(false);
                                                    }}
                                                >
                                                    <HiMinusCircle />
                                                    Remove End Reminders
                                                </StyledLinkBtn>
                                            </Fragment>
                                        )}
                                    </Fragment>
                                )
                            ) : showEndTimeReminder ? (
                                <Fragment>
                                    <CustFormLabel>End Reminders</CustFormLabel>
                                    <div className="my-2 d-flex">
                                        {reminderSet.all.map((reminder) => {
                                            return (
                                                <div key={reminder.value} className="d-flex align-items-center">
                                                    <input
                                                        id={`endtime-${reminder.value}`}
                                                        type="checkbox"
                                                        css={customCheckbox}
                                                        onChange={handleEndTimeReminderChange}
                                                        value={reminder.value}
                                                        checked={endTimeReminderMode.includes(reminder.value)}
                                                    />
                                                    <label htmlFor={`endtime-${reminder.value}`} className="mr-4">
                                                        {reminder.label}
                                                    </label>
                                                </div>
                                            );
                                        })}
                                    </div>
                                    {endTimeReminderMode.length > 0 ? (
                                        <Form.Text className="small text-muted font-italic">
                                            We will remind you{' '}
                                            {endTimeReminderMode.map((reminder, idx) => {
                                                let reminderTxt = '';
                                                if (idx === 0) {
                                                    reminderTxt = reminder;
                                                } else {
                                                    reminderTxt += `, ${reminder}`;
                                                }
                                                return reminderTxt;
                                            })}{' '}
                                            before the event end.
                                        </Form.Text>
                                    ) : null}

                                    <StyledLinkBtn
                                        className="danger"
                                        onClick={() => {
                                            setShowEndTimeReminder(false);
                                            setEndTimeReminderMode([]);
                                        }}
                                    >
                                        <HiMinusCircle />
                                        Remove End Reminders
                                    </StyledLinkBtn>
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <CustFormLabel></CustFormLabel>
                                    <StyledLinkBtn
                                        onClick={() => {
                                            setShowEndTimeReminder(true);
                                        }}
                                    >
                                        <HiPlusCircle />
                                        End Reminders
                                    </StyledLinkBtn>
                                </Fragment>
                            )}
                        </>
                    )}
                    {/* for select courses  */}
                    {selectedEventTypeOption != null && selectedEventTypeOption?.title === 'Online Training' && (
                        <div>
                            <CustFormLabel>Courses</CustFormLabel>
                            <Select
                                className="basic-single my-2"
                                classNamePrefix="select"
                                name="trainings"
                                options={courseOptions}
                                value={selectedCourses}
                                placeholder="Select Courses"
                                onChange={(selected) => {
                                    setSelectedCourses(selected);
                                }}
                                // onChange={onSelectChange}
                                closeMenuOnSelect={false}
                                isMulti
                            />
                            <Form.Text className="text-danger"></Form.Text>
                        </div>
                    )}

                    <Form.Text className="text-danger"></Form.Text>
                </Fragment>
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-between w-100">
                {/* <div className="d-flex justify-content-between w-100"> */}
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button
                    variant="primary"
                    className={` btn button-green`}
                    type="button"
                    onClick={handleSubmit(onSubmit)}
                    disabled={isSubmitting || loadingUsers}
                >
                    {isSubmitting ? <VscLoading className="spin" /> : <Fragment>{displayText}</Fragment>}
                </Button>
                {/* </div> */}
            </Modal.Footer>
        </Form>
    );
};

const mapStateToProps = (state) => ({
    currUser: state.user.user,
});

export const EventModalForm = connect(mapStateToProps, {})(EventModalFormWithoutReduxState);
