import { jwtCheck } from './jwt-check';

export async function requestHeaders(noJSON, requireAuth = true) {
    // return authorization header with jwt token
    let Token = requireAuth ? await jwtCheck(true) : null;
    const baseHeader = noJSON ? {} : { 'Content-Type': 'application/json' };
    const authHeader = Token ? { Authorization: `bearer ${Token}` } : {};
    return { ...authHeader, ...baseHeader };
}

export function encodeQueryParams(url) {
    try {
        const [baseUrl, queryString] = url.split('?');
        if (!queryString) return baseUrl;
        const encodedQuery = queryString
            .split('&')
            .map((param) => {
                const [key, value] = param.split('=');
                return `${key}=${encodeURIComponent(value || '')}`;
            })
            .join('&');
        console.log(baseUrl, queryString);
        return `${baseUrl}?${encodedQuery}`;
    } catch (error) {
        return url;
    }
}
