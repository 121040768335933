import { css } from 'twin.macro';
import { Fragment, useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { FaLink } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { fetchWrapper } from '../../../_helpers';
import { FaShareSquare } from 'react-icons/fa';
import { Switch } from '../../../_elements/FormElements';
import { ToolTip } from '../../../_elements/ToolTip';
import DateTimePicker from 'react-datetime-picker/dist/DateTimePicker';
import loaderStyles from '../../../_elements/loader';
import dayjs from 'dayjs';

const modalStyles = css`
    .modal-content {
        padding: 2rem 1rem;
    }
`;

function ShareForm(props) {
    const [show, setShow] = useState(false);
    const [sharedForm, setSharedForm] = useState({});
    const [isBusy, setIsBusy] = useState(false);
    const [endDate, setEndDate] = useState(null);
    const [isAcceptingResponses, setIsAcceptingResponses] = useState(true);

    useEffect(() => {
        if (props.checkIsShowing) {
            props.checkIsShowing(show);
        }
    }, [props, show]);

    const handleClose = () => {
        if (props?.handleFormClosed) {
            props.handleFormClosed();
        }
        setShow(false);
    };

    const handleShow = () => {
        setShow(true);
        if (props.handleFormOpened) {
            props.handleFormOpened();
        }
    };

    const getValidEndDate = (date = null) => {
        if (date) {
            return date.toISOString();
        }
    };

    const generateLink = async (e) => {
        e.preventDefault();
        if (props?.form?.type !== 'normal') {
            return toast.error('Only normal forms can have a link');
        }
        try {
            setIsBusy(true);
            const _endDate = getValidEndDate(endDate);

            const response = await fetchWrapper.post(`shared-forms/generate-link`, {
                endDate: _endDate,
                chatroom: props.roomId,
                form: props.form._id,
                acceptResponse: isAcceptingResponses,
            });
            setSharedForm(response || {});
        } catch (error) {
            toast.error(error?.message);
        } finally {
            setIsBusy(false);
        }
    };

    const fetchSharedForm = async (formId, roomId) => {
        try {
            setIsBusy(true);
            const response = await fetchWrapper.get(`shared-forms?form=${formId}&chatroom=${roomId}`);
            if (response?._id) {
                setSharedForm(response);
                setIsAcceptingResponses(response?.acceptResponse);
            }
            const _endDate = response?.endDate;
            setEndDate(_endDate ? new Date(_endDate) : endDate);
        } catch (err) {
            if (err?.name === 'AbortError') {
                return;
            }
            console.log('err', err);
            toast.error(err?.message);
            setSharedForm(err?.form || {});
        } finally {
            setIsBusy(false);
        }
    };

    const updatePublicForm = async (sharedFormId) => {
        try {
            const payload = { endDate: getValidEndDate(endDate), acceptResponse: isAcceptingResponses };
            setIsBusy(true);
            const response = await fetchWrapper.put(`shared-forms/${sharedFormId}`, {
                ...payload,
            });
            if (response?._id) {
                setSharedForm(response || {});
                setIsAcceptingResponses(response?.acceptResponse);
            }
            const _endDate = response?.endDate;
            setEndDate(_endDate ? new Date(_endDate) : endDate);
            toast.success('Updated successfully!');
        } catch (err) {
            console.log('err', err);
            toast.error(err?.message);
        } finally {
            setIsBusy(false);
        }
    };

    const handleCopyToClipboard = () => {
        try {
            if (sharedForm?.url) {
                navigator.clipboard.writeText(sharedForm?.url);
                toast.info('Form Link copied to clipboard');
            }
        } catch (err) {
            console.log('err', err);
        }
    };

    useEffect(() => {
        if (props?.form?._id && props?.roomId && show) {
            fetchSharedForm(props?.form?._id, props?.roomId);
        }
    }, [props.form?._id, props?.roomId, show]);

    // useEffect(() => {
    //     return ()=>{
    //         show && handleClose()
    //     }
    // }, [show]);
    return (
        <Fragment>
            <ToolTip text="Generate Link" side="right" cssStyles={`top:-80% !important; `}>
                <div
                    onClick={handleShow}
                    className="pointer d-flex"
                    css={css`
                        align-items: center;
                        gap: 6px;
                        padding: 4px;
                        flex-wrap: wrap;
                        @media (max-width: 600px) {
                            justify-content: center;
                        }
                    `}
                >
                    <FaLink />
                </div>
            </ToolTip>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                css={modalStyles}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size="lg"
            >
                <Modal.Header closeButton>
                    <div
                        css={css`
                            text-align: center;
                            width: 100%;
                            margin-bottom: 2rem;
                        `}
                    >
                        <h3>
                            <b>{props?.form?.name}</b>
                            {sharedForm?.createdAt && (
                                <p
                                    css={css`
                                        font-size: 1.5rem;
                                        margin-top: 0.5rem;
                                    `}
                                >
                                    Updated At: {dayjs(sharedForm?.updatedAt).format('MMMM D, YYYY h:mm A')}
                                </p>
                            )}
                        </h3>
                    </div>
                </Modal.Header>
                <Modal.Body className="position-relative">
                    {isBusy && (
                        <div
                            css={css`
                                height: 100%;
                                width: 100%;
                            `}
                        >
                            <div css={loaderStyles}>
                                <img src="/images/loading.gif" alt="loader" />
                            </div>
                        </div>
                    )}

                    <Form>
                        <Form.Group controlId="formExpirationDate">
                            <div
                                css={css`
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                `}
                            >
                                <Form.Label>
                                    <b>Stop Accepting Response At:</b>
                                </Form.Label>
                                <DateTimePicker
                                    css={css`
                                        width: 300px;
                                    `}
                                    onChange={setEndDate}
                                    minDate={endDate || new Date()}
                                    defaultValue={endDate}
                                    value={endDate}
                                    format="MM-dd-y hh:mm a"
                                    disableClock={true}
                                    calendarType={'US'}
                                />
                            </div>
                        </Form.Group>

                        <Form.Group className="d-flex align-items-center">
                            <Switch
                                id="acceptingResponses"
                                label={isAcceptingResponses ? 'Accepting Responses' : 'Not Accepting Responses'}
                                checked={isAcceptingResponses}
                                onChange={() => setIsAcceptingResponses(!isAcceptingResponses)}
                                disabled={isBusy}
                                labelStyle={`${isAcceptingResponses ? '' : 'color: #be0000;'}`}
                                disableTextClick={true}
                            />
                        </Form.Group>
                        <Form.Group className="d-flex justify-content-end">
                            {sharedForm?.url ? (
                                <Button
                                    variant="primary"
                                    disabled={isBusy}
                                    onClick={() => updatePublicForm(sharedForm?._id)}
                                >
                                    Update
                                </Button>
                            ) : (
                                <Button variant="primary" disabled={isBusy || sharedForm?.url} onClick={generateLink}>
                                    Generate Link
                                </Button>
                            )}
                        </Form.Group>

                        {sharedForm?.url && (
                            <div
                                css={css`
                                    margin-top: 10.5rem;
                                `}
                            >
                                <strong
                                    css={css`
                                        margin-right: 1rem;
                                    `}
                                >
                                    Sharable Link:
                                </strong>
                                <span
                                    onClick={handleCopyToClipboard}
                                    className="pointer"
                                    css={css`
                                        padding: 1rem;
                                        border: none;
                                        background: #e89842;
                                        border-radius: 50%;
                                        color: white;
                                        height: 40px;
                                        width: 40px;
                                        text-align: center;
                                    `}
                                >
                                    <FaShareSquare size={24} />
                                </span>

                                <div
                                    css={css`
                                        overflow: hidden;
                                        white-space: nowrap;
                                        text-overflow: ellipsis;
                                        width: 100%;
                                        margin-top: 1rem;
                                    `}
                                >
                                    <a href={sharedForm?.url} target="_blank" rel="noopener noreferrer">
                                        {sharedForm?.url}
                                    </a>
                                </div>
                            </div>
                        )}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => {
                            if (!isBusy) handleClose();
                        }}
                        disabled={isBusy}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </Fragment>
    );
}

export default ShareForm;
