import { css } from 'twin.macro';
import { NavLink, Link } from 'react-router-dom';
import PropTypes from 'prop-types';

//components
import Versioning from '../../Common/Versioning';
import logo from '../../../../assets/logos/logo.svg';
import { SidebarStyled } from './SidebarStyles';
// import noImg from '../../../../assets/no-img.png';

//redux
import { connect } from 'react-redux';
import { useLogout } from '../../Common/useLogout';
import Profile from '../../../Profile/Profile';

import { FaBell, FaMapMarkedAlt, FaPowerOff, FaUsers, FaRegCalendarAlt } from 'react-icons/fa';
import { BsChatDots } from 'react-icons/bs';
import { IoMdBookmarks } from 'react-icons/io';
import { HiChevronDoubleDown } from 'react-icons/hi';
import { TbArrowBarRight } from 'react-icons/tb';
import { RiAdminFill } from 'react-icons/ri';
import { Badge } from 'react-bootstrap';
function SideBar({ logoutUser, currUser, mobileSidebar, setMobileSidebar, activities }) {
    const { logoutFunction } = useLogout();

    return (
        <SidebarStyled className={`${mobileSidebar ? '' : 'd-none'} d-md-block sidebar-ani`}>
            <div className="d-flex sidebar-content flex-column justify-content-between align-items-center">
                <div className="mid-content">
                    <div className="icons-li d-none d-md-flex">
                        <Link to="/">
                            <img src={logo} className="logo h-auto" alt="logo" />
                        </Link>
                    </div>
                    {mobileSidebar && (
                        <div className={`hide-sidebar`} onClick={() => setMobileSidebar(false)}>
                            <HiChevronDoubleDown />
                        </div>
                    )}
                    <NavLink activeClassName="selected text-decoration-none " className="icons-li" exact to="/">
                        <div className="link d-flex flex-column align-items-center">
                            <FaMapMarkedAlt className="icon" />
                            <span>Map</span>
                        </div>
                    </NavLink>
                    <NavLink activeClassName="selected text-decoration-none " className="icons-li" to="/activity">
                        <div
                            className="link d-flex flex-column align-items-center"
                            css={css`
                                position: relative;
                            `}
                        >
                            <FaBell className="icon" />
                            {Boolean(currUser.unreadActivity) && (
                                <span
                                    css={css`
                                        top: -15px;
                                        right: 5px;
                                        position: absolute;
                                    `}
                                >
                                    <Badge variant="primary" className="text-light">
                                        {currUser.unreadActivity}
                                    </Badge>
                                </span>
                            )}
                            <span>Activity</span>
                        </div>
                    </NavLink>

                    <NavLink activeClassName="selected" className="icons-li" to="/chat">
                        <div className="d-flex flex-column align-items-center link">
                            <BsChatDots className="icon" />
                            <span>Chat</span>
                        </div>
                    </NavLink>
                    <NavLink
                        activeClassName="selected"
                        className="icons-li"
                        to={`${currUser?.lastSeenChannel ? `/teams/${currUser?.lastSeenChannel}#0` : '/teams'}`}
                    >
                        <div className="d-flex flex-column align-items-center link">
                            <FaUsers className="icon" />
                            <span>Teams</span>
                        </div>
                    </NavLink>
                    <NavLink
                        activeClassName="selected"
                        className="icons-li"
                        to={`${currUser?.lastSeenSubChannel ? `/event-channel/${currUser?.lastSeenSubChannel}` : '/event-channel'}`}
                    >
                        <div className="d-flex flex-column align-items-center link">
                            <TbArrowBarRight className="icon" />
                            <span>Subchannels</span>
                        </div>
                    </NavLink>
                    <NavLink activeClassName="selected" className="icons-li" to="/dashboard/manager">
                        <div className="d-flex flex-column align-items-center link">
                            <RiAdminFill className="icon" />
                            <span>Manager</span>
                        </div>
                    </NavLink>
                    <NavLink activeClassName="selected" className="icons-li" to="/events">
                        <div className="d-flex flex-column align-items-center link">
                            <FaRegCalendarAlt className="icon" />
                            <span>Calendar</span>
                        </div>
                    </NavLink>
                    <NavLink activeClassName="selected" className="icons-li" to="/courses">
                        <div className="d-flex flex-column align-items-center link">
                            <IoMdBookmarks className="icon" />
                            <span>Courses</span>
                        </div>
                    </NavLink>

                    <div className="profile">
                        <div className="icons-li">
                            {currUser && <Profile />}
                            <span>Profile</span>
                        </div>
                    </div>

                    <div className="d-sm-flex d-md-none power">
                        <Link to="#0" className="icons-li" onClick={() => logoutFunction()}>
                            <FaPowerOff className="icon" />
                            <span>Log Out</span>
                        </Link>
                    </div>
                </div>
                <div
                    className="d-none d-md-flex"
                    css={css`
                        width: 100%;
                        justify-content: center;
                        align-items: center;
                    `}
                >
                    <Link className="power" to="#0" onClick={() => logoutFunction()}>
                        <FaPowerOff
                            size={25}
                            css={css`
                                margin-bottom: 20px;
                            `}
                        />
                    </Link>
                    <Versioning />
                </div>
            </div>
        </SidebarStyled>
    );
}

const mapStateToProps = (state) => ({
    currUser: state.user.user,
    activities: state.activities.activities,
});

SideBar.propTypes = {
    currUser: PropTypes.object,
};

export default connect(mapStateToProps, {})(SideBar);
