import React, { useState } from 'react';

const FileUploader = ({
    accept,
    onFileUpload,
    buttonText,
    loadingText,
    successMessage,
    errorMessage,
    uploadedFileName,
    fileLimit,
    showTips,
    tips = [],
    getOriginalFile = false,
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [uploadError, setUploadError] = useState(null);
    const [dragging, setDragging] = useState(false);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (!file) return;

        handleFileUpload(file);
    };

    const handleFileUpload = (file) => {
        if (file.size > fileLimit * 1024 * 1024) {
            setUploadError(`File exceeds ${fileLimit}MB size limit`);
            return;
        }

        setIsLoading(true);
        setUploadSuccess(false);
        setUploadError(null);

        const formData = new FormData();
        formData.append('file', file);

        onFileUpload(getOriginalFile ? file : formData)
            .then(() => {
                setUploadSuccess(true);
                setIsLoading(false);
            })
            .catch((error) => {
                setUploadError(errorMessage || 'File upload failed');
                setIsLoading(false);
            });
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        setDragging(true);
    };

    const handleDragLeave = () => {
        setDragging(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setDragging(false);
        const file = e.dataTransfer.files[0];
        if (file) {
            handleFileUpload(file);
        }
    };

    return (
        <div>
            <div
                className={`upload-container ${dragging ? 'dragging' : ''}`}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
                style={{
                    border: dragging ? '2px dashed #50aeb0' : '2px dashed #ccc',
                    padding: '20px',
                    textAlign: 'center',
                    cursor: 'pointer',
                }}
            >
                <p>{dragging ? 'Drop your file here' : 'Drag and drop a file here, or click below to upload'}</p>

                <input
                    type="file"
                    accept={accept}
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                    id="file-upload-input"
                />
                <label
                    htmlFor="file-upload-input"
                    className="upload-label"
                    style={{
                        cursor: 'pointer',
                        display: 'inline-block',
                        padding: '10px',
                        background: '#50aeb0',
                        color: '#fff',
                        borderRadius: '5px',
                        marginTop: '10px',
                    }}
                >
                    {buttonText || 'Click to Upload'}
                </label>

                {isLoading && <p>{loadingText || 'Uploading...'}</p>}
                {uploadSuccess && <p style={{ fontWeight: 'bold' }}>{successMessage || 'Upload Successful!'}</p>}
                {uploadedFileName && <p style={{ fontWeight: 'bold' }}>{uploadedFileName}</p>}
                {uploadError && <p style={{ color: 'red' }}>{uploadError}</p>}
            </div>

            {showTips && (
                <div className="upload-tips">
                    <ul style={{ listStyleType: 'none' }}>
                        {tips.map((tip, index) => (
                            <li key={index}>{tip}</li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default FileUploader;
