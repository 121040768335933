import React, { useState, useEffect, Fragment, forwardRef, useImperativeHandle } from 'react';
import { PDFDocument, rgb } from 'pdf-lib';
import { Document, Page } from 'react-pdf';
import loaderStyles from '../../../_elements/loader';
import { toast } from 'react-toastify';
import { css } from 'twin.macro';

const TemplatePreview = forwardRef(({ formTemplateUrl, formElements = [], formName = '', pdfDimension }, ref) => {
    const [pdfUrl, setPdfUrl] = useState(null);
    const [pdfBytes, setPdfBytes] = useState(null);
    const [formValues, setFormValues] = useState(null);
    const [imageValues, setImageValues] = useState(null);
    const [numPages, setNumPages] = useState(null);
    const [isBusy, setIsBusy] = useState(true);
    const [error, setError] = useState(null);

    const templateFormElements = (formElements || [])?.filter((x) => x?.config?.templateConfig);

    useEffect(() => {
        if (!isBusy) {
            const initialValues = {};
            const imageValues = {};

            templateFormElements.forEach((_field) => {
                const field = { ..._field?.config?.templateConfig, ..._field };
                const fieldId = _field?._id || _field?.id;
                if (field.key === 'InputField') {
                    initialValues[fieldId] = field.value;
                } else if (field.key === 'Signature') {
                    imageValues[fieldId] = {
                        url: `${field.value}?noCache=${Math.random()}`,
                    };
                }
            });

            setFormValues(initialValues);
            setImageValues(imageValues);
        }
    }, [isBusy]);

    useEffect(() => {
        if (formValues && imageValues) {
            setTimeout(() => download(true), 1000);
        }
    }, [formValues, imageValues]);

    useEffect(() => {
        if (formTemplateUrl) {
            fetchPDF(formTemplateUrl);
        }
    }, [formTemplateUrl]);

    useImperativeHandle(ref, () => ({
        download,
    }));

    const fetchPDF = async (url) => {
        try {
            const response = await fetch(url);
            const buffer = await response.arrayBuffer();
            setPdfBytes(buffer);
        } catch (error) {
            console.error('Error fetching PDF:', error);
            toast.error('Failed to fetch template. Please try again.');
        } finally {
            setIsBusy(false);
        }
    };

    const drawTextInField = async (page, text, field, fontSize = 9, lineHeightFactor = 1.1) => {
        const padding = 0;
        const lineHeight = fontSize * lineHeightFactor;
        const formattedText = text.replaceAll('\r', '');
        const spacesToFitWidth = Math.ceil(field.width / Math.floor(fontSize / 2));
        const wrapText = (text, maxCharsPerLine) => {
            const words = text.split(' ');
            const lines = [];
            let currentLine = '';

            words.forEach((word) => {
                if ((currentLine + word).length > maxCharsPerLine) {
                    lines.push(currentLine.trim());
                    currentLine = word + ' ';
                } else {
                    currentLine += word + ' ';
                }
            });
            if (currentLine.trim()) {
                lines.push(currentLine.trim());
            }

            return lines;
        };

        const finalLines = formattedText.split('\n').flatMap((line) => wrapText(line, spacesToFitWidth));

        let currentY = field.y;
        finalLines.forEach((line) => {
            // if (currentY < field.y - field.height + fontSize) return;
            page.drawText(line, {
                x: field.x + padding,
                y: currentY - fontSize - padding,
                size: fontSize,
                color: rgb(0, 0, 0),
            });
            currentY -= lineHeight;
        });
    };

    const toAbsoluteCoordinates = (field) => ({
        x: field.x * pdfDimension.width,
        y: (1 - field.y) * pdfDimension.height,
        width: field.width * pdfDimension.width,
        height: field.height * pdfDimension.height,
    });

    const base64ToArrayBuffer = (base64) => Uint8Array.from(atob(base64.split(',')[1]), (c) => c.charCodeAt(0));

    const drawImageInField = async (pdfDoc, page, field, imageUrl) => {
        const padding = 0;
        const availableBoxWidth = field.coordinates.pdfWidth;
        const availableBoxHeight = field.coordinates.pdfHeight;
        let imageBytes;

        try {
            if (imageUrl.includes('image/png')) {
                imageBytes = base64ToArrayBuffer(imageUrl);
            } else {
                imageBytes = await fetch(imageUrl).then((res) => res.arrayBuffer());
            }

            const image =
                imageUrl.includes('.png') || imageUrl.includes('image/png')
                    ? await pdfDoc.embedPng(imageBytes)
                    : await pdfDoc.embedJpg(imageBytes);

            const { width: imageWidth, height: imageHeight } = image.scale(1);
            const imageAspectRatio = imageWidth / imageHeight;

            let resizedWidth = availableBoxWidth;
            let resizedHeight = availableBoxHeight;
            if (resizedWidth / resizedHeight > imageAspectRatio) {
                resizedWidth = resizedHeight * imageAspectRatio;
            } else {
                resizedHeight = resizedWidth / imageAspectRatio;
            }

            const centerX = field.coordinates.pdfX + (availableBoxWidth - resizedWidth) / 2;
            const centerY = field.coordinates.pdfY + (availableBoxHeight - resizedHeight) / 2;

            page.drawImage(image, {
                x: centerX,
                y: centerY + padding,
                width: resizedWidth,
                height: resizedHeight - padding * 2,
            });
        } catch (error) {
            console.error('Error embedding signature:', error);
        }
    };

    const download = async (preview = false) => {
        try {
            const pdfDoc = await PDFDocument.load(pdfBytes);
            const pages = pdfDoc.getPages();

            for (const _field of templateFormElements) {
                const fieldId = _field?._id || _field?.id;
                const field = { ..._field?.config?.templateConfig, ..._field };
                const page = pages[field.page - 1];

                if (field.key === 'Signature' && imageValues[fieldId]?.url) {
                    const imageUrl = imageValues[fieldId]?.url;
                    await drawImageInField(pdfDoc, page, field, imageUrl);
                } else if (field.key === 'InputField') {
                    const absField = toAbsoluteCoordinates(field.coordinates);
                    await drawTextInField(page, formValues[fieldId] || '', absField);
                }
            }

            const blob = new Blob([await pdfDoc.save()], {
                type: 'application/pdf',
            });
            const url = URL.createObjectURL(blob);
            if (preview) {
                setPdfUrl(url);
            } else {
                const link = document.createElement('a');
                link.href = url;
                link.download = `${formName || 'form'}.pdf`;
                link.click();
                URL.revokeObjectURL(url);
            }
        } catch (error) {
            const message = 'Error generating Template PDF. Please try again.';
            console.error(message, error);
            toast.error(message);
            setError(message);
        }
    };

    const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages);

    if (error) {
        return <div className="text-danger text-center mt-4"> {error} </div>;
    }

    return (
        <Fragment>
            {pdfUrl && !isBusy ? (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: 'auto',
                        width: '100%',
                        height: 'auto',
                        backgroundColor: '#525659',
                        padding: '5px',
                    }}
                >
                    <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
                        {Array.from({ length: numPages }, (_, index) => (
                            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                        ))}
                    </Document>
                </div>
            ) : (
                <div css={loaderStyles}>
                    <div
                        css={css`
                            display: flex;
                            align-items: center;
                            gap: 10px;
                            flex-direction: column;
                        `}
                    >
                        <img src="/images/loading.gif" alt="loader" />

                        <div
                            css={css`
                                text-align: center;
                            `}
                        >
                            Generating Template PDF ...
                        </div>
                    </div>
                </div>
            )}
        </Fragment>
    );
});

export default TemplatePreview;
