import Select from 'react-select/async';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { addRooms, setRooms } from '../../_redux/actions/ActionMessage';
import { getTeamIdFromURL, fetchWrapper, getUniqueObjects, sortingOptionMethod } from '../../_helpers';

const ChannelSelectFilter = ({
    teamIds,
    assignedChannel,
    teams,
    teamRooms,
    totalRooms,
    selectedOption,
    onRoomSelect = () => {},
    disabled = false,
    addRooms,
    setRooms,
    user,
    ...props
}) => {
    const [defaultValue, setDefaultValue] = useState(selectedOption);

    const [isLoading, setIsLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [_, setPage] = useState();
    const [scrolledToBottom, setScrolledToBottom] = useState(false);

    const allRoomsFetched = (teamRooms, teamIds) => {
        if (
            Object.values(teamRooms)
                .map((teamRoom) => teamRoom.rooms || [])
                .flat().length >= (totalRooms || 9e9)
        ) {
            return true;
        }
        return teamIds.every(
            (teamId) => (teamRooms[teamId]?.rooms?.length || 0) >= (teamRooms[teamId]?.totalRooms || 9e9)
        );
    };

    //#TODO Decide if better to fetch from URL or from parent component as props, also clearly define weather it is the admin or normal user
    if (!teamIds || !teamIds.every((id) => id)) {
        const urlTeamId = getTeamIdFromURL();
        teamIds = urlTeamId
            ? [urlTeamId]
            : user.teamList?.filter(({ role }) => role !== 'user')?.map(({ team }) => team?._id || team?.id || team);
    }

    useEffect(() => {
        setSearch('');
        if (!allRoomsFetched(teamRooms, teamIds)) {
            setPage(0);
            setScrolledToBottom(true);
        }
    }, [teamIds.toString()]);

    useEffect(() => {
        if (
            teamIds &&
            teamIds.every((id) => id) &&
            scrolledToBottom &&
            !isLoading &&
            !allRoomsFetched(teamRooms, teamIds)
        ) {
            setPage((prev) => {
                const newPage = prev + 1;
                fetchRooms(newPage);
                return newPage;
            });
        }
    }, [scrolledToBottom, teamIds.toString()]);

    useEffect(() => {
        const allRooms = Object.values(teamRooms)
            .map((teamRoom) => teamRoom.rooms)
            .flat();
        if (allRooms.length && assignedChannel) {
            try {
                if (Array.isArray(assignedChannel)) {
                    assignedChannel = assignedChannel[0];
                }
                if (typeof assignedChannel === 'string') {
                    let selectedRoom = allRooms.filter((room) => assignedChannel === room._id);
                    const groupby = selectedRoom.map((room) => ({
                        label: room.title,
                        value: room.id || room._id,
                    }));
                    setDefaultValue(groupby);
                } else {
                    setDefaultValue({
                        label: assignedChannel.label || assignedChannel.title,
                        value: assignedChannel.value || assignedChannel.id || assignedChannel._id,
                    });
                }
            } catch (err) {
                console.log(err);
            }
        }
    }, [teamRooms, assignedChannel]);

    const onRoomSearchChange = (selectedRoom) => {
        if (Array.isArray(selectedRoom)) {
            onRoomSelect(selectedRoom.length ? selectedRoom : []);
        } else {
            onRoomSelect(selectedRoom);
        }
    };

    const mapRoomsToOptions = (rooms) => {
        const filteredRooms = props?.allowedRooms
            ? (rooms || []).filter((room) => (props?.allowedRooms || []).includes(room?._id || room?.id))
            : rooms;
        return getUniqueObjects(filteredRooms || [])
            .map((room) => ({
                label: room?.title,
                value: room?._id || room?.id,
                type: room.type,
                _id: room?._id || room?.id,
                title: room?.title,
            }))
            .sort(sortingOptionMethod);
    };

    const groupedRoomOptions = (roomsData, title = search) => {
        // here grouby first sorts by team name then it filters room with matching team and sorts by title and it maps label set to title of room and value set to room id
        // label gives team name and option gives team specific channels and using map gives overall all team name and team specific channels
        const teamRooms = Array.isArray(roomsData)
            ? roomsData.reduce((accumulator, current) => {
                  if (title && !new RegExp(title, 'i').test(current.title)) {
                      return accumulator;
                  }
                  const rooms = [
                      ...(accumulator[current.team]?.rooms || []).filter(
                          (room) => (room.id || room._id) !== (current.id || current._id)
                      ),
                      current,
                  ];
                  accumulator[current.team] = { rooms };
                  return accumulator;
              }, {})
            : roomsData || {};
        const optionTeams =
            teamIds && teamIds.every((id) => id) ? teams.filter((team) => teamIds.includes(team.id || team._id)) : [];
        return optionTeams
            ?.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
            .map((team) => ({
                label: team.name,
                options: mapRoomsToOptions(
                    (teamRooms[team.id || team._id]?.rooms || []).filter(
                        ({ title: roomTitle }) => !title || new RegExp(title, 'i').test(roomTitle)
                    )
                ),
            }));
    };

    const fetchRooms = async (page = 1, title = '') => {
        try {
            setIsLoading(true);
            if (allRoomsFetched(teamRooms, teamIds)) {
                return [];
            }
            const data = await fetchWrapper.get(
                `/chatrooms?teamId=${teamIds}&page=${page}&limit=100${title ? `&title=${title}` : ''}&sortBy=title:asc`
            );

            if (data?.teamRooms) {
                setRooms(data.results, totalRooms);
                data.teamRooms.forEach(({ _id: teamId, totalResults }) =>
                    addRooms(teamId, [], title ? teamRooms[teamId]?.totalRooms : totalResults)
                );
            } else {
                addRooms(teamIds[0], data.results, title ? teamRooms[teamIds[0]]?.totalRooms : data.totalResults);
            }

            const options = groupedRoomOptions(data.results, title);
            return options;
        } catch (error) {
            return [];
        } finally {
            setIsLoading(false);
            setScrolledToBottom(false);
        }
    };

    let timeout;
    const loadRoomOptions = (search) => {
        return new Promise((resolve) => {
            clearTimeout(timeout);
            if (allRoomsFetched(teamRooms, teamIds)) {
                resolve(groupedRoomOptions(teamRooms, search));
            }
            timeout = setTimeout(() => {
                if (!search) {
                    resolve(groupedRoomOptions(teamRooms));
                }
                fetchRooms(1, search).then((options) => {
                    setSearch(search);
                    resolve(options);
                });
            }, 500);
        });
    };

    return (
        <div
            style={{
                minWidth: '200px',
                gap: '6px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
            }}
        >
            <div
                style={{
                    width: '100%',
                    position: 'relative',
                    // display: 'flex',
                    gap: '6px',
                }}
            >
                <Select
                    name="channel"
                    value={selectedOption || defaultValue}
                    defaultOptions={groupedRoomOptions(teamRooms)}
                    loadOptions={loadRoomOptions}
                    onChange={onRoomSearchChange}
                    isClearable={!props.required}
                    placeholder="Select Room"
                    isDisabled={disabled}
                    isLoading={isLoading}
                    onMenuScrollToBottom={() => {
                        setScrolledToBottom(true);
                    }}
                    styles={{
                        option: (baseStyles, state) => ({
                            ...baseStyles,
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }),
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            // borderRadius: '12px',
                            minHeight: '3.7rem',
                        }),
                    }}
                    theme={(theme) => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            primary25: '#c3e4e2',
                            primary: '#50aeb0',
                        },
                    })}
                    {...props}
                ></Select>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    teams: state.teams.teams,
    teamRooms: state.rooms.teamRooms,
    totalRooms: state.rooms.totalRooms,
    user: state.user.user,
});

const mapDispatchToProps = { addRooms, setRooms };

export default connect(mapStateToProps, mapDispatchToProps)(ChannelSelectFilter);
