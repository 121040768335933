import React from 'react';

import { css } from 'twin.macro';
import { toast } from 'react-toastify';
import { FaShareSquare } from 'react-icons/fa';

export const ShareBtnAction = ({ url }) => {
    const handleCopy = () => {
        navigator.clipboard.writeText(url);
        toast.info('File Link copied to clipboard');
    };
    return (
        <button
            css={css`
                padding: 1rem;
                border: none;
                background: #e89842;
                color: white;
                text-align: center;
                border-radius: 1000px;
                display: flex;
                &:hover {
                    cursor: pointer;
                    transform: scale(1.1);
                    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 8px;
                }
            `}
            className="shareBtn"
            onClick={handleCopy}
        >
            <FaShareSquare fontSize={18} />
        </button>
    );
};

function ShareBtn({ url }) {
    const handleCopy = () => {
        navigator.clipboard.writeText(url);
        toast.info('File Link copied to clipboard');
    };
    return (
        <button
            css={css`
                position: absolute;
                top: 0;
                right: 0;
                padding: 1rem;
                border: none;
                background: #e89842;
                color: white;
                text-align: center;
                border-radius: 1000px;
                display: flex;
                opacity: 0;
            `}
            className="shareBtn"
            onClick={handleCopy}
        >
            <FaShareSquare fontSize={18} />
        </button>
    );
}

export default ShareBtn;
