import React from 'react';
import { css } from 'twin.macro';
import { getRandomColor } from '../../../../_helpers';
import { RemoveRectButton } from './components';

const MappedFields = ({ rects, updateFieldName, updateFieldKey, removeField }) => {
    return (
        <div className="w-100 d-flex justify-content-center items-center py-4">
            <div
                css={css`
                    padding: 32px;
                    border-radius: 8px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
                    width: 800px;
                `}
            >
                <h2
                    className="font-bold mb-4 "
                    css={css`
                        border-bottom: 2px solid #000;
                    `}
                >
                    Mapped Fields From PDF
                </h2>
                <div
                    css={css`
                        width: 100%;
                    `}
                >
                    {(rects || []).map((rect, idx) => (
                        <div
                            key={idx}
                            className="bg-white rounded  p-2"
                            css={css`
                                display: flex;
                                flex-direction: row;
                                justify-content: space-around;
                                background-color: #e0f0ef;
                            `}
                        >
                            <div
                                css={css`
                                    flex: 1;
                                `}
                            >
                                <span
                                    css={css`
                                        color: ${getRandomColor(String(idx))};
                                        margin-right: 4px;
                                        font-weight: bold;
                                    `}
                                >
                                    {idx + 1}.
                                </span>
                                <input
                                    type="text"
                                    value={rect.fieldName}
                                    onChange={(e) => updateFieldName(idx, e.target.value)}
                                    className="rounded px-4 py-2 w-full"
                                    placeholder="Enter Field Name"
                                    css={css`
                                        border: 2px solid #ccc;
                                        font-size: 16px;
                                        width: 90%;
                                        // color: ${getRandomColor(String(idx))};
                                        &:focus {
                                            border-color: transparent;
                                            outline: 2px solid #50aeb0;
                                        }
                                    `}
                                />
                            </div>
                            <div>
                                <select
                                    value={rect.key}
                                    onChange={(e) => updateFieldKey(idx, e.target.value)}
                                    className="border rounded px-4 py-2 w-full"
                                >
                                    <option value="InputField">Input Field</option>
                                    <option value="Signature">Signature</option>
                                </select>
                            </div>
                            <div className="ml-2 py-2">
                                <RemoveRectButton onClick={removeField} index={idx} />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default MappedFields;
