import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { css } from 'twin.macro';

const columnBorderIndex = {};

const TableCell = ({ cell, cellIndex, rowIndex, style = {}, isSticky, formId }) => {
    const stickyStyles = isSticky
        ? css`
              position: sticky;
              left: 0;
              background-color: #ccc;
              z-index: 1;
          `
        : null;

    if (cell?.day) {
        columnBorderIndex[formId][cellIndex] = true;
    }

    const columnBorder =
        cell?.day || columnBorderIndex[formId][cellIndex]
            ? css`
                  border-left: 5px solid #999;
                  border-right: none;
              `
            : null;

    return (
        <td
            css={css`
                border: 1px solid #ddd;
                padding: 8px;
                ${stickyStyles}
                ${columnBorder}
              ${!cell?.day && rowIndex === 0 ? 'border: none;' : ''}
            `}
            style={style}
        >
            {cell?.image ? (
                <img
                    src={cell.image + '?noCache=' + Math.random().toString()}
                    alt={cell.value || 'Image'}
                    loading="lazy"
                    css={css`
                        width: auto;
                        max-height: 200px;
                        max-width: 500px;
                    `}
                />
            ) : (
                <div
                    css={css`
                        font-family: 'Arial', sans-serif;
                        font-size: 14px;
                        white-space: pre-wrap;
                    `}
                >
                    {cell?.value || ''}
                </div>
            )}
        </td>
    );
};

TableCell.propTypes = {
    cell: PropTypes.shape({
        value: PropTypes.string,
        image: PropTypes.string,
        style: PropTypes.object,
    }),
    style: PropTypes.object,
    isSticky: PropTypes.bool,
};

const TableRow = ({ rowData, rowIndex, columnWidths, isSticky, formId }) => {
    const newDayStyles =
        rowIndex < 3
            ? css`
                  background-color: #f0f0f0;
                  font-weight: bold;
              `
            : null;

    return (
        <tr key={rowIndex} css={newDayStyles}>
            {(rowData || []).map((cell, cellIndex) => (
                <TableCell
                    key={cellIndex}
                    rowIndex={rowIndex}
                    cell={cell}
                    cellIndex={cellIndex}
                    style={{ ...cell?.style, minWidth: '224px' }}
                    isSticky={cellIndex === 0 ? true : false}
                    formId={formId}
                />
            ))}
        </tr>
    );
};

TableRow.propTypes = {
    rowData: PropTypes.array.isRequired,
    rowIndex: PropTypes.number.isRequired,
    columnWidths: PropTypes.array.isRequired,
    isSticky: PropTypes.bool,
};

const Table = ({ data = [], columns = [], customStyles, tableRef, title, formId }) => {
    if (formId) {
        columnBorderIndex[formId] = {};
    }
    return (
        <Fragment>
            <h1
                className="text-left sticky w-100"
                css={css`
                    position: sticky;
                    left: 0;
                `}
            >
                {title || ''}
            </h1>

            <table
                css={css`
                    width: 100%;
                    border-collapse: collapse;
                `}
            >
                <tbody>
                    {data.length > 0 ? (
                        data.map((row, rowIndex) => (
                            <TableRow
                                key={rowIndex}
                                rowData={row}
                                rowIndex={rowIndex}
                                isSticky={true}
                                formId={formId}
                            />
                        ))
                    ) : (
                        <tr>
                            <td
                                colSpan={columns.length}
                                css={css`
                                    border: 1px solid #ddd;
                                    padding: 8px;
                                `}
                                style={{ minWidth: '264px' }}
                            >
                                No data available
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </Fragment>
    );
};

Table.propTypes = {
    data: PropTypes.array,
    columns: PropTypes.array,
    customStyles: PropTypes.object,
};

export default Table;
