import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { css } from 'twin.macro';
import { fetchWrapper, getTeamIdFromURL, getUniqueObjects } from '../../../../_helpers';
import { useContextStore } from '../../../../_context/contextStore';
import PropTypes from 'prop-types';

const TagSelectFilter = ({ teamId, type, placeholder = 'Select Tags', disabled, handleSelectCallback }) => {
    const team = teamId || getTeamIdFromURL();
    const { refetchTagsOnSubmit } = useContextStore();
    const [toggleRefetchFilterTags, setToggleRefetchFilterTags] = refetchTagsOnSubmit;
    const [selectedValues, setSelectedValues] = useState([]);
    const [responseResults, setResponseResults] = useState({ results: [], totalResults: 9e9 });

    const fetchData = (inputValue, callback) => {
        setTimeout(async () => {
            try {
                let tempArray = [];
                if (responseResults?.results?.length < responseResults?.totalResults || toggleRefetchFilterTags) {
                    const response = await fetchWrapper.get(
                        `tags?limit=100${team ? `&team=${team}` : ''}${type ? `&type=${type}` : ''}${inputValue ? `&name=${inputValue}` : ''}`
                    );
                    setResponseResults((prev) => {
                        const newResults = getUniqueObjects(prev?.results || [], response?.results || []);
                        return {
                            ...response,
                            results: newResults,
                            totalResults: inputValue.toString() ? prev?.totalResults || 9e9 : response?.totalResults,
                        };
                    });

                    tempArray = response?.results
                        .sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1))
                        .map((element) => ({
                            label: element?.name,
                            value: element?._id,
                        }));
                }

                if (!tempArray?.length) {
                    tempArray = responseResults?.results
                        ?.filter((tag) => tag?.name && tag?.name.toUpperCase().includes(inputValue?.toUpperCase()))
                        ?.map((filteredTag) => ({ label: filteredTag?.name, value: filteredTag?._id }))
                        ?.sort((a, b) => (a?.label < b?.label ? -1 : a > b ? 1 : 0));
                }
                callback(tempArray);
            } catch (error) {
                console.log(error);
            } finally {
                // setToggleRefetchFilterTags(false);
            }
        }, 1000);
    };

    const handleChange = (newValues) => {
        const newTags = newValues.map((tag) => ({ ...tag, label: tag?.label?.toUpperCase() }));
        setSelectedValues(() => newTags);
        if (handleSelectCallback) {
            handleSelectCallback(newTags);
        }
    };

    const debounce = (func, delay) => {
        let timeoutId;
        return (...args) => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => func(...args), delay);
        };
    };

    useEffect(() => {
        setToggleRefetchFilterTags(false);
    }, []);

    return (
        <div
            className="search-container"
            css={css`
                z-index: 99;
            `}
        >
            <AsyncSelect
                // cacheOptions={true}
                defaultOptions
                loadOptions={debounce(fetchData, 1000)}
                isMulti
                onChange={handleChange}
                value={selectedValues}
                placeholder={placeholder}
                isDisabled={disabled}
            />
        </div>
    );
};

TagSelectFilter.propTypes = {
    placeholder: PropTypes.string,
    team: PropTypes.string,
    type: PropTypes.string,
    disabled: PropTypes.bool,
    handleSelectCallback: PropTypes.func,
};

export default TagSelectFilter;
