import React, { useState } from 'react';
import { connect } from 'react-redux';
import EvvCreateModal from '../../../../Admin/EVVContainer/EvvCreateModal/EvvCreateModal';
import EvvCreateOptions from '../../../../Admin/EVVContainer/EvvCreateModal/EvvCreateOptions';
import { FaPlusCircle } from 'react-icons/fa';
import { Dropdown } from 'react-bootstrap';
import { AddMemberStyle } from '../Members';
import CreateChannelModal from '../../CreateChannelModal';
import { getTeamIdFromURL } from '../../../../../_helpers';
import BulkChannelModal from './BulkChannelModal';

const CreateChannels = ({ getTeamChannels = () => {} }) => {
    const teamId = getTeamIdFromURL();
    const [showModal, setShowModal] = useState(false);
    const [createChannelMode, setCreateChannelMode] = useState(null);

    const handleModalShow = () => {
        setCreateChannelMode(null);
        setShowModal(true);
    };
    const handleCloseAllModal = () => {
        setShowModal(false);
    };

    const handleGoBack = () => {
        setCreateChannelMode(null);
    };

    let content = null;
    switch (createChannelMode) {
        default:
            content = (
                <EvvCreateOptions
                    type={'channel'}
                    setCreateProviderMode={setCreateChannelMode}
                    fileType="excel (.xlsx)"
                />
            );
            break;
    }

    return (
        <>
            <div css={AddMemberStyle}>
                <Dropdown.Item style={{ borderRadius: '4px' }} onClick={handleModalShow}>
                    <FaPlusCircle />
                </Dropdown.Item>
            </div>
            {showModal && !createChannelMode && (
                <EvvCreateModal
                    type={'channel'}
                    showModal={showModal}
                    hideModal={handleCloseAllModal}
                    content={content}
                    goBack={createChannelMode ? handleGoBack : null}
                />
            )}
            {createChannelMode === 'individual' && (
                <CreateChannelModal
                    hideModal={handleCloseAllModal}
                    show={showModal}
                    setShow={setShowModal}
                    teamId={teamId}
                    isTeamSettings={true}
                    getTeamChannels={getTeamChannels}
                />
            )}
            {createChannelMode === 'bulk' && (
                <BulkChannelModal
                    hideModal={handleCloseAllModal}
                    show={showModal}
                    setShow={setShowModal}
                    getTeamChannels={getTeamChannels}
                />
            )}
        </>
    );
};

export default connect(null, {})(CreateChannels);
