import * as ActionTypes from './ActionTypes';
import { fetchWrapper } from '../../_helpers';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

export const getAllOrganization = () => async (dispatch) => {
    try {
        const response = await fetchWrapper.get(`organizations?limit=1000`);
        dispatch(setOrganizations(response));
    } catch (error) {}
};

export const CreateATeam = (organizationId, newTeam, file, admin, isFirst) => async (dispatch) => {
    try {
        dispatch(startTeamLoading());
        const response = await fetchWrapper.post(`organizations/${organizationId}/teams`, newTeam);
        response.id = response._id;
        response.room.id = response.room._id;

        dispatch(setMessage({ error: false, message: 'Team Created Succesfully' }));
        if (isFirst) {
            dispatch(setNewTeam(response));
        }
        if (file) {
            dispatch(postAvatarTeam(response.id, organizationId, file));
            dispatch(stopTeamLoading());
        } else {
            dispatch(stopTeamLoading());
        }
        return response;
    } catch (error) {
        dispatch(stopTeamLoading());
        dispatch(setMessage({ error: true, message: error.message || 'Something went wrong' }));
        return false;
    }
};

//if errors occured during this should update previous data
export const postAvatarTeam = (teamId, organizationId, file) => async (dispatch) => {
    dispatch(startTeamLoading());
    let user = JSON.parse(localStorage.getItem('user'));

    return fetch(backendUrl + `organizations/${organizationId}/teams/${teamId}/avatar`, {
        method: 'POST',
        body: file,
        headers: { Authorization: `bearer ${user.token}` },
        credentials: 'same-origin',
    })
        .then(
            (response) => {
                if (response.ok) return response;
                var error = new Error('Error ' + response.status + ': ' + response.statusText);
                error.response = response;
                throw error;
            },
            (error) => {
                throw error;
            }
        )
        .then((response) => {
            return response.json();
        })
        .then((response) => {
            dispatch(setEditTeam(response));
            dispatch(stopTeamLoading());
            // dispatch(setMessage({ error: false, message: 'Avatar Updated Succesfully' }));
        })
        .catch((error) => {
            dispatch(stopTeamLoading());
            dispatch(setMessage({ error: true, message: 'Something went wrong' }));
        });
};

export const editATeam = (organizationId, teamId, newTeam) => async (dispatch) => {
    try {
        dispatch(startTeamLoading());
        const response = await fetchWrapper.put(`organizations/${organizationId}/teams/${teamId}`, newTeam);
        dispatch(setEditTeam(response));
        dispatch(stopTeamLoading());
        dispatch(setMessage({ error: false, message: 'Team Updated Succesfully' }));
    } catch (error) {
        dispatch(stopTeamLoading());
        dispatch(setMessage({ error: true, message: error.message || 'Something went wrong' }));
    }
};

export const editATeamAvatar = (teamId, organizationId, file) => async (dispatch) => {
    dispatch(postAvatarTeam(teamId, organizationId, file));
};

export const GetAllTeams =
    (organizationId, page = 1, limit = 100) =>
    async (dispatch) => {
        try {
            dispatch(startTeamLoading());
            const response = await fetchWrapper.get(
                `organizations/${organizationId}/teams?sortBy=name:asc&limit=${limit}&page=${page}`
            );
            dispatch(setTeams(response.results, response.totalResults));
            dispatch(stopTeamLoading());
        } catch (error) {
            dispatch(stopTeamLoading());
        }
    };

export const GetMyTeams = (organizationId) => async (dispatch) => {
    try {
        dispatch(startTeamLoading());
        const response = await fetchWrapper.get(`organizations/${organizationId}/user-teams`);
        dispatch(setTeams(response.results, response.totalResults));
        dispatch(stopTeamLoading());
    } catch (error) {
        dispatch(stopTeamLoading());
    }
};

export const Get10Teams =
    ({ organizationId, page = 1, searchText = '', sortBy = 'name:asc' }) =>
    async (dispatch) => {
        try {
            dispatch(startTeamLoading());
            const response = await fetchWrapper.get(
                `organizations/${organizationId}/teams?${sortBy ? `sortBy=${sortBy}&` : ''}&limit=10&page=${page}${
                    searchText ? `&name=${searchText}` : ''
                }`
            );
            dispatch(set10Teams(response));
            dispatch(setTeams(response.results, response.totalResults));
            dispatch(stopTeamLoading());
        } catch (error) {
            dispatch(stopTeamLoading());
        }
    };

// export const setPublicTeams = (organizationId) => async (dispatch) => {
// 	try {
// 		const response = await fetchWrapper.get(`organizations/${organizationId}/team_list?limit=1000&page=1`);
// 		dispatch(setMyPublicTeams(response.results));
// 	} catch (error) {
// 		console.log(error);
// 	}
// };
// export const resetPublicTeams = () => async (dispatch) => {
// 	try {
// 		dispatch({
// 			type: ActionTypes.REMOVE_PUBLIC_TEAMS,
// 		});
// 	} catch (error) {
// 		console.log(error);
// 	}
// };

export const AssignUserToTeam =
    (userId, teamId, user, showSuccessToast = true) =>
    async (dispatch) => {
        const team = { teamId };
        return fetchWrapper
            .post(`users/${userId}/assignTeam`, team)
            .then(() => {
                if (user) {
                    const tempUser = { ...user };
                    tempUser.team.push(teamId);
                    dispatch(setNewTeamMember(tempUser));
                }
                if (showSuccessToast) {
                    return dispatch(setMessage({ error: false, message: 'Successfully Added To Team' }));
                }
            })
            .catch((error) => {
                return dispatch(setMessage({ error: true, message: error.message }));
            });
    };

export const AssignNewMemberToTeam = (orgId, teamId, user) => async (dispatch) => {
    try {
        const response = await fetchWrapper.post(`organizations/${orgId}/teams/${teamId}/invite`, {
            email: user.email,
            name: user.name,
        });
        dispatch(setMessage({ error: false, message: response.message }));
        dispatch(setNewTeamMember(user));
        return response;
    } catch (error) {
        dispatch(setMessage({ error: true, message: error.message || 'Something went wrong' }));
        return error;
    }
};

export const AssignManagerToTeam = (orgId, teamId, user, isUserInTeam) => async (dispatch) => {
    try {
        let sendingUser = {
            name: user.name,
            email: user.email,
        };
        const response = await fetchWrapper.post(`organizations/${orgId}/teams/${teamId}/invite-manager`, sendingUser);
        dispatch(setMessage({ error: false, message: response.message }));
        if (isUserInTeam) {
            dispatch(setManager({ teamId, email: user.email }));
        } else {
            dispatch(setNewTeamMember(user));
            dispatch(setManager({ teamId, email: user.email }));
        }
        return { response };
    } catch (error) {
        dispatch(setMessage({ error: true, message: error.message || 'Something went wrong' }));
        return { error };
    }
};

export const getTeamMember =
    (teamId, orgId, limit = 100) =>
    async (dispatch) => {
        try {
            const response = await fetchWrapper.get(
                `organizations/${orgId}/teams/${teamId}/members?limit=${limit || 100}`
            );
            dispatch(setTeamMembers(response.results));
        } catch (error) {
            console.log(error, 'ERROR');
        }
    };

export const getChannelMembers = (roomId) => async (dispatch) => {
    try {
        if (roomId) {
            //#TODO when members in a channel exceeds limit, it doesn't show the member in list neither mention in chat as in members view
            //there is no serverside pagination. Long term solution might be removing the server side pagination & if thats very expensive
            // use other means like caching, indexing or maybe adding userList in the room for two way referencing.
            const response = await fetchWrapper.get(`chatrooms/${roomId}/members?limit=1000`);
            dispatch(setChannelMembers(response));
        }
    } catch (error) {
        console.log(error, 'ERROR GETING CHANNEL MEMEBR');
    }
};

export const getSubChannelMembers = (subChannelId) => async (dispatch) => {
    try {
        if (subChannelId) {
            const response = await fetchWrapper.get(`subchannels/${subChannelId}/members?limit=1000`);
            dispatch(setSubChannelMembers(response));
        }
    } catch (error) {
        console.log(error, 'ERROR GETING CHANNEL MEMEBR');
    }
};

export const changeRoleInRoom = (data, roomId) => async (dispatch) => {
    await fetchWrapper.put(`chatrooms/${roomId}/accessControl`, data);
};

export const deleteATeamById = (orgId, teamId) => async (dispatch) => {
    try {
        dispatch(startTeamLoading());
        await fetchWrapper.delete(`organizations/${orgId}/teams/${teamId}`);
        dispatch(removeTeam(teamId));
        dispatch(stopTeamLoading());
        dispatch(setMessage({ error: false, message: 'Team Deleted Successfully' }));
    } catch (error) {
        dispatch(stopTeamLoading());
        dispatch(setMessage({ error: true, message: error.message || 'Something went wrong' }));
    }
};

export const deleteUserFromChannel = (chatroomId, userId) => async (dispatch) => {
    try {
        await fetchWrapper.delete(`chatrooms/${chatroomId}/${userId}/remove`);
        dispatch({ type: ActionTypes.REMOVE_CHANNEL_MEMBER, payload: userId });
        dispatch(setMessage({ error: false, message: 'User has been removed from the Channel' }));
        return userId;
    } catch (error) {
        dispatch(setMessage({ error: true, message: error.message || error.error || 'Something went wrong' }));
    }
};

export const deleteUserFromSubChannel = (subChannelId, userId) => async (dispatch) => {
    try {
        await fetchWrapper.delete(`subchannels/${subChannelId}/${userId}/remove`);
        dispatch({ type: ActionTypes.REMOVE_SUB_CHANNEL_MEMBER, payload: userId });
        dispatch(setMessage({ error: false, message: 'User has been removed from the sub Channel' }));
    } catch (error) {
        dispatch(setMessage({ error: true, message: error.message || error.error || 'Something went wrong' }));
    }
};

export const deleteChannelById = (roomId) => async (dispatch) => {
    try {
        const response = await fetchWrapper.delete(`chatrooms/${roomId}/delete`);
        dispatch(removeChannel(roomId));
        dispatch(setMessage({ error: false, message: response.message }));
        return response;
    } catch (error) {
        dispatch(setMessage({ error: true, message: error.error }));
        throw error;
    }
};

export const updateChannelNotification = (roomId, teamId, notificationConfig) => async (dispatch) => {
    try {
        dispatch(updateNotificationConfig(roomId, teamId, notificationConfig));
    } catch (error) {
        dispatch(setMessage({ error: true, message: error.message }));
        throw error;
    }
};

export const updateNotificationConfig = (roomId, teamId, notificationConfig) => ({
    type: ActionTypes.UPDATE_CHANNEL_NOTIFICATION,
    payload: {
        roomId,
        teamId,
        notificationConfig: notificationConfig,
    },
});

export const removeChannel = (channelId) => ({
    type: ActionTypes.REMOVE_CHANNEL,
    payload: channelId,
});

export const removeTeam = (teamId) => ({
    type: ActionTypes.REMOVE_TEAM,
    payload: teamId,
});

export const removeUserChannel = (channelId) => ({
    type: ActionTypes.REMOVE_USER_CHANNEL,
    payload: channelId,
});

export const removeUserTeam = (teamId) => ({
    type: ActionTypes.REMOVE_USER_TEAM,
    payload: teamId,
});

const setOrganizations = (organitations) => ({
    type: ActionTypes.SET_ORGANIZATION,
    payload: organitations,
});

export const updateOrganization = (organitation) => ({
    type: ActionTypes.UPDATE_ORGANIZATION,
    payload: organitation,
});
export const removeOrganization = (orgId) => ({
    type: ActionTypes.REMOVE_ORGANIZATION,
    payload: orgId,
});

export const setTeams = (teams, totalTeams) => ({
    type: ActionTypes.SET_TEAMS,
    payload: { teams, totalTeams },
});

const set10Teams = (teams) => ({
    type: ActionTypes.SET_TEN_TEAMS,
    payload: teams,
});

export const setNewTeam = (team) => ({
    type: ActionTypes.SET_NEW_TEAM,
    payload: team,
});

export const setEditTeam = (team) => ({
    type: ActionTypes.SET_EDIT_TEAM,
    payload: team,
});

export const setTeamOwnership = (team) => ({
    type: ActionTypes.SET_TEAM_OWNERSHIP,
    payload: team,
});

// export const setMyPublicTeams = (teams) => ({
// 	type: ActionTypes.SET_PUBLIC_TEAMS,
// 	payload: teams,
// });

const setTeamMembers = (teamMembers) => ({
    type: ActionTypes.SET_TEAM_MEMBER,
    payload: teamMembers,
});

const setNewTeamMember = (teamMember) => ({
    type: ActionTypes.SET_NEW_TEAM_MEMBER,
    payload: teamMember,
});

const setManager = (user) => ({
    type: ActionTypes.SET_MANAGER,
    payload: user,
});

const setChannelMembers = (channelMembers) => ({
    type: ActionTypes.SET_CHANNEL_MEMBER,
    payload: channelMembers,
});
const setSubChannelMembers = (subChannelMembers) => ({
    type: ActionTypes.SET_SUB_CHANNEL_MEMBER,
    payload: subChannelMembers,
});

export const setNewChannelMember = (channelMembers) => ({
    type: ActionTypes.SET_NEW_CHANNEL_MEMBER,
    payload: channelMembers,
});

export const setNewSubChannelMember = (subChannelMembers) => ({
    type: ActionTypes.SET_NEW_SUB_CHANNEL_MEMBER,
    payload: subChannelMembers,
});

export const setMessage = (message) => ({
    type: ActionTypes.SET_SHOW_MESSAGE,
    payload: message,
});

export const clearMessage = () => ({
    type: ActionTypes.CLEAR_SHOW_MESSAGE,
});

const startTeamLoading = () => ({
    type: ActionTypes.SET_TEAM_LOADING,
});

const stopTeamLoading = () => ({
    type: ActionTypes.CLEAR_TEAM_LOADING,
});
