import React from 'react';
import { css } from 'twin.macro';
export const Checkbox = ({ label, value, disabled = false, onChange = () => {} }) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <input
                type="checkbox"
                className="mr-2"
                style={{ width: '20px', height: '20px', marginTop: '0' }}
                checked={value}
                onChange={(e) => (!!disabled ? () => {} : onChange(e.target.checked))}
                disabled={disabled}
            />
            <span style={{ lineHeight: '20px', textTransform: 'capitalize' }}>{label}</span>
        </div>
    );
};

export const Switch = ({
    id,
    label = '',
    checked = false,
    onChange = () => {},
    disabled = false,
    size = 'medium',
    labelStyle = {},
    switchStyle = {},
    style = {},
    disableTextClick = true,
}) => {
    const sizeStyles = {
        small: { width: '3em', height: '1.6em', knobSize: '1.4em' },
        medium: { width: '4em', height: '2em', knobSize: '1.8em' },
        large: { width: '5em', height: '2.5em', knobSize: '2em' },
    };

    const currentSize = sizeStyles[size] || sizeStyles.medium;
    const handleLabelClick = (e) => {
        if (!disableTextClick && !disabled) {
            onChange(e);
        }
    };
    return (
        <label
            htmlFor={id}
            css={css`
                display: inline-flex;
                align-items: center;
                opacity: ${disabled ? 0.5 : 1};
                user-select: none;
                ${style}
            `}
        >
            <span
                css={css`
                    display: inline-block;
                    width: ${currentSize.width};
                    height: ${currentSize.height};
                    background-color: ${checked
                        ? switchStyle.switchColor || '#4CAF50'
                        : switchStyle.trackColor || '#ccc'};
                    border-radius: 50px;
                    position: relative;
                    transition: background-color 0.3s;
                    ${switchStyle}
                `}
            >
                <input
                    type="checkbox"
                    id={id}
                    checked={checked}
                    onChange={onChange}
                    disabled={disabled}
                    css={css`
                        opacity: 0;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        cursor: ${disabled ? 'not-allowed' : 'pointer'};
                    `}
                />
                <span
                    css={css`
                        position: absolute;
                        top: 0.1em;
                        left: ${checked ? `calc(100% - ${currentSize.knobSize})` : '0.1em'};
                        width: ${currentSize.knobSize};
                        height: ${currentSize.knobSize};
                        background-color: white;
                        border-radius: 50%;
                        transition: left 0.3s;
                        cursor: ${disabled ? 'not-allowed' : 'pointer'};
                        ${switchStyle.knob && { ...switchStyle.knob }}
                    `}
                />
            </span>
            <span
                onClick={handleLabelClick}
                css={css`
                    margin-left: 1em;
                    ${labelStyle}
                    cursor: ${disableTextClick ? '' : 'pointer'};
                `}
            >
                {label}
            </span>
        </label>
    );
};
