import * as ActionTypes from './ActionTypes';
import { fetchWrapper } from '../../_helpers';
import { logoutUser } from './ActionAuthentication';
import { getAddressFromLatLng } from '../../_helpers/methods';
export const getUserData = () => async (dispatch) => {
    try {
        const user = await fetchWrapper.get(`users/me`);
        dispatch(setUser(user));
        return user;
    } catch (error) {
        if (error.code === 401) {
            dispatch(logoutUser());
        }
    }
};

export const setUserRooms = (room, user) => (dispatch) => {
    dispatch(setUserRoomsAction(room));
};
///////
export const getOtherUserData = (userId) => async (dispatch) => {
    try {
        if (!userId) {
            return null;
        }
        dispatch({ type: ActionTypes.SET_USER_LOADING });
        const response = await fetchWrapper.get(`users/${userId}`);
        dispatch({ type: ActionTypes.CLEAR_USER_LOADING });
        return response;
    } catch (e) {}
};

export const deleteUser = (userId) => async (dispatch) => {
    try {
        await fetchWrapper.delete(`users/${userId}`);
        dispatch({ type: ActionTypes.ARCHIVE_USER, payload: userId });
        dispatch({
            type: ActionTypes.SET_SHOW_MESSAGE,
            payload: { error: false, message: 'User archived successfully' },
        });
    } catch (error) {
        dispatch({
            type: ActionTypes.SET_SHOW_MESSAGE,
            payload: { error: true, message: 'Something went wrong' },
        });
    }
};

export const getAllUser =
    ({ searchText = '', page = 1, sortBy = 'name:asc', filters = {} }) =>
    async (dispatch) => {
        try {
            dispatch({ type: ActionTypes.SET_USER_LOADING });
            let url = `users?${sortBy ? `sortBy=${sortBy}&` : ''}limit=10&page=${page}${
                searchText ? `&name=${searchText}` : ''
            }`;
            if (filters.verifiedUser) {
                if (filters.verifiedUser === 'verified') {
                    url += `&isEmailVerified=true`;
                }
                if (filters.verifiedUser === 'unverified') {
                    url += `&isEmailVerified=false`;
                }
                if (filters.teamId) {
                    url += `&team=${filters.teamId}`;
                }
            }
            const res = await fetchWrapper.get(url);
            dispatch(setAllUser(res));
            dispatch({ type: ActionTypes.CLEAR_USER_LOADING });
        } catch (error) {
            dispatch({ type: ActionTypes.CLEAR_USER_LOADING });
        }
    };

export const getChatableUser = (name, pageNo) => async (dispatch) => {
    try {
        const res = await fetchWrapper.get(
            `users/me/chatable?sortBy=name:asc&limit=10&page=${pageNo}${name ? `&name=${name}` : ''}`
        );
        dispatch({ type: ActionTypes.SET_CHATABLE_USER, payload: res });
    } catch (error) {}
};

export const clearChatableUsers = () => async (dispatch) => {
    try {
        dispatch({ type: ActionTypes.CLEAR_CHATABLE_USER });
    } catch (error) {}
};

export const setUserLocation = () => (dispatch) => {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(showPosition, showError, {
            timeout: 10000,
            maximumAge: 1000,
            enableHighAccuracy: true,
        });
    }
    async function showPosition(position) {
        if (!position?.coords?.latitude?.toString() || !position?.coords?.longitude?.toString()) {
            return dispatch(setError('Invalid location data received'));
        }
        const address = await getAddressFromLatLng(position.coords.latitude, position.coords.longitude);
        dispatch(setPos(position));
        dispatch(setAddress(address));
    }

    function showError(error) {
        switch (error.code) {
            case error.PERMISSION_DENIED:
                dispatch(setError('User denied the request for Geolocation.'));
                break;
            case error.POSITION_UNAVAILABLE:
                dispatch(setError('Location information is unavailable.'));
                break;
            case error.TIMEOUT:
                dispatch(setError('The request to get user location timed out.'));
                break;
            case error.UNKNOWN_ERROR:
                dispatch(setError('An unknown error occurred.'));
                break;
            default:
                dispatch(setError('An unknown error occurred.'));
        }
    }
};

export const setLastSeenChannel = (channelId) => (dispatch) => {
    dispatch({ type: ActionTypes.SET_LAST_SEEN_CHANNEL, payload: channelId });
};

export const setLastSeenSubChannel = (subChannelId) => (dispatch) => {
    dispatch({ type: ActionTypes.SET_LAST_SEEN_SUB_CHANNEL, payload: subChannelId });
};

export const setUser = (user) => ({
    type: ActionTypes.SET_USER,
    payload: user,
});

export const setUserRoomsAction = (room) => ({
    type: ActionTypes.SET_USER_ROOMS,
    payload: room,
});

export const setloginUser = () => ({
    type: ActionTypes.SET_LOGGEDIN,
});

export const setAllUser = (allUser) => ({
    type: ActionTypes.SET_ALL_USER,
    payload: allUser,
});
export const setNewUser = (user) => ({
    type: ActionTypes.SET_NEW_USER,
    payload: user,
});
const setPos = (position) => ({
    type: ActionTypes.SET_POSITION,
    payload: position,
});
const setAddress = (address) => ({
    type: ActionTypes.SET_ADDRESS,
    payload: address,
});

const setError = (error) => ({
    type: ActionTypes.SET_POS_ERROR,
    payload: error,
});
