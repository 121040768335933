import * as ActionTypes from './ActionTypes';
import { convertDateToISO, fetchWrapper } from '../../_helpers';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

export const getAllRooms =
    (page = 1, limit = 500) =>
    async (dispatch) => {
        try {
            const response = await fetchWrapper.get(`chatrooms?limit=${limit}&page=${page}`);
            dispatch(setRooms(response.results, response.totalResults));
        } catch (error) {}
    };

// legacy action
export const getAllSubChannels =
    (page = 1, limit = 100) =>
    async (dispatch) => {
        try {
            const response = await fetchWrapper.get(`subchannels?limit=${limit}&page=${page}`);
            dispatch(setSubChannels(response.results, response.totalResults));
        } catch (error) {}
    };

export const getRoomsByTeam =
    (teamId, page = 1, limit = 100) =>
    async (dispatch) => {
        try {
            dispatch(setIsAddingRoom(true));
            const response = await fetchWrapper.get(
                `chatrooms?teamId=${teamId}&limit=${limit}&page=${page}&sortBy=title:asc`
            );
            dispatch(addRooms(teamId, response.results, response.totalResults));
            return true;
        } catch (error) {
            dispatch(setIsAddingRoom(false));
            return false;
        }
    };
export const getClientRooms = (teamId) => async (dispatch) => {
    try {
        const response = await fetchWrapper.get(`chatrooms?teamId=${teamId}&limit=2000&page=1`);
        const results = response.results;
        var filteredArray = results.filter((item) => item.client);
        let object = filteredArray.reduce((obj, item) => Object.assign(obj, { [item.client]: item.id }), {});

        dispatch(addClientRooms(object));
        return true;
    } catch (error) {
        return false;
    }
};

export const setNewRoomRef = (roomId, ref) => async (dispatch) => {
    try {
        dispatch({
            type: ActionTypes.SET_NEW_ROOM_REF,
            payload: {
                roomId: roomId,
                ref: ref,
            },
        });
    } catch (error) {}
};

export const getTeamChat = (roomId, pageNo) => async (dispatch) => {
    try {
        if (pageNo === 1) {
            dispatch(startMessageLoading());
        }
        const response = await fetchWrapper.get(
            `chatrooms/${roomId}/messages?sortBy=createdAt:desc&limit=10&page=${pageNo}`
        );

        dispatch(setMessages({ response, id: roomId }));
        if (pageNo === 1) dispatch(getLastSeenRoomMessage(roomId));

        if (pageNo === 1) {
            dispatch(stopMessageLoading());
        }
    } catch (error) {
        if (pageNo === 1) {
            dispatch(stopMessageLoading());
        }
    }
};

export const postImageToChat = (roomId, file) => async (dispatch) => {
    dispatch({ type: ActionTypes.SET_UPLOADING });
    let user = JSON.parse(localStorage.getItem('user'));
    return await fetch(backendUrl + `chatrooms/${roomId}/file`, {
        method: 'POST',
        body: file,
        headers: { Authorization: `bearer ${user.token}` },
        credentials: 'same-origin',
    })
        .then(
            (response) => {
                if (response.ok) return response;

                var error = new Error('Error ' + response.status + ': ' + response.statusText);
                error.response = response;
                throw error;
            },
            (error) => {
                throw error;
            }
        )
        .then((response) => {
            return response.json();
        })
        .then((response) => {
            dispatch({ type: ActionTypes.CLEAR_UPLOADING });
        })
        .catch((error) => {
            dispatch({ type: ActionTypes.CLEAR_UPLOADING });
        });
};

export const postImageToSubChannel = (subChannelId, file) => async (dispatch) => {
    dispatch({ type: ActionTypes.SET_UPLOADING });
    let user = JSON.parse(localStorage.getItem('user'));
    return await fetch(backendUrl + `subchannels/${subChannelId}/file`, {
        method: 'POST',
        body: file,
        headers: { Authorization: `bearer ${user.token}` },
        credentials: 'same-origin',
    })
        .then(
            (response) => {
                if (response.ok) return response;

                var error = new Error('Error ' + response.status + ': ' + response.statusText);
                error.response = response;
                throw error;
            },
            (error) => {
                throw error;
            }
        )
        .then((response) => {
            return response.json();
        })
        .then((response) => {
            dispatch({ type: ActionTypes.CLEAR_UPLOADING });
        })
        .catch((error) => {
            dispatch({ type: ActionTypes.CLEAR_UPLOADING });
        });
};

export const postImageToDirectChat = (userId, file) => async (dispatch) => {
    dispatch({ type: ActionTypes.SET_UPLOADING });
    let user = JSON.parse(localStorage.getItem('user'));
    return await fetch(backendUrl + `directMessages/${userId}/file`, {
        method: 'POST',
        body: file,
        headers: { Authorization: `bearer ${user.token}` },
        credentials: 'same-origin',
    })
        .then(
            (response) => {
                if (response.ok) return response;

                var error = new Error('Error ' + response.status + ': ' + response.statusText);
                error.response = response;
                throw error;
            },
            (error) => {
                throw error;
            }
        )
        .then((response) => {
            return response.json();
        })
        .then((response) => {
            dispatch({ type: ActionTypes.CLEAR_UPLOADING });
        })
        .catch((error) => {
            dispatch({ type: ActionTypes.CLEAR_UPLOADING });
        });
};

export const uploadFile = async (file) => {
    try {
        let user = JSON.parse(localStorage.getItem('user'));
        const res = await fetch(backendUrl + `file`, {
            method: 'POST',
            body: file,
            headers: { Authorization: `bearer ${user.token}` },
            credentials: 'same-origin',
        });
        const response = await res.json();
        if (res.ok) {
            return response;
        } else {
            throw new Error(response?.message || 'Failed Uploading File');
        }
    } catch (error) {
        console.log(error);
        throw new Error(error?.message || 'Failed Uploading File');
    }
};

export const searchChat = (roomId, subChannelId, pageNo, searchCriteria, userId) => async (dispatch) => {
    try {
        let isSearchTextPresent = searchCriteria?.text?.length > 0;
        let isStartDatePresent = Boolean(searchCriteria?.startDate);
        let isEndDatePresent = Boolean(searchCriteria?.endDate);
        let type = searchCriteria?.type;

        if (pageNo === 1) {
            dispatch(startMessageLoading());
        }

        dispatch(setSearchFilter(searchCriteria));

        let query;

        if (userId) {
            query = `directMessages/${userId}?sortBy=createdAt:desc&limit=20&page=${pageNo}`;
        } else if (subChannelId) {
            query = `subchannels/${subChannelId}/messages?sortBy=createdAt:desc&limit=20&page=${pageNo}`;
        } else {
            query = `chatrooms/${roomId}/messages?sortBy=createdAt:desc&limit=20&page=${pageNo}`;
        }
        if (isSearchTextPresent) {
            query = `${query}&searchText=${searchCriteria.text}`;
        }

        if (isStartDatePresent) {
            let startDate = convertDateToISO(searchCriteria.startDate, { start: true });
            query = `${query}&startDate=${startDate}`;
        }
        if (isEndDatePresent) {
            let endDate = convertDateToISO(searchCriteria.endDate, { end: true });
            query = `${query}&endDate=${endDate}`;
        }
        if (type && type.value !== 'text') {
            query += `&type=${type.value}`;
        }

        let response = await fetchWrapper.get(query);
        dispatch(setSearchMessages(response));
        if (pageNo === 1) {
            dispatch(stopMessageLoading());
        }
    } catch (error) {
        console.log(error);
        if (pageNo === 1) {
            dispatch(stopMessageLoading());
        }
    }
};

export const getDirectMessageHistory = (userId, pageNo) => async (dispatch) => {
    try {
        if (pageNo === 1) {
            dispatch(startMessageLoading());
        }
        const response = await fetchWrapper.get(
            `directMessages/${userId}?sortBy=createdAt:desc&limit=10&page=${pageNo}`
        );

        dispatch(setMessages({ response, id: userId }));

        if (pageNo === 1) {
            dispatch(getLastSeenDirectMessage(userId));
            dispatch(stopMessageLoading());
        }
    } catch (error) {
        if (pageNo === 1) {
            dispatch(stopMessageLoading());
        }
    }
};

export const getSubChannelMessages = (subChannelId, pageNo) => async (dispatch) => {
    try {
        if (pageNo === 1) {
            dispatch(startMessageLoading());
        }
        const response = await fetchWrapper.get(
            `subchannels/${subChannelId}/messages?sortBy=createdAt:desc&limit=10&page=${pageNo}`
        );
        dispatch(setMessages({ response, id: subChannelId }));
        if (pageNo === 1) dispatch(getLastSeenSubChannelMessage(subChannelId));

        if (pageNo === 1) {
            dispatch(stopMessageLoading());
        }
    } catch (error) {
        if (pageNo === 1) {
            dispatch(stopMessageLoading());
        }
    }
};

export const getRecentChats = (userId, pageNo) => async (dispatch) => {
    try {
        dispatch(startChatLoading());
        const response = await fetchWrapper.get(`directMessages?sortBy=createdAt:asc&limit=50&page=1`);
        console.log(response, 'REcet');
        dispatch(setRecentChats(response));
        dispatch(stopChatLoading());
    } catch (error) {
        dispatch(stopChatLoading());
    }
};

export const getLastSeenRoomMessage = (roomId) => async (dispatch) => {
    try {
        const response = await fetchWrapper.get(`userChatroomMessage/${roomId}`);
        dispatch({ type: ActionTypes.SET_LAST_SEEN_MESSAGE, payload: { id: roomId, message: response.message } });
    } catch (err) {
        console.log(err);
    }
};

export const postLastSeenRoomMessage = (message) => async (dispatch) => {
    try {
        if (message?.user) {
            const response = await fetchWrapper.post(`userChatroomMessage`, message);
            return response;
        }
    } catch (e) {}
};

export const getLastSeenSubChannelMessage = (subChannelId) => async (dispatch) => {
    try {
        const response = await fetchWrapper.get(`userSubChannelMessage/${subChannelId}`);
        dispatch({ type: ActionTypes.SET_LAST_SEEN_MESSAGE, payload: { id: subChannelId, message: response.message } });
    } catch (err) {
        console.log(err);
    }
};

export const postLastSeenSubChannelMessage = (message) => async (dispatch) => {
    try {
        const response = await fetchWrapper.post(`userSubChannelMessage`, message);
        return response;
    } catch (e) {}
};

export const getLastSeenDirectMessage = (userId) => async (dispatch) => {
    const response = await fetchWrapper.get(`userDirectMessage/${userId}`);

    dispatch({ type: ActionTypes.SET_LAST_SEEN_MESSAGE, payload: { id: userId, message: response.message } });
};

export const postLastSeenDirectMessage = (message) => async (dispatch) => {
    try {
        await fetchWrapper.post(`userDirectMessage`, message);
    } catch (err) {
        console.log(err, 'userDirectMessage error');
    }
};

export const deleteDirectChatRoomMessage = (id, messageId) => async (dispatch) => {
    try {
        await fetchWrapper.delete(`directMessages/${messageId}`);
        dispatch({ type: ActionTypes.REMOVE_SEND_MESSAGE, payload: { id: id, messageId } });
    } catch (e) {}
};
export const editDirectChatRoomMessage = (id, messageId, message) => async (dispatch) => {
    try {
        await fetchWrapper.put(`directMessages/${messageId}`, message);
        dispatch({ type: ActionTypes.UPDATE_SEND_MESSAGE, payload: { id: id, messageId, message } });
    } catch (e) {}
};

export const deleteChatRoomMessage = (id, messageId) => async (dispatch) => {
    try {
        await fetchWrapper.delete(`chatrooms/message/${messageId}`);
        dispatch({ type: ActionTypes.REMOVE_SEND_MESSAGE, payload: { id, messageId } });
    } catch (e) {
        return e;
    }
};

export const deleteSubChannelMessage = (id, messageId) => async (dispatch) => {
    try {
        await fetchWrapper.delete(`subchannels/message/${messageId}`);
        dispatch({ type: ActionTypes.REMOVE_SEND_MESSAGE, payload: { id: id, messageId } });
    } catch (e) {
        return e;
    }
};

export const editChatRoomMessage = (id, messageId, message) => async (dispatch) => {
    try {
        const response = await fetchWrapper.put(`chatrooms/message/${messageId}`, message);
        dispatch({ type: ActionTypes.UPDATE_SEND_MESSAGE, payload: { id: id, messageId, message: response } });
    } catch (e) {
        return e;
    }
};

export const editSubChannelMessage = (id, messageId, message) => async (dispatch) => {
    try {
        const response = await fetchWrapper.put(`subchannels/message/${messageId}`, message);
        dispatch({ type: ActionTypes.UPDATE_SEND_MESSAGE, payload: { id: id, messageId, message: response } });
    } catch (e) {
        return e;
    }
};

export const startAccessingBot =
    (payload = {}) =>
    async (dispatch) => {
        dispatch({ type: ActionTypes.SET_ACCESSING_BOT, payload });
    };
export const stopAccessingBot =
    (payload = {}) =>
    async (dispatch) => {
        dispatch({ type: ActionTypes.CLEAR_ACCESSING_BOT, payload });
    };

export const setRooms = (rooms, totalRooms) => ({
    type: ActionTypes.SET_ROOMS,
    payload: { rooms, totalRooms },
});

const setSubChannels = (subChannels, totalSubChannels) => ({
    type: ActionTypes.SET_SUB_CHANNELS,
    payload: { subChannels, totalSubChannels },
});

export const addRooms = (teamId, rooms, totalRooms) => ({
    type: ActionTypes.ADD_ROOMS,
    payload: { teamId, rooms, totalRooms },
});

const addClientRooms = (rooms) => ({
    type: ActionTypes.ADD_CLIENT_ROOMS,
    payload: rooms,
});

export const getNotSeenRooms = (roomId) => ({
    type: ActionTypes.NOT_SEEN_ROOM,
    payload: roomId,
});

export const getHasSeenRooms = (teamId, roomId) => ({
    type: ActionTypes.HAS_SEEN_ROOM,
    payload: { teamId, roomId },
});
export const setNewRoom = (room) => ({
    type: ActionTypes.SET_NEW_ROOM,
    payload: room,
});
export const setEditedRoom = (room) => ({
    type: ActionTypes.EDIT_CHANNEL,
    payload: room,
});
export const setIsAddingRoom = (isAddingRoom = false) => ({
    type: ActionTypes.SET_IS_ADDING_ROOM,
    payload: isAddingRoom,
});

export const setMessages = (messages) => ({
    type: ActionTypes.SET_MESSAGES,
    payload: messages,
});

export const setSocketMessages = (messages) => ({
    type: ActionTypes.SET_SOCKET_MESSAGE,
    payload: messages,
});

export const clearMessages = () => ({
    type: ActionTypes.CLEAR_MESSAGES,
});

export const setConferenceEnd = (conference) => ({
    type: ActionTypes.SET_CONFERENCE_END,
    payload: conference,
});

export const setConferenceJoin = (conference) => ({
    type: ActionTypes.SET_CONFERENCE_JOIN,
    payload: conference,
});
export const setConferenceLeave = (conference) => ({
    type: ActionTypes.SET_CONFERENCE_LEAVE,
    payload: conference,
});

const setSearchMessages = (messages) => ({
    type: ActionTypes.SET_SEARCH_MESSAGES,
    payload: messages,
});

export const clearSearchMessages = () => ({
    type: ActionTypes.CLEAR_SEARCH_MESSAGES,
});
const setSearchFilter = (search) => ({
    type: ActionTypes.SET_SEARCH_FILTER,
    payload: search,
});

const setRecentChats = (chats) => ({
    type: ActionTypes.SET_CHAT_USERS,
    payload: chats,
});

export const setSocketRecentChats = (chats) => ({
    type: ActionTypes.SET_SOCKET_CHAT_USERS,
    payload: chats,
});

export const startMessageLoading = () => ({
    type: ActionTypes.SET_LOADING_MESSAGE,
});

//if error is not set in messages reducer it is to be removed
export const stopMessageLoading = () => ({
    type: ActionTypes.STOP_LOADING_MESSAGE,
});

const startChatLoading = () => ({
    type: ActionTypes.SET_LOADING_CHAT,
});

//if error is not set in recentChat reducer it is to be removed
const stopChatLoading = () => ({
    type: ActionTypes.STOP_LOADING_CHAT,
});
