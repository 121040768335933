import ZealContractorPayroll from './ZealContractorPayroll';
import ZealEmployeePayroll from './ZealEmployeePayroll';

const ZealStaffPayroll = ({ employeePayroll, teamId, workLocations, previewFLSAOvertime }) => {
    const isEmployee = employeePayroll?.data?.type === 'employee';
    const isEmployeeOnboarded = employeePayroll?.metadata?.employeeID && employeePayroll?.metadata?.onboarded;
    const isContractorOnboarded = employeePayroll?.metadata?.contractorID && employeePayroll?.metadata?.onboarded;
    if (employeePayroll) {
        if (isEmployee) {
            if (!isEmployeeOnboarded) {
                return <div>Employee is not onboarded.</div>;
            }
            return (
                <ZealEmployeePayroll
                    employee={employeePayroll}
                    teamId={teamId}
                    workLocations={workLocations}
                    previewFLSAOvertime={previewFLSAOvertime}
                />
            );
        }
        if (!isEmployee) {
            if (employeePayroll && !isContractorOnboarded) {
                return <div>Employee(Contractor) is not onboarded.</div>;
            }
            return <ZealContractorPayroll employee={employeePayroll} teamId={teamId} />;
        }
    }
    return <div></div>;
};

export default ZealStaffPayroll;
