import { css } from 'twin.macro';
import loaderStyles from '../../../../_elements/loader';

export const PdfLoader = () => (
    <div
        css={css`
            height: 70vh;
        `}
    >
        <div css={loaderStyles}>
            <div
                css={css`
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    flex-direction: column;
                `}
            >
                <img src="/images/loading.gif" alt="loader" />

                <div
                    css={css`
                        text-align: center;
                    `}
                >
                    Loading Template PDF ...
                </div>
            </div>
        </div>
    </div>
);

export const RemoveRectButton = ({ onClick = () => {}, index, styles = {} }) => {
    return (
        <button
            onClick={(e) => {
                e.stopPropagation();
                onClick(index);
            }}
            onMouseDown={(e) => e.stopPropagation()}
            onMouseUp={(e) => e.stopPropagation()}
            style={{
                ...styles,
                background: 'red',
                color: 'white',
                border: 'none',
                borderRadius: '50%',
                width: '20px',
                height: '20px',
                cursor: 'pointer',
                display: 'flex',
                marginLeft: '4px',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '18px',
                fontWeight: 'bold',
                zIndex: 999,
            }}
        >
            ×
        </button>
    );
};

export const convertUrlToFilename = (url) => {
    try {
        const filenameWithExtension = url.split('/').pop();
        const decodedFilename = decodeURIComponent(filenameWithExtension);
        const parts = decodedFilename.split(/_\d+_/);
        return parts[1] || parts[0];
    } catch (err) {
        return url;
    }
};
