import React from 'react';
import tw, { styled } from 'twin.macro';

const MarqueeWrapper = styled.div`
    ${tw`overflow-hidden relative w-full`}
`;

const MarqueeText = styled.span`
    ${tw`inline-block whitespace-nowrap`}
    animation: ${({ speed, direction }) => `marquee ${speed}s linear infinite`};
    color: ${({ color }) => color || '#fff'};
    font-size: ${({ fontSize }) => fontSize || ''};

    @keyframes marquee {
        0% {
            transform: ${({ direction }) => (direction === 'right' ? 'translateX(-100%)' : 'translateX(100%)')};
        }
        100% {
            transform: ${({ direction }) => (direction === 'right' ? 'translateX(100%)' : 'translateX(-100%)')};
        }
    }
`;

const Marquee = ({ text = '', baseSpeed = 30, color = '', fontSize = '', direction = 'left' }) => {
    const additionalSpace = (text || '').length > 75 ? 1 : 100;
    const _text = `${text}${'&nbsp;'.repeat(additionalSpace)} `;
    return (
        <MarqueeWrapper>
            <MarqueeText speed={baseSpeed} color={color} fontSize={fontSize} direction={direction}>
                <span dangerouslySetInnerHTML={{ __html: _text }} />
            </MarqueeText>
        </MarqueeWrapper>
    );
};

export default Marquee;
