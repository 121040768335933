import { connect } from 'react-redux';
import React, { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { DocumentsStyles } from './styles/DocumentsStyled';
import TeamChannels from './TeamChannels';
import SMSContainerAcess from '../../../Admin/SMSContainer/Access';

function TeamSettingsChannel({ teamRooms, currUser, channelDetails }) {
    const [key, setKey] = useState('channels');

    return (
        <div css={DocumentsStyles}>
            <Tabs id="controlled-tab-example" activeKey={key} onSelect={(k) => setKey(k)} className="mb-3">
                <Tab eventKey="channels" title="Channels">
                    {key === 'channels' && <TeamChannels />}
                </Tab>
                <Tab eventKey="sms" title="SMS Access">
                    {key === 'sms' && <SMSContainerAcess />}
                </Tab>
            </Tabs>
        </div>
    );
}

const mapStateToProps = (state) => ({
    teamRooms: state.rooms.teamRooms,
    currUser: state.user.user,
});

export default connect(mapStateToProps, {})(TeamSettingsChannel);
