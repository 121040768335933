import React from 'react';
import { css } from 'twin.macro';
import { getRandomColor } from '../../../../_helpers';

const PdfCanvas = ({ canvasRef, startDrawing, updateDrawing, finishDrawing, currentPage, rects, removeField }) => {
    return (
        <div
            ref={canvasRef}
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                cursor: 'crosshair',
                zIndex: 10,
            }}
            onMouseDown={startDrawing}
            onMouseMove={updateDrawing}
            onMouseUp={finishDrawing}
            onMouseLeave={finishDrawing}
        >
            {(rects || []).map((rect, idx) => {
                if (rect?.page === currentPage) {
                    return (
                        <div
                            key={idx}
                            css={css`
                                position: absolute;
                                border: 1px dashed blue;
                                background-color: rgba(0, 0, 255, 0.1);
                                left: ${rect.x}px;
                                top: ${rect.y}px;
                                width: ${Math.abs(rect.width)}px;
                                height: ${Math.abs(rect.height)}px;
                                transform: scale(${rect.width < 0 ? -1 : 1}, ${rect.height < 0 ? -1 : 1});
                                z-index: 20;
                            `}
                        >
                            <div
                                css={css`
                                    position: absolute;
                                    top: -30px;
                                    left: 0;
                                    background-color: rgba(255, 255, 255, 1);
                                    // backdrop-filter: blur(10px);
                                    padding: 2px 5px;
                                    font-size: 14px;
                                    border-radius: 3px;
                                    box-shadow: 0 0px 16px rgba(0, 0, 0, 0.5);
                                    min-width: 90px !important;
                                `}
                            >
                                <span
                                    css={css`
                                        color: ${getRandomColor(String(idx))};
                                        margin-right: 4px;
                                        font-weight: bold;
                                    `}
                                >
                                    {idx + 1}.<span className="ml-2">{rect.fieldName}</span>
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            removeField(idx);
                                        }}
                                        onMouseDown={(e) => e.stopPropagation()}
                                        onMouseUp={(e) => e.stopPropagation()}
                                        css={css`
                                            position: absolute;
                                            top: -15px;
                                            right: -15px;
                                            background: red;
                                            color: white;
                                            border: none;
                                            border-radius: 50%;
                                            width: 20px;
                                            height: 20px;
                                            cursor: pointer;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            font-size: 18px;
                                            font-weight: bold;
                                            z-index: 999;
                                        `}
                                    >
                                        ×
                                    </button>
                                </span>
                            </div>
                        </div>
                    );
                } else {
                    return <div key={idx}></div>;
                }
            })}
        </div>
    );
};

export default PdfCanvas;
