//import { css } from 'twin.macro';
import React from 'react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

// import PropTypes from 'prop-types';

import FormPicker from './FormPicker';
import DynamicPreview from './DynamicPreview';

import { getAllRooms } from '../../../_redux/actions/ActionMessage';

import { useDispatch } from 'react-redux';
import { fetchWrapper } from '../../../_helpers';
import { EditFormStyles } from './styles/EditFormStyles';
import NavigationBar from '../reusableComponents/NavigationBar';
import Loader from '../containers/Loader';

import { getTeamIdFromURL, getChannelIdFromSettingsURL } from '../../../_helpers';

//icons

export const EditForm = ({ match }) => {
    const dispatch = useDispatch();

    const [formData, setFormData] = useState(null);
    const [loading, setLoading] = useState(false);

    const teamId = getTeamIdFromURL();
    const channelId = getChannelIdFromSettingsURL();

    useEffect(() => {
        setLoading(true);
        fetchWrapper.get(`/forms/${match.params.id}`).then((res) => {
            setFormData(res);

            for (let idx in res.data) {
                let newFormData = Object.assign({}, res.data[idx]);
                newFormData.id = res.data[idx]._id;
                dispatch({ type: 'ADD_FORM_DATA', payload: newFormData });
            }
            setLoading(false);
        });
    }, [dispatch, setFormData, match.params.id]);

    useEffect(() => {
        dispatch({ type: 'REMOVE_fORM' });
    }, [dispatch]);

    return (
        <EditFormStyles>
            <div className="d-flex justify-content-between mb-4">
                <NavigationBar mainTabName="Master Form Library" tab="Edit" subtab={formData?.name} />
            </div>
            {loading ? (
                <Loader size="sm" />
            ) : (
                <>
                    <div className="d-flex flex-row position-relative">
                        {formData && (
                            <div className="w-100 d-flex justify-content-center">
                                <DynamicPreview
                                    formName={formData?.name}
                                    editformId={formData?.id || formData?._id}
                                    formformType={formData?.type}
                                    specialityForm={formData?.specialityForm}
                                    formLocationRequired={!formData?.isLocationOptional}
                                    teamId={teamId}
                                    channelId={channelId}
                                    selectedTeams={formData?.teamData?.map((team) => ({
                                        label: team.name,
                                        value: team.id,
                                    }))}
                                    selectedRooms={formData?.chatroomData?.map((room) => ({
                                        label: room.title,
                                        value: room.id,
                                    }))}
                                    formVisibleTo={formData?.visibleTo}
                                    pdfTemplateData={formData?.pdfTemplate || {}}
                                />
                            </div>
                        )}

                        <FormPicker openFormBuilder />
                    </div>
                </>
            )}
        </EditFormStyles>
    );
};

EditForm.propTypes = {};

const mapStateToProps = (state) => ({
    rooms: state.rooms.teamRooms,
});

const mapDispatchToProps = { getAllRooms };

export default connect(mapStateToProps, mapDispatchToProps)(EditForm);
