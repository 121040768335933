import { css } from 'twin.macro';
import { useState, useEffect, Fragment, useMemo } from 'react';
import { fetchWrapper, getTeamIdFromURL, getChannelIdFromSettingsURL, toastWrapper } from '../../../../_helpers';
import { VscLoading } from 'react-icons/vsc';
import { connect, useSelector, useDispatch } from 'react-redux';
import {
    AssignManagerToTeam,
    deleteUserFromChannel,
    getChannelMembers,
} from '../../../../_redux/actions/ActionOrganization';
import { toast } from 'react-toastify';
import { StyledContainer, TableCSS } from './styles/MembersStyled';
import { Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
import InviteToTeam from '../InviteToTeam';
import AddPeople from '../AddPeople';
import Avatar from '../../../Admin/reusableComponents/Avatar';
import { getRole } from '../../../../_helpers/roles/get-roles';
import { FaUserPlus } from 'react-icons/fa';

const SelectComponent = ({
    teamRole,
    member,
    team,
    AssignManagerToTeam,
    filteredMembers,
    setFilteredMembers,
    isDisabled,
}) => {
    const [memberTeamRole, setMemberTeamRole] = useState(teamRole);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setMemberTeamRole(teamRole);
    }, [teamRole]);

    const handleRoleChange = async (member, team, role, AssignManagerToTeam) => {
        let tempMembers = [...filteredMembers];
        let index = tempMembers.findIndex((m) => m._id === member._id);
        if (index === -1) {
            return;
        }
        setLoading(true);
        if (role === 'teamManager') {
            const tempData = {
                name: member.name,
                email: member.email,
                team: [{ role: 'teamManager', _id: '', team: team.id }],
            };
            try {
                const { error } = await AssignManagerToTeam(team.organization, team.id, tempData);
                if (error) {
                    throw error;
                }
                tempMembers[index].team[0].role = 'teamManager';
                setFilteredMembers(tempMembers);
                setMemberTeamRole('teamManager');
            } catch (err) {
                console.log(err);
            } finally {
                setLoading(false);
            }
        } else {
            try {
                await fetchWrapper.put(`organizations/${team.organization}/teams/${team.id || team._id}/change-role`, {
                    userId: member._id || member.id,
                    role: 'user',
                });
                tempMembers[index].team[0].role = 'user';
                setFilteredMembers(tempMembers);
                toast.success('Team Manager turned to User');
                setMemberTeamRole('user');
            } catch (err) {
                toastWrapper.error(err?.message || "Couldn't change role to user");
                console.log(err);
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <select
            className="role-select"
            value={memberTeamRole}
            disabled={isDisabled || loading}
            onChange={(e) => handleRoleChange(member, team, e.target.value, AssignManagerToTeam)}
        >
            <option value="teamManager">Team Manager</option>
            <option value="user">User</option>
        </select>
    );
};

const SelectChannelRoleComponent = ({ isDisabled, member, showSupervisor }) => {
    const roomIdFromUrl = getChannelIdFromSettingsURL();
    const [channelRole, setChannelRole] = useState(member?.channelRole);
    const [loading, setLoading] = useState(false);

    const handleChangeRole = async ({ changeTo }) => {
        try {
            const data = {
                user: member?._id || member?.id,
                role: changeTo,
            };
            setLoading(true);
            await fetchWrapper.put(`chatrooms/${roomIdFromUrl}/accessControl`, data);
            toast.success('Role has been updated Successfully');
            setChannelRole(changeTo);
        } catch (error) {
            toast.error(error.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Fragment>
            {loading ? (
                <span className="mt-3">
                    <VscLoading className="spin mr-3" />
                    Wait ...
                </span>
            ) : (
                <select
                    className="role-select"
                    value={channelRole}
                    disabled={isDisabled || loading}
                    onChange={(e) => handleChangeRole({ changeTo: e.target.value })}
                >
                    <option value="user">User</option>
                    <option value="family">Family</option>
                    <option value="doctor">Third Party</option>
                    {showSupervisor && <option value="supervisor">Supervisor</option>}
                </select>
            )}
        </Fragment>
    );
};

const CustomTableRow = ({
    member,
    team,
    SN,
    userRole,
    filteredMembers,
    setFilteredMembers,
    AssignManagerToTeam,
    canChangeRole,
    userTeamOrRoomRole,
    isOwner,
    isCurrentUser,
}) => {
    const organization = useSelector((state) => {
        const organizations = state?.organizations?.organizations;
        return Array.isArray(organizations) && organizations.length > 0 ? organizations[0] : null;
    });

    const [isDeleting, setIsDeleting] = useState(false);
    const dispatch = useDispatch();
    const roomIdFromUrl = getChannelIdFromSettingsURL();

    const handleCancelForm = (memberId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: `The member will be removed from the ${roomIdFromUrl ? 'channel' : 'team'}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#50aeb0',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel',
        }).then((result) => {
            if (result.isConfirmed) {
                if (roomIdFromUrl) {
                    handleRemoveChannelMember(memberId);
                } else {
                    handleRemove(memberId);
                }
            }
        });
    };

    const handleRemove = async (memberId) => {
        try {
            setIsDeleting(true);
            await fetchWrapper.delete(`/organizations/${organization.id}/teams/${team.id}/${memberId}/remove`);
            let tempMembers = [...filteredMembers];
            let index = tempMembers.findIndex((member) => member._id === memberId);
            if (index !== -1) {
                tempMembers.splice(index, 1);
                setFilteredMembers(tempMembers);
            }
            setIsDeleting(false);
            toast.success('Member successfully removed!');
        } catch (error) {
            setIsDeleting(false);
            toast.error(error?.error || error?.message);
        }
    };

    const handleRemoveChannelMember = async (memberId) => {
        const resMemberId = dispatch(deleteUserFromChannel(roomIdFromUrl, memberId));
        if (resMemberId) {
            setFilteredMembers((prevFilteredMembers) =>
                (prevFilteredMembers || [])?.filter((member) => member._id !== memberId)
            );
        }
    };

    const showChannelDeleteButton =
        member?.teamRole !== 'owner' &&
        member?.teamRole !== 'teamManager' &&
        (userTeamOrRoomRole !== 'supervisor' || member.channelRole !== 'supervisor');
    const showTeamDeleteButton =
        member.teamRole !== 'owner' &&
        (isOwner || userTeamOrRoomRole !== 'teamManager' || member.teamRole !== 'teamManager');

    return (
        <tr key={member._id}>
            <td>{SN}</td>
            <td>
                <div
                    css={css`
                        display: flex;
                        align-items: center;
                        gap: 0.5rem;
                    `}
                >
                    <Avatar item={member} large={false} />
                    <div
                        css={css`
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                        `}
                    >
                        <div>
                            {member.name} {isCurrentUser ? '(You)' : null}
                        </div>
                        <div
                            css={css`
                                color: #757575;
                                font-size: 1.4rem;
                            `}
                        >
                            {member?.email}
                        </div>
                    </div>
                </div>
            </td>
            <td className="d-flex align-items-center">
                {roomIdFromUrl ? (
                    <Fragment>
                        {member?.teamRole === 'owner' || member?.teamRole === 'teamManager' ? (
                            <div
                                className={`${member?.teamRole} mt-2`}
                                css={css`
                                    padding-left: 1rem;
                                    text-transform: capitalize;
                                `}
                            >
                                {' '}
                                {member?.teamRole}
                            </div>
                        ) : (
                            <SelectChannelRoleComponent
                                member={member}
                                isDisabled={
                                    !canChangeRole ||
                                    (roomIdFromUrl &&
                                        userTeamOrRoomRole === 'supervisor' &&
                                        member.channelRole === 'supervisor')
                                }
                                showSupervisor={
                                    member.channelRole === 'supervisor' || userTeamOrRoomRole !== 'supervisor'
                                }
                            />
                        )}
                    </Fragment>
                ) : (
                    <Fragment>
                        {member.teamRole === 'owner' ? (
                            <div
                                className={`${member.teamRole === 'owner' ? 'owner' : ''} mt-3`}
                                css={css`
                                    padding-left: 1rem;
                                `}
                            >
                                Owner
                            </div>
                        ) : (
                            <div>
                                <SelectComponent
                                    teamRole={member.teamRole}
                                    member={member}
                                    team={team}
                                    AssignManagerToTeam={AssignManagerToTeam}
                                    userRole={userRole}
                                    filteredMembers={filteredMembers}
                                    setFilteredMembers={setFilteredMembers}
                                    isDisabled={
                                        !canChangeRole || (!roomIdFromUrl && userTeamOrRoomRole === 'teamManager')
                                    }
                                />
                            </div>
                        )}
                    </Fragment>
                )}
            </td>
            <td>
                {canChangeRole && (roomIdFromUrl ? showChannelDeleteButton : showTeamDeleteButton) ? (
                    <Button
                        variant="danger"
                        disabled={isDeleting}
                        onClick={() => handleCancelForm(member?._id || member?.id)}
                    >
                        {isDeleting ? <VscLoading className="spin " /> : 'Remove'}
                    </Button>
                ) : (
                    <Button variant="danger" disabled={true}>
                        Remove
                    </Button>
                )}
            </td>
        </tr>
    );
};
const CustomTable = ({
    members,
    filteredMembers,
    setFilteredMembers,
    team,
    AssignManagerToTeam,
    userRole,
    currUser,
    isChannel,
    getTeamMembers,
}) => {
    const user = useSelector((state) => state?.user?.user);
    const [userTeamOrRoomRole, setUserTeamOrRoomRole] = useState({});
    const roomIdFromUrl = getChannelIdFromSettingsURL();

    useEffect(() => {
        if (user?.teamList && user?.roomList) {
            const teamRole =
                user.teamList.find(
                    (userTeam) =>
                        (userTeam?.team?._id || userTeam?.team?.id || userTeam?.team) === (team?._id || team?.id)
                )?.role || 'user';
            const roomRole =
                user.roomList.find((room) => (room?.room?._id || room?.room?.id || room?.room) === roomIdFromUrl)
                    ?.role || 'user';
            setUserTeamOrRoomRole(
                userRole === 'admin' ? userRole : ['owner', 'teamManager'].includes(teamRole) ? teamRole : roomRole
            );
        }
    }, [team, user]);

    const canChangeRole = useMemo(
        () => ['admin', 'owner', 'teamManager', 'supervisor'].includes(userTeamOrRoomRole),
        [userTeamOrRoomRole]
    );

    if (!members) {
        return (
            <h4 className="text-center">
                <VscLoading className="spin" fontSize="42" />
            </h4>
        );
    }

    return (
        <div className="custom-card">
            <TableCSS>
                <thead>
                    <tr>
                        <th
                            css={css`
                                width: 2rem;
                            `}
                        >
                            S.N.
                        </th>
                        <th>Name</th>
                        <th>Role</th>
                        {canChangeRole && <th>Action</th>}
                    </tr>
                </thead>
                <tbody>
                    {filteredMembers && filteredMembers.length > 0 ? (
                        filteredMembers
                            .sort((a, b) => (a?.name > b?.name ? 1 : -1))
                            .map((member, i) => {
                                return (
                                    <CustomTableRow
                                        key={member._id}
                                        member={member}
                                        SN={i + 1}
                                        team={team}
                                        userRole={userRole}
                                        filteredMembers={filteredMembers}
                                        setFilteredMembers={setFilteredMembers}
                                        AssignManagerToTeam={AssignManagerToTeam}
                                        canChangeRole={canChangeRole}
                                        userTeamOrRoomRole={userTeamOrRoomRole}
                                        isOwner={team.admin === user?._id}
                                        isChannel={isChannel}
                                        isCurrentUser={currUser?.id === member?._id}
                                        getTeamMembers={getTeamMembers}
                                    />
                                );
                            })
                    ) : (
                        <tr>
                            <td colSpan={4}>Ops! Member not found</td>
                        </tr>
                    )}
                </tbody>
            </TableCSS>
        </div>
    );
};

export const AddMemberStyle = css`
    display: flex;
    align-items: center;
    justify-content: center;

    a {
        background-color: #50aeb0 !important;
        border: 1px solid #50aeb0;
        color: #ffffff !important;
    }
    a:hover {
        transform: scale(1.06);
    }
`;

const Members = ({
    teams,
    AssignManagerToTeam,
    userRole,
    currUser,
    channelDetails,
    getChannelMembers,
    channelMembers,
}) => {
    const [members, setMembers] = useState(null);
    const [filteredMembers, setFilteredMembers] = useState(null);
    const [memberSearch, setMemberSearch] = useState('');
    const teamId = getTeamIdFromURL();
    let teamArr = teams.filter((team) => team.id === teamId);
    let team = teamArr[0];
    const roomIdFromUrl = getChannelIdFromSettingsURL();

    const getTeamMembers = async () => {
        try {
            if (team?.organization && team?.id) {
                let response;
                if (roomIdFromUrl) {
                    response = await fetchWrapper.get(`chatrooms/${roomIdFromUrl}/members?limit=1000`);
                } else {
                    response = await fetchWrapper.get(
                        `organizations/${team?.organization}/teams/${team?.id}/members?limit=1000`
                    );
                }
                let membersData = (response?.results || [])?.map((thisMember) => {
                    let memberTeam = thisMember?.team?.find((team) => team?.team === teamId);
                    const teamRole = thisMember?.teams?.length && thisMember.teams[0]?.role;
                    const channelRole = thisMember?.rooms && thisMember.rooms[0]?.role;
                    return {
                        ...thisMember,
                        teamRole: memberTeam?.role || teamRole,
                        channelRole,
                    };
                });
                setMembers(membersData || []);
                setFilteredMembers(membersData || []);
            }
        } catch (error) {
            console.log('ERROR', error);
        }
    };
    useEffect(() => {
        if (team && !roomIdFromUrl) {
            getTeamMembers();
        } else {
            getChannelMembers(roomIdFromUrl);
        }
    }, [team, teamId]);

    useEffect(() => {
        if (roomIdFromUrl) {
            let membersData = (channelMembers || [])?.map((thisMember) => {
                let memberTeam = thisMember?.team?.find((team) => team?.team === teamId);
                const teamRole = thisMember?.teams?.length && thisMember.teams[0]?.role;
                const channelRole = thisMember?.rooms && thisMember.rooms[0]?.role;
                return {
                    ...thisMember,
                    teamRole: memberTeam?.role || teamRole,
                    channelRole,
                };
            });
            setMembers(membersData || []);
            setFilteredMembers(membersData || []);
        }
    }, [channelMembers]);

    const handleSearchChange = (e) => {
        setMemberSearch(e.target.value);
        let filteredMember = members?.filter(
            (member) =>
                member.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
                member?.email?.toLowerCase().includes(e.target.value.toLowerCase())
        );
        setFilteredMembers(filteredMember);
    };

    return (
        <StyledContainer>
            <h1 className="mb-4">Members</h1>
            {team ? (
                <Fragment>
                    <div className="d-flex justify-content-between ">
                        <input
                            className="input"
                            onChange={handleSearchChange}
                            value={memberSearch}
                            placeholder="Search for Member"
                        />
                        {roomIdFromUrl ? (
                            <AddPeople teamId={teamId} roomId={channelDetails?.id} role={getRole(currUser, team)}>
                                <Button style={{ borderRadius: '4px' }}>
                                    <FaUserPlus />
                                </Button>
                            </AddPeople>
                        ) : (
                            <span css={AddMemberStyle}>
                                {' '}
                                <InviteToTeam
                                    key="inviteToTeam-123"
                                    teamId={team.id}
                                    orgId={team.organization}
                                    type="admin"
                                />{' '}
                            </span>
                        )}
                    </div>

                    <div className="mt-4">
                        <CustomTable
                            filteredMembers={filteredMembers}
                            setFilteredMembers={setFilteredMembers}
                            team={team}
                            AssignManagerToTeam={AssignManagerToTeam}
                            userRole={userRole}
                            currUser={currUser}
                            members={members}
                        />
                    </div>
                </Fragment>
            ) : (
                <VscLoading className="spin" />
            )}
        </StyledContainer>
    );
};

const mapStateToProps = (state) => ({
    teams: state.teams.teams,
    userRole: state.user?.user?.role,
    currUser: state.user?.user,
    channelMembers: state.rooms.channelMembers,
});
export default connect(mapStateToProps, { AssignManagerToTeam, getChannelMembers })(Members);
