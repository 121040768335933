import { css } from 'twin.macro';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import { connect } from 'react-redux';
import { BiLinkExternal } from 'react-icons/bi';

// icons
import { FaInfoCircle, FaUserPlus } from 'react-icons/fa';
import { BiArrowBack } from 'react-icons/bi';
import { AiFillSetting, AiOutlineCaretDown } from 'react-icons/ai';
import { IoMdCall } from 'react-icons/io';
import { GrUserSettings } from 'react-icons/gr';

import { Button, Dropdown, Alert, ButtonGroup } from 'react-bootstrap';
import { Fragment, forwardRef, useEffect, useState } from 'react';
import { getRole } from '../../../_helpers/roles/get-roles';
import { checkPermission, fetchWrapper, scrollMeToMyRoom } from '../../../_helpers';
import InviteToTeam from './InviteToTeam';
import InviteManager from './InviteManager';
import { Link } from 'react-router-dom';
import { deleteATeamById, getChannelMembers, getTeamMember, getUserData } from '../../../_redux';
import { channelBar, titleBar } from './TeamHeadingStyles';
import { navIcon } from '../../Admin/reusableComponents/NavigationBar';
import { searchChat, clearSearchMessages } from '../../../_redux/actions/ActionMessage';
import { Tagstyled } from '../Common/styles/UserPanelCommon';

import EditTeam from '../../Admin/Teams/ReuseableEditTeam';
import { BsFillPlusSquareFill, BsPencil } from 'react-icons/bs';
import { ToolTip, BootstrapToolTip } from '../../../_elements/ToolTip';
import SearchConversation from './SearchConversation';
import { UPrimaryButton, UPrimaryButtonDisabled } from '../../../_elements/Buttons';
import AddPeople from './AddPeople';
import { CgScrollV } from 'react-icons/cg';
import CreateChannelModal from './CreateChannelModal';
import ServiceCounter from './ServiceCounter';
import { setConferenceData, startConference } from '../../../_redux/actions/ActionConference';
import VisitModalEmployee from './VisitModalEmployee';
import dayjs from 'dayjs';
import ViewEventChannels from '../../Admin/Tasks/eventchannel/ViewEventChannels';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { getRoomIdFromURL } from '../../../_helpers';
import { TbReportMoney } from 'react-icons/tb';
import PayrollsModalEmployee from './PayrollsModalEmployee';
import Marquee from '../../../_elements/Marquee';

const InviteMembersToggle = forwardRef(({ children, onClick }, ref) => (
    <UPrimaryButton
        className="mr-4"
        css={css`
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            :hover {
                transform: scale(1);
                background-color: #dae2e1;
            }
        `}
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
    </UPrimaryButton>
));

const InviteMembers = forwardRef(({ children, onClick }, ref) => (
    <UPrimaryButton
        className="mr-4"
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
    </UPrimaryButton>
));

const InviteMemberToChannelBtn = forwardRef(({ children, onClick }, ref) => (
    <UPrimaryButton
        css={css`
            border-right: none;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            :hover {
                transform: scale(1);
                background-color: #dae2e1;
            }
        `}
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
        }}
    >
        {children}
    </UPrimaryButton>
));

const CustomMenu = forwardRef(({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    return (
        <div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
            <ul className="list-unstyled">{children}</ul>
        </div>
    );
});

const alertPop = css`
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    width: 100%;
    .alert-enter {
        opacity: 0;
        transform: translateY(-100%);
    }
    .alert-enter-active {
        opacity: 1;
        transform: translateY(0);
        transition:
            opacity 800ms,
            transform 800ms;
    }
    .alert-exit {
        opacity: 1;
        transform: translateY(0);
    }
    .alert-exit-active {
        opacity: 0;
        transform: translateY(-100%);
        transition:
            opacity 800ms,
            transform 800ms;
    }
`;

export const CallButtonWhenRoom = ({ conferenceData, room, handleUserCall }) => {
    const roomIdData = room._id || room.id;
    if (
        !conferenceData ||
        !conferenceData.conferenceId ||
        conferenceData.conferenceId === 'false' ||
        conferenceData.roomId !== roomIdData
    ) {
        return null;
    }

    return (
        <span
            className="callBtn"
            onClick={handleUserCall}
            css={css`
                margin-left: 1rem;
                cursor: pointer;
                transition: transform 150ms;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid white;
                padding: 0 1rem;
                border-radius: 5px;
                :hover {
                    transform: scale(1.1);
                    background-color: white;
                    color: #2e9a9c;
                }
            `}
        >
            <ToolTip text="Call" side="left">
                <IoMdCall fontSize={20} /> <span className="ml-2">Join</span>
            </ToolTip>
        </span>
    );
};

function TeamHeading({
    conferenceData,
    room,
    teams,
    setShowChat,
    setOpenDetails,
    openDetails,
    user,
    userRole,
    updateSearchText,
    searchText,
    getUserData,
    searchChat,
    organizations,
    channelMembers,
    getChannelMembers,
    getTeamMember,
    roomsRef,
    counterArray,
    setConferenceData,
    startConference,
    taskArray,
    subChannelArray,
}) {
    // console.log(user, userRole, teams, 'user');
    const roomId = room.id || room._id;
    const userId = user.id || user._id;
    const team = teams.find((team) => team.id === room.team) || {};
    const [visibleDropDowns, setVisibleDropDowns] = useState([]);
    const [visibleCreateChannel, setVisibleCreateChannel] = useState(false);
    const [showAvatarWarning, setShowAvatarWarning] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showChannelModal, setShowChannelModal] = useState(false);
    const [showModal, setShowModal] = useState({});
    const history = useHistory();
    const roomIdFromUrl = getRoomIdFromURL();

    const handleShow = () => {
        setShowChannelModal(true);
    };

    useEffect(() => {
        let teamId = team?._id;
        let userTeam = user.teamList.filter((team) => team?.team?.id === teamId);
        if (userTeam.length && userTeam[0].role === 'teamManager' && !team?.avatar) {
            setShowAvatarWarning(true);
        } else {
            setShowAvatarWarning(false);
        }
    }, [user.teamList, team?._id, team?.avatar]);

    const handleShowEditModal = () => {
        setShowEditModal(true);
    };
    const handleHideEditModal = () => {
        setShowEditModal(false);
    };

    let role = getRole(user, team);
    const [channelRole, setChannelRole] = useState(null);
    useEffect(() => {
        if (channelMembers) {
            const channelMember = channelMembers.filter((member) => member._id === user.id);
            if (channelMember.length > 0) {
                setChannelRole(channelMember[0]?.rooms[0]?.role);
            } else {
                setChannelRole(null);
            }
        }
    }, [channelMembers, user]);
    useEffect(() => {
        if (room && room.id) {
            if (room.type === 'private' || room.type === 'gps_enabled') {
                getChannelMembers(room.id || room._id);
            }
            if (room && room.team && Array.isArray(organizations) && organizations[0]?.id) {
                getTeamMember(room.team, organizations[0].id);
            }
        }
    }, [getChannelMembers, organizations, getTeamMember, room, getUserData]);

    useEffect(() => {
        if (checkPermission(role, 'create:channal', { userId: user.id, teamAdmin: team.admin })) {
            setVisibleCreateChannel(true);
        }
    }, [user, role, team]);

    useEffect(() => {
        if (team.id) {
            const Components = [
                {
                    component: <InviteToTeam key="inviteToTeam-123" teamId={team.id} orgId={team.organization} />,
                    action: 'invite:user',
                    data: {
                        userId: user.id,
                        teamAdmin: team.admin,
                    },
                },
                {
                    component: <InviteManager key="inviteManager-123" teamId={team.id} orgId={team.organization} />,
                    action: 'invite:manager',
                    data: {
                        userId: user.id,
                        teamAdmin: team.admin,
                        user: user,
                    },
                },
            ];
            let presentComponents = [];
            for (let component of Components) {
                if (checkPermission(role, component.action, component.data)) {
                    presentComponents.push(component.component);
                }
            }
            setVisibleDropDowns(presentComponents);
        }
    }, [user, role, team]);

    const showTeam = () => {
        setShowChat(false);
    };

    const handleCall = async () => {
        try {
            if (conferenceData && conferenceData.conferenceId && conferenceData.conferenceId !== 'false') {
                if (conferenceData.teamId === (team.id || team._id)) {
                    return startConference();
                } else {
                    return Swal.fire('Already on Conference', `Please end any ongoing conference to start a new one.`);
                }
            }
            const response = await fetchWrapper.post('/conference/token', { room: room.id });
            setConferenceData(roomId, response.id, team?._id);
            startConference();
        } catch (e) {}
    };
    const handleUserCall = async () => {
        try {
            startConference();
        } catch (e) {}
    };

    const handleEventChannel = (id) => {
        if (userId) {
            history.push(`/event-channel/${id}`);
        }
    };

    return (
        <div>
            <div css={titleBar}>
                <div className="d-flex flex-row ">
                    <BiArrowBack css={navIcon} size={24} className="pointer d-md-none" onClick={() => showTeam()} />
                    <div
                        className="title mb-0 mr-4 d-flex align-items-center wrap"
                        css={css`
                            font-size: 1.8rem;
                            :hover .scrollToView {
                                opacity: 1;
                            }
                        `}
                    >
                        {team && team.name}
                        <div
                            className="scrollToView"
                            css={css`
                                opacity: 0;
                                border: 1px solid #1b686a;
                                border-radius: 100px;
                                margin: 0 1rem;
                                cursor: pointer;
                                background: #e0f0ef;
                                :hover {
                                    background-color: #dae2e1;
                                }
                                padding: 0.4rem;
                                transition: opacity 200ms;
                            `}
                            onClick={() => {
                                scrollMeToMyRoom(room?.id, roomsRef);
                            }}
                        >
                            <CgScrollV fontSize="24" color="#1b686a" />
                        </div>
                    </div>
                    {team?.provider?._id && (
                        <Tagstyled light small className="tags mr-4">
                            <span>Provider</span>
                        </Tagstyled>
                    )}

                    {visibleDropDowns.length !== 0 ? (
                        <Fragment>
                            {room.type === 'private' || room.type === 'gps_enabled' ? (
                                <Dropdown as={ButtonGroup} className="dropper">
                                    <AddPeople role={getRole(user, team)} teamId={room.team} roomId={room.id}>
                                        <Button variant="primary" as={InviteMemberToChannelBtn} id="invite-to-channel">
                                            <FaUserPlus className="icon mr-2" /> <span>Invite</span>
                                        </Button>
                                    </AddPeople>

                                    <Dropdown.Toggle
                                        split
                                        variant="primary"
                                        id="invite-to-team"
                                        className="dropdown-menu"
                                        as={InviteMembersToggle}
                                    >
                                        <AiOutlineCaretDown />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu as={CustomMenu}>
                                        {visibleDropDowns.map((visibleDropDown) => visibleDropDown)}
                                    </Dropdown.Menu>
                                </Dropdown>
                            ) : (
                                <Dropdown className="dropper">
                                    <Dropdown.Toggle as={InviteMembers} id="invite-to-team" className="dropdown-menu">
                                        <FaUserPlus className="icon mr-2" /> <span>Invite to Team</span>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu as={CustomMenu}>
                                        {visibleDropDowns.map((visibleDropDown) => visibleDropDown)}
                                    </Dropdown.Menu>
                                </Dropdown>
                            )}
                        </Fragment>
                    ) : (
                        (room.type === 'private' || room.type === 'gps_enabled') &&
                        userRole === 'supervisor' && (
                            <AddPeople role={getRole(user, team)} teamId={room.team} roomId={room.id}>
                                <UPrimaryButton className="mr-4">
                                    <FaUserPlus className="icon mr-2" /> <span>Invite To Channel</span>
                                </UPrimaryButton>
                            </AddPeople>
                        )
                    )}
                    {visibleCreateChannel && (
                        <Fragment>
                            <UPrimaryButton className="invite mr-4" onClick={handleShow}>
                                <BsFillPlusSquareFill className="icon mr-2" /> <span>Create Channel</span>
                            </UPrimaryButton>
                            <CreateChannelModal
                                key="CreateChannel-123"
                                show={showChannelModal}
                                setShow={setShowChannelModal}
                                teamId={team.id}
                            />
                        </Fragment>
                    )}
                </div>
                <div className="d-flex flex-row">
                    {!team?._id ? (
                        <UPrimaryButtonDisabled>
                            <AiFillSetting />
                        </UPrimaryButtonDisabled>
                    ) : (
                        <Fragment>
                            {checkPermission(role, 'update:team', { userId: user?.id, teamAdmin: team?.admin }) ? (
                                <Link to={`/teams/${team.id}/settings`} className="mr-2">
                                    <UPrimaryButton className="team-settings-button">
                                        <AiFillSetting />
                                    </UPrimaryButton>
                                </Link>
                            ) : (
                                <Fragment>
                                    {team?.provider?._id && (
                                        <Fragment>
                                            <BootstrapToolTip text="Payrolls" placement="bottom">
                                                <UPrimaryButton
                                                    className="mr-2"
                                                    onClick={() => setShowModal({ type: 'payrolls' })}
                                                >
                                                    <TbReportMoney />
                                                </UPrimaryButton>
                                            </BootstrapToolTip>
                                            <BootstrapToolTip text="Visits" placement="bottom">
                                                <UPrimaryButton onClick={() => setShowModal({ type: 'visit' })}>
                                                    <GrUserSettings />
                                                </UPrimaryButton>
                                            </BootstrapToolTip>
                                        </Fragment>
                                    )}
                                    {showModal?.type === 'payrolls' && (
                                        <PayrollsModalEmployee
                                            show={!!showModal}
                                            setShow={setShowModal}
                                            teamId={team?._id}
                                        />
                                    )}
                                    {showModal?.type === 'visit' && (
                                        <VisitModalEmployee
                                            show={!!showModal}
                                            setShow={setShowModal}
                                            teamId={team?._id}
                                        />
                                    )}
                                </Fragment>
                            )}
                        </Fragment>
                    )}
                </div>
            </div>
            <div css={channelBar}>
                <div className="channelNames">
                    <span
                        className="font-weight-bold mb-0 pointer d-flex align-items-center channel-name"
                        onClick={() => {
                            setOpenDetails(!openDetails);
                        }}
                    >
                        <span
                            className="mr-2"
                            css={css`
                                max-width: 430px;
                                white-space: nowrap;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            `}
                        >
                            #{room.title}
                        </span>
                    </span>
                    {room?.client?.id && (
                        <Tagstyled small className="tags">
                            Consumer
                        </Tagstyled>
                    )}

                    <span className="separator"></span>
                    <span
                        className="text-capitalize mb-0"
                        css={css`
                            font-size: 1.7rem;
                            max-width: 430px;
                            min-width: 160px;
                        `}
                    >
                        {room.type} Community
                    </span>
                    <span className="separator"></span>
                </div>
                <marquee>{room.subTitle}</marquee>
                {/* <Marquee text={room.subTitle} /> */}
                <div className="channel-actions">
                    <SearchConversation
                        searchChat={searchChat}
                        id={room.id}
                        isDirectChat={false}
                        updateSearchText={updateSearchText}
                        searchText={searchText}
                    />
                    {checkPermission(role, 'start:call') || checkPermission(channelRole, 'start:call') ? (
                        <span
                            css={css`
                                margin-left: 1rem;
                                cursor: pointer;
                                transition: transform 150ms;
                                :hover {
                                    transform: scale(1.2);
                                }
                            `}
                        >
                            <ToolTip text="Call" side="left" cssStyles="top: -25px !important;">
                                <IoMdCall fontSize={20} onClick={handleCall} />
                            </ToolTip>
                        </span>
                    ) : (
                        <CallButtonWhenRoom
                            conferenceData={conferenceData}
                            room={room}
                            handleCall={handleCall}
                            handleUserCall={handleUserCall}
                        />
                    )}
                    <span
                        css={css`
                            margin-left: 1rem;
                            cursor: pointer;
                            transition: transform 150ms;
                            :hover {
                                transform: scale(1.2);
                            }
                        `}
                    >
                        <ToolTip text="Details" side="left" cssStyles="top: -25px !important;">
                            <FaInfoCircle fontSize={20} onClick={() => setOpenDetails(!openDetails)} />
                        </ToolTip>
                    </span>
                </div>
            </div>

            {(counterArray.length > 0 || taskArray.length > 0 || subChannelArray.length > 0) && (
                <div style={{ maxHeight: '215px' }} className="d-flex w-100 border-bottom px-2">
                    {counterArray.length > 0 && (
                        <div className={taskArray.length > 0 ? 'w-50' : 'w-100'}>
                            <div
                                className="title mb-0 mr-4 d-flex align-items-center wrap"
                                css={css`
                                    font-size: 1.8rem;
                                    justify-content: center;
                                    display: flex;
                                    width: 100%;
                                    color: #757575;
                                    font-weight: bold;
                                `}
                            >
                                Ongoing Services
                            </div>
                            <div
                                css={css`
                                    display: flex;
                                    ${counterArray.length > 1 ? 'justify-content: start;' : 'justify-content: center;'}
                                    align-items: center;
                                    padding: 1rem 10px;
                                    width: 100%;
                                    position: relative;
                                    overflow-x: auto;
                                `}
                            >
                                {counterArray &&
                                    counterArray.map((counter, counterIndex) => {
                                        let offset = counter?.offset || 0;
                                        const stopwatchOffset = new Date();
                                        stopwatchOffset.setSeconds(stopwatchOffset.getSeconds() + offset);
                                        return (
                                            <ServiceCounter
                                                key={counterIndex}
                                                counter={counter || {}}
                                                offsetTimestamp={stopwatchOffset}
                                                channelId={roomIdFromUrl}
                                                showTemplate={true}
                                            />
                                        );
                                    })}
                            </div>
                        </div>
                    )}
                    {/* <div className='d-flex flex-column'> */}
                    <div className={counterArray.length > 0 ? 'w-50 overflow-auto' : 'w-100 overflow-auto px-2'}>
                        {(taskArray.length > 0 || subChannelArray.length > 0) && (
                            <div>
                                <div className="d-flex flex-column justify-content-center align-items-start">
                                    <div
                                        className="title mb-0 mr-4 d-flex align-items-center"
                                        css={css`
                                            font-size: 1.8rem;
                                            justify-content: center;
                                            display: flex;
                                            width: 100%;
                                            color: #757575;
                                            font-weight: bold;
                                        `}
                                    >
                                        Ongoing tasks
                                    </div>
                                    <div
                                        css={css`
                                            display: flex;
                                            // flex-wrap: wrap;
                                            justify-content: center;
                                            align-items: center;
                                            width: 100%;
                                            gap: 5px;
                                            // margin :0 0 5px 0;
                                            width: fit-content;
                                            overflow: auto;
                                        `}
                                    >
                                        {taskArray?.map((task) => (
                                            <div
                                                css={css`
                                                    display: flex;
                                                    // flex-direction: column;
                                                    align-items: center;
                                                    width: 200px;
                                                    justify-content: space-evenly;
                                                    background-color: #50aeb0;
                                                    color: #ffffff;
                                                    border-radius: 12px;
                                                    padding: 4px;
                                                    font-size: 14px;
                                                    gap: 5px;
                                                `}
                                                key={task.id}
                                            >
                                                <div className="d-flex flex-column align-items-center">
                                                    <span className="font-weight-bolder text-center">{task.title}</span>
                                                    <span>
                                                        {dayjs(task.startedAt).format('h:mm A')} -{' '}
                                                        {dayjs(task.endsAt).format('h:mm A')}
                                                    </span>
                                                </div>
                                                {task.users > 0 && (
                                                    <ViewEventChannels
                                                        handleEventChannel={handleEventChannel}
                                                        eventId={task.id}
                                                        teamId={team._id}
                                                    />
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <hr style={{ margin: '5px 0' }} />
                                <div className="d-flex flex-column justify-content-center align-items-start overflow-auto">
                                    {/* <div
									className="title mb-0 mr-4 d-flex align-items-center"
									css={css`
							font-size: 1.8rem;
							justify-content: center;
							display: flex;
							width: 100%;
							color: #757575;
							font-weight: bold;
						`}
								>
									Ongoing tasks
								</div> */}
                                    <div
                                        css={css`
                                            display: flex;
                                            // flex-wrap: wrap;
                                            justify-content: center;
                                            align-items: center;
                                            width: 100%;
                                            gap: 5px;
                                            // margin :0 0 5px 0;
                                            width: fit-content;
                                            overflow: auto;
                                        `}
                                    >
                                        {subChannelArray?.map((task) => (
                                            <div
                                                css={css`
                                                    display: flex;
                                                    // flex-direction: column;
                                                    align-items: center;
                                                    width: 200px;
                                                    justify-content: space-evenly;
                                                    background-color: #50aeb0;
                                                    color: #ffffff;
                                                    border-radius: 12px;
                                                    padding: 4px;
                                                    font-size: 14px;
                                                    gap: 5px;
                                                `}
                                                key={task.id}
                                            >
                                                <div className="d-flex flex-column align-items-center">
                                                    <span className="font-weight-bolder text-center">{task.title}</span>
                                                    <span>
                                                        {dayjs(task.startedAt).format('h:mm A')} -{' '}
                                                        {dayjs(task.endsAt).format('h:mm A')}
                                                    </span>
                                                </div>
                                                {task.id && (
                                                    <BiLinkExternal
                                                        className="icon"
                                                        size={35}
                                                        onClick={() => handleEventChannel(task.id)}
                                                    />
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
            <div css={alertPop}>
                <CSSTransition in={showAvatarWarning} timeout={800} classNames="alert" unmountOnExit>
                    <Alert
                        css={css`
                            border-radius: 15px;
                            padding: 2rem;
                            width: 60%;
                            margin: 0.5rem auto;
                            box-shadow: 1px 5px 10px 1px #bcbcbc;
                        `}
                        variant="secondary"
                        onClose={() => setShowAvatarWarning(false)}
                        dismissible
                    >
                        <Alert.Heading
                            css={css`
                                padding-top: 0.3rem;
                                font-size: 2.5rem;
                            `}
                        >
                            Hello <span className="text-capitalize">{user?.name.split(' ')[0]}</span>,
                        </Alert.Heading>
                        <p
                            css={css`
                                min-height: 3em;
                                padding-top: 0.5em;
                            `}
                        >
                            Your Team is looking great, but it seems your team is missing a team image.
                            <br />
                            Would you like to upload it now?
                            <br />
                            <span className="lead small">
                                <em>Click on Edit to upload team image.</em>
                            </span>
                        </p>
                        <div className="d-flex justify-content-end">
                            <Button
                                variant="primary"
                                css={css`
                                    padding: 0.5em 1.5em;
                                    font-size: 1.5rem;
                                    display: flex;
                                    justify-content: center;
                                    align-items: center;
                                `}
                                onClick={handleShowEditModal}
                            >
                                <BsPencil />
                                <span
                                    css={css`
                                        margin-left: 0.5rem;
                                    `}
                                >
                                    Edit
                                </span>
                            </Button>
                            <EditTeam
                                team={team}
                                showEditModal={showEditModal}
                                handleHideEditModal={handleHideEditModal}
                            />
                        </div>
                    </Alert>
                </CSSTransition>
            </div>
        </div>
    );
}

TeamHeading.propTypes = {
    room: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    teams: state.teams.teams,
    user: state.user.user,
    organizations: state.organizations.organizations,
    isSearch: state.messages,
    channelMembers: state.rooms.channelMembers,
    conferenceData: state.conference.conferenceData,
    roomsRef: state.rooms.roomsRef,
});

export default connect(mapStateToProps, {
    deleteATeamById,
    getUserData,
    searchChat,
    clearSearchMessages,
    getChannelMembers,
    getTeamMember,
    setConferenceData,
    startConference,
})(TeamHeading);
