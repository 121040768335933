import ZealReimbursement from './ZealReimbursement';
import DefaultWorkLocation from './DefaultWorkLocation';
import { useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { css } from 'twin.macro';
import OurAccordionMultiple from '../../../../../../_elements/OurAccordionMultiple';

const ZealShiftMisc = ({ show, companyID, provider, teamId, handleUpdateProvider = () => {} }) => {
    const [activeKey, setActiveKey] = useState('reimbursement');
    if (!show) {
        return null;
    }
    return (
        <div
            css={css`
                margin-top: 20px;
                border: 1px solid #dddddd;
            `}
        >
            <Accordion
                defaultActiveKey={activeKey}
                onSelect={(e) => {
                    setActiveKey(e);
                }}
            >
                <OurAccordionMultiple
                    title={'Reimbursement'}
                    eventKey={'reimbursement'}
                    isOpen={activeKey === 'reimbursement'}
                    titleTag=""
                >
                    <ZealReimbursement
                        companyID={companyID}
                        teamId={teamId}
                        provider={provider}
                        handleUpdateProvider={handleUpdateProvider}
                    />
                </OurAccordionMultiple>
                <OurAccordionMultiple
                    title={'Default Shift Work Location'}
                    eventKey={'default-work-location'}
                    isOpen={activeKey === 'default-work-location'}
                    titleTag=""
                >
                    <DefaultWorkLocation
                        companyID={companyID}
                        teamId={teamId}
                        provider={provider}
                        handleUpdateProvider={handleUpdateProvider}
                        show={activeKey === 'default-work-location'}
                    />
                </OurAccordionMultiple>
            </Accordion>
        </div>
    );
};

export default ZealShiftMisc;
