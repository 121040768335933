import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { css } from 'twin.macro';
import { fetchWrapper } from '../../../../../../_helpers';
import { ConsentToolTip } from '../../../../../../_elements/ToolTip';
import Select from 'react-select';

const DefaultWorkLocation = ({ show, companyID, provider, teamId, handleUpdateProvider }) => {
    const [isBusy, setIsBusy] = useState(false);
    const [workLocationOptions, setWorkLocationOptions] = useState([]);
    const [workId, setWorkId] = useState(null);

    const handleSubmit = async () => {
        try {
            setIsBusy(true);
            await fetchWrapper.post(
                `evv/payroll/zeal/provider/${companyID}/default-worksite-id${teamId ? `?team=${teamId}` : ''}`,
                {
                    defaultShiftWorkSiteId: workId?.value || '',
                }
            );

            toast.success('Successfully changed the default shift worklocation');
            handleUpdateProvider({
                ...provider,
                payroll: {
                    ...provider?.payroll,
                    metadata: {
                        ...provider?.payroll?.metadata,
                        defaultShiftWorkSiteId: workId?.value,
                    },
                },
            });
        } catch (err) {
            toast.error(err?.message);
        } finally {
            setIsBusy(false);
        }
    };
    const fetchWorkLocations = async () => {
        try {
            setIsBusy(true);
            const response = await fetchWrapper.get(
                `/evv/payroll/zeal/provider/${companyID}/work-locations${teamId ? `?team=${teamId}` : ''}`
            );
            const options = (response || []).map((location) => {
                return {
                    label: `${location.name} [${location.work_site_id}]`,
                    value: location?.work_site_id,
                };
            });
            setWorkLocationOptions(options);
            const _defaultShiftWorkSiteId = provider?.payroll?.metadata?.defaultShiftWorkSiteId;
            if (_defaultShiftWorkSiteId) {
                const workLocation = options.find((option) => option.value === _defaultShiftWorkSiteId);
                setWorkId(workLocation);
            }
        } catch (error) {
            if (error?.name === 'AbortError') return;
            toast.error(error?.message || "Work locations couldn't be fetched");
        } finally {
            setIsBusy(false);
        }
    };

    useEffect(() => {
        if (show) {
            fetchWorkLocations();
        }
    }, [show]);
    return (
        <div
            css={css`
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding: 8px;
            `}
        >
            <div
                className="d-flex flex-column"
                css={css`
                    width: 100%;
                `}
            >
                <div className="mt-4  d-flex">
                    Default Work Location:
                    <ConsentToolTip text="If main office is selected, the work location will be pre-selected while creating shifts checks." />
                </div>
                <div className="mt-4 d-flex justify-content-between">
                    <div className="d-flex  align-items-center ">
                        <div className="d-flex">
                            <input
                                type="radio"
                                className="mx-1"
                                name="smsNotification"
                                checked={workId}
                                onChange={() => {}}
                            />
                            <Select
                                isClearable={false}
                                onChange={(option) => setWorkId(option)}
                                value={workId}
                                options={workLocationOptions}
                                isDisabled={isBusy}
                                css={css`
                                    width: 312px;
                                `}
                            />
                        </div>
                        <div className="d-flex ml-4">
                            <input
                                type="radio"
                                id="clientWorkLocation"
                                className="mx-1"
                                name="clientWorkLocation"
                                checked={!workId}
                                onChange={() => setWorkId(null)}
                            />
                            <label htmlFor="clientWorkLocation">Client Work-Location</label>
                        </div>
                    </div>

                    <div className="d-flex justify-content-end">
                        <Button disabled={isBusy} onClick={() => handleSubmit()}>
                            Submit
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default DefaultWorkLocation;
